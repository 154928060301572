.badge-default {background-color: #e8e8e8;}
.badge.small {font-size:80%;}


.trip .element-wrapper  {
	padding-bottom: 10px;
}

.trip .order-flag {float:left;margin-right: 5px;}

.unflag-modal .user-avatar {float:left;margin-right: 20px;}


.separate-above {border-top: 2px solid #eaeaea;margin-top: 5px;padding-top: 5px;}

.trip-content {padding-top: 25px;padding-left: 10px;padding-right: 10px;/* overflow: scroll; *//* max-height: 100%; */max-width: 930px;}


.flag-alert .user-avatar {display:inline-block;float: left;margin-right: 10px;}


.payment-modal .element-box {padding-top:15px;padding-bottom:15px;}
.payment-modal .element-box i {padding-bottom:15px;}

.payment-modal .element-box a:hover .label, .payment-modal .element-box:hover i {
    color: #3395fc;
}

.booking-content .contact .name a, .tripOrder .date a, .tripOrder .time a {font-size: 14px;color: #737373;margin-right: 3px;margin-left: 2px;opacity: .4;}

.booking-content .contact .name a:hover, .tripOrder .date a:hover, .tripOrder .time a:hover {
	opacity: 1;
	transition: all 0.25s ease;
}

.booking-content .badges {
	flex-grow:0;
	/* flex-basis: 80px; */
	margin-right: 10px;
}

.booking-content .contact-info {
	flex-grow:1;
}

.booking-content .edit-region {
	flex-grow:1;
	max-width: 350px;
}

.booking-content .right .amount-item > div {
	font-size: .8rem;
	margin-top:3px;
	margin-bottom:3px;
	width:100%;
	display:flex;
	justify-content:space-between;
}

.booking-content .left {
	flex-grow:1;
	margin-right:10px;
	padding-left:10px;
}


.booking-content > .right {
	flex-grow:0;
	flex-basis: 170px;
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 15px;
	background-color: #fafafa;
	flex-shrink: 0;
}


.booking.with-selector.selected .booking-content > .right {
	background-color: #0173ea14;
}


.booking-content .right .amount-item .label {
	margin-right:5px;
	color: #737373;
	max-width: 90px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.booking-content .right .amount-item .number {
	color: #456CF9;
    margin-left: 5px;
}

.modal .side-nav {position: absolute;/* min-height: 735px; */bottom: 0px;overflow-x: scroll;/* overflow: hidden; */}

.payment-modal .element-box a .label, .payment-modal .element-box i {
	transition: all 0.25s ease;
}

.modal.payment-modal .slick-next {display:none;left: -1000px;}
.modal.payment-modal .slick-prev {display:none;left: -1000px;}

.payment-modal #card-element {height:40px;width: 100%;}
.payment-modal .modal-split-bottom {padding-bottom:0px;}
.payment-modal .modal-footer {margin-left:55px;margin-right:55px;position: relative;}

.payment-modal .buttons {margin-bottom:50px;}
.payment-modal .slide {padding-bottom: 10px;}

.payment-modal .modal-dialog {background-color: #fafbfe;}

#paymentsModal .StripeElement, #paymentCardLaterModal .StripeElement {
  box-sizing: border-box;
  height: 40px;
  margin-bottom:20px;
  padding: 10px 12px;
  border: 1px solid #dde2ec;
  border-radius: 4px;
  background-color: white;
}

.notes .element-header {margin-bottom:0px;}
.notes .chat-actions {display: inline-block;margin-top: 0px;vertical-align: bottom;padding: 0px;margin: 0px;}
.notes .chat-actions a {margin: 0px;padding: 0px;}
.notes .chat-message.self {cursor:pointer;}
.notes .full-chat-w .chat-controls {border-top:none;}


.full-note-w .note-content-w .note-message.self .note-message-content {
	margin-left: 20px;
	background-color: #f0f9ff;
	color: #2a4e7f;
	margin-right: 20px;
}


.note .note-message-content {
    margin-left: 20px;
    background-color: #f0f9ff;
    color: #2a4e7f;
    margin-right: 20px;
    padding: 15px 35px;
    background-color: #fff9f0;
    color: #594939;
    /* max-width: 400px; */
    display: inline-block;
    margin-bottom: -20px;
    margin-left: 20px;
    border-radius: 20px;
}


.full-note-w .full-note-i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #fff;
    padding: 0;
}

.full-note-w .full-note-middle {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    background-color: #fff;
}

.full-note-w .note-content-w {
    overflow-y: scroll;
    position: relative;
   border-bottom: 1px solid rgba(0,0,0,.1);
}

.full-note-w .note-content-w .note-content {
    padding: 30px;
}

.notes .note-message.self {
    cursor: pointer;
}

.full-note-w .note-content-w .note-message.self {
    text-align: left;
}

.full-note-w .note-content-w .note-message {
    margin-bottom: 20px;
}


.note-message-date {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
    margin-right: 10px;
    font-size: .72rem;
    color: rgba(0,0,0,.3);
}

.notes .note-actions {
    display: inline-block;
    vertical-align: bottom;
    padding: 0;
    margin: 0;
}

.notes .note-actions a {
    margin: 0;
    padding: 0;
}

.full-note-w .note-controls {
    padding: 0 20px 20px;
    /* border-top: 1px solid rgba(0,0,0,.1); */
}

.StripeElement--focus {
color: #495057;
    background-color: #fff;
    border-color: #047bf8;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.credit-card-reader-status {margin-bottom:20px;}


.order-totals a i {font-size:.6em;}

.full-chat-w .chat-content-w .chat-message.self {text-align:left;}

.element-box.compact .element-header {
	padding-left:20px;
	padding-right:20px;
	padding-top:20px;
	margin-bottom: 10px;
	background-color:#f8faff;
}

.element-box.compact .element-actions {
	padding-right:10px;
	padding-top:20px;
	right: 0px;
	position: absolute;
	top: 0px;
}

.element-actions .bookings-view-by {border-left: 1px solid #eaeaea;padding-left:20px;}

.element-actions div {display: inline-block;}

#tripOrders .edit-region, #giftCards .edit-region {margin-bottom:10px;}

#tripOrders span.data-label, #giftCards span.data-label, #trip-add-ons span.data-label, .booking-addons .data-label {font-size: 11px;display:block;text-transform:uppercase;color: #999999;}

#tripOrders .tripOrder-product span.data-label {
	font-size:12px;
}

#tripOrders .booking:hover {background-color:#fbfbfb87;}

#tripOrders .booking.status-editing-contact .actions {
	opacity:.5;
}

#tripOrders .booking.status-editing-contact .summary {
	opacity:.5;
}

.tripOrder .bookings {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.trip-order-note .note {display:inline;/* vertical-align: top; */}

#tripModal.modal.show .modal-dialog {
	transform:none;
	-webkit-transform: none;
	/* margin-bottom: 0px; */
	/* padding-bottom: 0px; */
}

.bookings .booking-content .edit-region {margin-bottom:15px;padding-top: 15px;padding-left: 15px;padding-bottom: 15px;}

.trip .tripOrder.bookings-single .booking {padding-bottom: inherit;width: 100%;}


.trip .tripOrder-experience .booking {
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 20px;
	position: relative;
	padding-bottom: 42px;
}

.trip .tripOrder-experience .booking.with-selector .booking-content {
	margin-left:25px;
}


.trip .tripOrder-experience .booking.with-selector.selected .booking-content {
	background-color: #017bf80a;
}

.trip .tripOrder-experience .booking.with-selector .selector {
	position:
	absolute;
	height: 100%;
	cursor: pointer;
}


.trip .tripOrder-experience .booking .booking-content {
	border-left: 2px solid #047bf836;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	border-right: 2px solid #047bf836;
	-webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
	box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
}

.trip .tripOrder-experience .booking.with-selector.selected .booking-content {
	border-left: 2px solid #047bf880;
	border-right: 2px solid #047bf880;
}

.bookings .actions .btn + .btn, .bookings .actions .btn, .bookings .actions .btn {
	margin-left:5px;
}
.bookings .actions .dropdown {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}



.booking .booking:first-child {margin-top:10px;}
.booking .booking:last-child {margin-bottom:0px;}

.tripOrder .timeslot {font-size: 16px;}

.tripOrder .timeslot span {display:block;line-height: 1.5;}

.tripOrder .experience .name {
	font-size: 22px;
	font-weight: 400;
	display: block;
	color: black;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.modal .tripOrder .experience .name {max-width: 360px;}


.modal .tripOrder .experience .name {max-width: 360px;}


#tripOrders .element-header {margin-bottom:0px;}

.tripOrder .summary {
	background-color: #f8faff;
	/* padding-top: 10px; */
	border-bottom: 2px solid rgba(0, 0, 0, 0.05);
	position: relative;
}

.trip-contact .status-editing .btn-group {float:right;}

.trip-contact .status-editing .state {
	position:absolute;
}


.trip-contact .status-editing .field-description {
	margin-left: 20px;
	max-width: 100%;
	/* white-space: nowrap; */
}

.custom-field-values span.placeholder {
	color: #e0e0e0;
	font-weight: 100;
}

.custom-field-values .actions .btn, .trip-contact .actions .btn i {
	background-color:inherit;color: #a6a6a7;
}

.trip-contact .custom-field-values .status-editing.field-type-2 .field-label {
	margin-bottom:0px;
}


.trip-contact .element-header {padding-left:15px;}

.tripOrder .summary:after {
    content: "";
    background-color: #047bf8;
    width: 25px;
    height: 4px;
    border-radius: 0px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0px;
}

.trip-contact .custom-field-values .field-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: .72rem;
    padding-left: 20px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-right: 15px;
}

.trip-contact .custom-field-values .status-editing .field-label {
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 10px;
}

.trip-contact .custom-field-values .status-editing {display:block;background-color: #f8faff;padding-top: 5px;}

.trip-contact .custom-field-values .status-editing .form-check {padding-left:0px;}

.trip-contact .custom-field-values .field-row .booking-edit-custom-field-value {
	display:flex;
}

.trip-contact .custom-field-values .field-row .actions {
	padding-left:5px;
}

.trip-contact .custom-field-values {
	margin-bottom:10px;
}

.trip-contact .custom-field-values .field-label {
	margin-right:10px;
}

.trip-contact .custom-field-values .field-row .field-value {
	padding-right:0px;
}

.trip-contact .custom-field-values .status-editing .actions  {
	padding-left:0px;
}

.booking-content > div.left > .row.contact-detail {padding-top:0px;}

.booking .contact {padding-top:15px;}

.booking-content > div.left > .row {padding-top:10px;display: flex;}

.booking h3 {
	font-size: 16px;
	margin-bottom: 3px;
	margin-top: 0px;
	font-weight: 100;
}

.booking-content .right  h3:hover {
	font-weight: 400;
}


.booking .contact-info span {
	margin-bottom:5px;
}

.resource-assign-modal .element-box.selected {background-color: #0080000f;border: 1px solid #00800038;}
.resource-assign-modal .element-box.selected .fa-check-circle {position:absolute;right: 5px;bottom: 5px;}



.booking .contact-info span.text-muted {
	color: #aeaeae !important;
}

.booking h3.value, .giftCard h3.value, .trip-order-addon h3.value, .trip-order-total h3.value {margin-bottom: 5px;font-size: 14px;font-weight:100;color: #5a5a5a;/* display: inline; */}

.booking .emailphone {
	color: #3d4b5b91;
	font-weight: 100;
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.bookings .field-description {
	white-space:normal;
	line-height: 1.2;
}

#tripModal___BV_modal_content_, .overflow-hide .modal-content {overflow:hidden;}

.trip-contact.element-box {margin-top: 15px;padding-left: 0px;padding-right: 0px;padding-top: 20px;padding-bottom: 10px;}


.booking .actions .title, .giftCard .actions .title {margin-bottom:10px;font-size:11px;font-weight:100;}


.booking .actions.bar {background-color: #f8faff;border-top: 1px solid white;}

.tripOrder {
	position: relative;
}

.booking .actions.bar, .trip .actions.bar, .notes .actions.bar {
	background-color: #f8faff94;
	padding-top: 10px;
	transition: all 0.3s ease;
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0px;
	padding-bottom: 5px;
}

.trip .actions a, .booking .actions a, .tripOrder a.btn-link {
	margin-left: 0px;
	margin-right: 5px;
	transition: all 0.3s ease;
	cursor:pointer;
	border-radius: 4px;
}


.trip .actions.hover a.btn-link:hover, .tripOrder a.btn-link:hover, .tripOrder a.btn-default:hover {
	opacity:1;
	background-color: #037bf8;
	color: white !important;
	box-shadow: 0 0 0 1px rgba(42,47,69,.1), 0 2px 5px 0 rgba(42,47,69,.1), 0 3px 9px 0 rgba(42,47,69,.08), 0 1px 1.5px 0 rgba(0,0,0,.08), 0 1px 2px 0 rgba(0,0,0,.08);
}

.tripOrder .actions .dropdown:hover button {
	background-color: #037bf8;
	color: white !important;
	box-shadow: 0 0 0 1px rgba(42,47,69,.1), 0 2px 5px 0 rgba(42,47,69,.1), 0 3px 9px 0 rgba(42,47,69,.08), 0 1px 1.5px 0 rgba(0,0,0,.08), 0 1px 2px 0 rgba(0,0,0,.08);
}

.tripOrder .summary .time-info .row {
	display: flex;
	flex-wrap: nowrap;
}

.trip .show-nav-head {
	max-width:900px;
}

.tripOrder .summary .experience-info {flex-grow:1;padding-top: 10px;}
.tripOrder .summary .time-info {flex-grow:1;padding-top: 10px;margin-right: 10px;}
.tripOrder .summary .guest-count {
	min-width: 50px;
	text-align:
	center;
	font-size:32px;
	padding-top: 10px;
	color: #017bf8;
	font-weight: 100;
	padding-right: 10px;
	padding-left: 10px;
	border-right: 1px solid #e5e5e5;
}

.tripOrder .summary .time-info .date-time {
	flex-grow:1;
	margin-right: 10px;
	margin-top: 5px;
}

.tripOrder .summary .time-info .trip-order-actions {
	flex-grow:0;
	flex-basis: 50px;
	padding-right: 10px;
	padding-left: 10px;
	margin-top: 5px;
}

.tripOrder.removeable .summary .time-info .trip-order-actions {
	padding-right: 15px;
}

.tripOrder .actions .dropdown:hover

.custom-fields .field-row:hover {
		background-color: #067af808;
}

.custom-fields .field-row {
	display: flex;
	align-items: flex-start;
	transition: all .5s ease;
}

.custom-fields .field-row .booking-custom-field-value {
	transition: all 1s ease;
}

.custom-fields .booking.status-editing {
	background-color: #f8faff;
	padding-top:10px;
	padding-bottom:10px;
}

.booking-content {
	/* padding-left:0px; */
	/* padding-right:0px; */
}

.booking-content .contact {
	padding-left:10px;
	padding-right:10px;
	flex-wrap: nowrap;
	margin-bottom: 5px;
}


.custom-fields .field-row.field-type-5 {
	align-items: baseline;
}

.custom-fields .field-row.field-type-1.status-editing .field-label {margin-top:12px;}
.custom-fields .field-row.field-type-4.status-editing .field-label {margin-top: 25px;}

.field-row .actions i {
	cursor:pointer;
}

.field-row .actions i:hover {
	opacity:1;
	cursor:pointer;
}


.custom-fields .field-label {min-width: 120px;text-align: right;margin-right: 10px;color: #b3b3b3;font-weight: 100;font-size: 11px;margin-top: 4px;}
.custom-fields .field-value {color: #606060;font-weight: 100;}
.tripOrder-experience .booking-content .left > div > .row:last-child {/* margin-bottom:20px; */}

.custom-fields .field-value .placeholder, .custom-fields .field-value .placeholder + i {color: #e4e4e4;font-weight: 100;}

.custom-fields .field-value {padding-left:5px;padding-right:5px;flex-grow: 1;}
.custom-fields .field-value .row .col-12 {display:flex;}
.custom-fields .field-value .row .field {flex-grow:1;max-width: 400px;padding-top: 5px;}
.custom-fields .field-value .row .actions a.btn {padding-left:5px;padding-right:5px;}
.custom-fields .field.status-editing {padding-top:10px;padding-bottom:10px;background-color: #cfe5fe24;}
.custom-fields .field-row .actions {
	display:none;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	/* font-size: 5px; */
}


#tripOrders .blue-border-top {
	border-top: 1px solid #cde4fe;
}

.tripOrder-experience .booking .booking-products, .booking .booking-addons {
	border-top: 1px solid #cde4fe;
	padding-bottom: 10px;
	color: #919295;
}


.booking .booking-products > div:first-child, .booking .booking-addons > div:first-child {/* padding-bottom:5px; */}

.booking .booking-product, .booking .booking-addon {
    flex-grow: 1;
    color: #919295;
    display: flex;
    font-size: 14px;
}

.tripOrder-product .booking .booking-product, .booking .booking-addon {
    color: #7a7b7b;
    padding-bottom: 3px;
}


.trip-type-product .booking .booking-product {
	font-size:16px;
margin-top: 2px;}

.booking .booking-product:hover .actions a {
	transition: 0.5s ease;
	opacity: 1;
}

.booking .booking-product .field-qty {text-align: right;margin-right: 5px;flex-grow: 0;flex-basis: 20px;text-align: right;}


.booking .booking-product .field-item {
	flex-grow:1;
}

.booking .booking-product .field-quantity {flex-basis: 70px;}

.booking .booking-product .field-each {flex-basis: 150px;flex-grow: 0;text-align: right;font-weight: 100;}
.booking .booking-product .field-value {
    flex-grow: 0;
    flex-basis: 80px;
    text-align: right;
    padding-right: 5px;
}

.booking .booking-product.is-editing .field-value {
	flex-basis:100px;
	margin-bottom: 10px;
	padding-right: 0px;
}

.booking .booking-product.is-editing.field-row {margin-top:5px;}
.booking .booking-product form {display:inherit;}

.booking .booking-product.is-editing .actions, .booking .booking-product.is-editing .field-qty {margin-top: 10px;}

.booking .booking-addon .addon-name {flex-grow:1;margin-left: 15px;}
.booking .booking-addon .addon-price {
	flex-grow: 0;
	flex-basis: 80px;
	text-align: right;
	padding-right: 10px;
}

.custom-fields .field-row .status-editing .actions {
	display:inline;
	padding-top: 9px;
}



.element-wrapper .field-row .btn + .btn {margin-left:0px;}


#trip-custom-fields .element-box {padding-bottom:20px;}
#trip-custom-fields .element-box.compact .element-header {
	margin-bottom:10px;
}

#tripOrders .tripOrder-experience.element-box {padding-bottom: 30px;}

.trip.trip-status-deleted #tripOrders .element-box {padding-bottom: 20px;}


.trip-order-addons {padding-bottom: 10px;}
.trip-order-addon.row {cursor:pointer;}

.trip-order-addon .addon-price {
	flex-basis: 120px;
	flex-grow: 0;
	text-align: right;
}

.trip-order-addon .addon-name {
	flex-basis: 200px;
	flex-grow: 1;
	margin-left: 15px;
}

.addon-actions {
	flex-basis: 50px;
	flex-grow: 0;
	padding-left: 10px;
	/* text-align: right; */
	font-size: .7rem;
}

.trip-order-addon .addon-actions div {
	transition: 0.5s ease;
	margin-top: -3px;
}

.trip-order-addon .addon-actions a {
	opacity:.3;
	margin-right: 5px;
}

.trip-order-addon .addon-actions a:hover {
	opacity: 1;
}

.trip-order-addons > .data-label {
	margin-bottom:10px;
}


.addon-actions {
    display: inline;
    cursor:pointer;
    opacity: 1;
    margin-right: 10px;
}

.actions.inline {display: -webkit-box;}



.payment .payment-type .label {	font-size:12px;}
.payment .payment-type .label {display:block;text-transform:uppercase;font-size: 8px;}

#discount-amount {max-width: 120px;}

#review-log .message-content {padding:10px;padding-bottom: 0px;overflow: hidden;margin: auto;max-height: 5000px;transition: all 0.7s ease;background-color: white;padding-bottom: 10px;zoom: .8;}
#review-log .head {display: flex;padding-left: 15px;padding-right: 15px;padding-top: 10px;padding-bottom: 10px;}
#review-log .head .message-type {display: flex;}




#review-log .review-info label {
	width: 60px;
	text-align:right;
	color: #bfbfbf;
	text-transform: uppercase;
	font-size: 10px;
	margin-right: 5px;
}

#review-log .head .type {
	flex-grow:1;
}

#review-log .rating-1 i {color: red;}
#review-log .rating-2 i {color: red;}
#review-log .rating-3 i {color: #757575;}
#review-log .rating-4 i {color: #8e9800;}
#review-log .rating-5 i {color: #8e9800;}


#tripOrders .trip-order-status-cancelled {padding-bottom:20px;}
#tripOrders .trip-order-status-cancelled .bookings {opacity: .5;}
#tripOrders .trip-order-status-cancelled .date-time {opacity:.4;}
#tripOrders .trip-order-status-cancelled .status {opacity:.4;}




#review-log .head .timestamp {
	flex-basis: 250px;
	font-size: 10px;
}

#review-log .section div.label {
	color: #bfbfbf;
	text-transform:uppercase;
	font-size:10px;
}

#review-log .subject h5 {
	font-size:16px;
}

#review-log .review-info  {
	margin-left:40px;
	border-top: 1px solid #cde4fe;
	margin-top: 10px;
	padding-top: 10px;
	font-size: 12px;
	color: #797979;
}

#review-log .section {
	border-top:1px solid #cde4fe;
	margin-left: 40px;
	padding-top: 15px;
	padding-right: 30px;
	padding-bottom: 20px;
	position: relative;
	display: block;
}


.element-box-remove {
	position:absolute;
	right: -11px;
	cursor:pointer;
	top: -6px;
	z-index: 2;
	color: #dedede;
	transition: color 0.5s ease;
}

.element-box-remove:hover {
	color: #666666;
}


.element-box-remove i {background-color:white;border-radius: 50%;}

.payment .actions, .payment-header .actions {
	flex-basis: 120px;
	flex-grow: 0;
	padding-left: 20px;
}

.net-payment {border-top: 1px solid #e0ebf9;padding-top: 5px;}
.refund-notes p, .refund-amount p {line-height:20px;}

.payment:hover .actions div {
	display: inline;
}


.payment-details {
	flex-grow:1;
	display: flex;
}

.refund-notes {
	border-left: 1px solid #e0ebf9;
	padding-left: 10px;
}

.detail-rows {padding-top:10px;padding-bottom:10px;display:flex;font-size:12px;padding-left: 1em;}
.detail-rows .indent {flex-basis: 20px;flex-grow:0;padding-left: 1em;border: 1px solid #c1d8f6;border-width: 0 0 1px 1px;margin-left: 25px;height: 15px;}
.detail-rows .transaction-detail {flex-grow:1;background-color: #e0ebf947;padding-top: 15px;border-bottom: 1px solid #cfe4fe;border-top: 1px solid #c1dcff;padding-right: 20px;margin-right: 20px;max-width: 700px;border-radius: 8px;padding-left: 20px;padding-bottom: 15px;}
.detail-rows .transaction-notes p {margin-left:10px;}
.detail-rows .transaction-detail span.note {margin-left:10px;display: block;margin-top: 5px;}

.detail-rows .transaction-amount {flex-basis:100px;flex-grow:0;text-align:right;border-right: 1px solid #e0ebf9;padding-right: 10px;background-color: #e0ebf947;padding-top: 10px;border-bottom: 1px solid #e0ebf9;border-top: 1px solid #e0ebf9;}
.detail-rows .actions {flex-basis: 70px;flex-grow:0;padding-left:10px;}



.waiver-status-2 {
	background-color:#24b314;
	color:white;
}

.addon-catalog-modal .catalog-item {
	box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
	transition: background-color 0.5s ease;
	cursor:pointer;
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-bottom: 10px;
	padding: 0px;
}

.catalog-modal .catalog-item:hover {
	background-color: #b5c6db14;
}


.booking-resource-row {
	display:flex;
	width:100%;
	padding-left: 10px;
	padding-right: 0px;
	margin-top: 2px;
	margin-bottom: 2px;
	transition: all .5s ease;
	flex-wrap: wrap;
	cursor: pointer;
	position: relative;
	/* padding-bottom: 5px; */
	padding-top: 5px;
}

.booking-resource-row:hover, .booking-resource-row:hover a {
}

.booking-resource-row.new-row.adding {
	background-color:#047bf80d;
}

.booking-resource-row.new-row:hover {
	background-color: inherit;
}

.booking-resource-row .element {
	flex-grow:1;
	color: #919295;
	font-size: 14px;margin-left: 5px;margin-right: 5px;
}

.booking-resource-row.new-row div.actions {
	border-bottom: none;
}

.booking-resource-row .break {
  flex-basis: 100%;
  height: 0;
}

.booking-resource-row .resource-error {
	width: 100%;
	color: red;
	border: 1px solid #ff000040;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 8px;
	padding: 3px;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 12px;
	font-weight: 100;
}

.booking-resource-row.new-row.adding .resource-name  {
	margin-left:10px;
	font-style:italic;
}

.booking-resource-row .resource-dot {
	width: 7px;
	height: 7px;
	display: inline-block;
	border-radius: 50%;
	margin-top: 7px;
	flex-grow: 0;
}


.booking-resource-row .resource-type {flex-basis: 30px;flex-grow:0;margin-left: 10px;text-align: center;}
.booking-resource-row .resource-name {flex-grow: 1;/* max-width: 140px; */}
.booking-resource-row .resource-time {/* flex-basis: 220px; */flex-grow: 1;flex-wrap: wrap;margin-top: 5px;margin-left: 5px;}

.booking-resource-row.new-row .resource-name {cursor:pointer;}
.booking-resource-row .vs__dropdown-toggle {display:none;}
.booking-resource-row .resource-time > div > button {
	box-shadow:none;
	border: none;
	color: #919295;
	background-color: transparent;
	padding-top: 2px;
	padding-left: 2px;
	padding-right: 2px;
}


.booking-resource-row .actions {
	text-align: right;
	margin-right: 5px;
	margin-top: 4px;
	flex-grow: 0;
	flex-basis: 30px;
}

.resource-assign-modal .resource-name {white-space:nowrap;overflow:hidden;margin-bottom: 0px;margin-top: 5px;}


.booking-resource-row .resource-time div {
    transition: all .08s ease-in, color .08s ease-in;
    padding-top: 2px;
    padding-right: 5px;
    font-size: 12px;
    color: #919295;
    background-color: #fff;
    padding-left: 5px;
    text-align: center;
    max-width: 115px;
    border: 1px solid #d1e4fe;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    min-width: 100px;
    /* white-space: nowrap; */
}

.booking-resource-row .resource-time div:hover {
	box-shadow: 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 3px 9px 0 rgb(42 47 69 / 8%), 0 1px 1.5px 0 rgb(0 0 0 / 8%), 0 1px 2px 0 rgb(0 0 0 / 8%);
	border: 1px solid white;
}


/* 
.booking-resource-row .vdatetime {
	display: inline-block;
}

.booking-resource-row .vdatetime-input:hover {
	box-shadow: 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 10%), 0 3px 9px 0 rgb(42 47 69 / 8%), 0 1px 1.5px 0 rgb(0 0 0 / 8%), 0 1px 2px 0 rgb(0 0 0 / 8%);
	border: 1px solid white;
}

.booking-resource-row .vdatetime-input {
	transition: all .08s ease-in, color .08s ease-in;
	border-color: #dde2ec;
	padding-top: 2px;
	padding-right: 5px;
	font-size: 12px;
	color: #919295;
	background-color: #fff;
	padding-left: 5px;
	text-align: center;
	max-width: 105px;
	border-style: none;
	border: 1px solid #d1e4fe;
	border-radius: 6px;
	cursor: pointer;
}
 */


.bookings-single .field-row .actions {
	font-size: 16px;
	line-height: 1;
}

.booking-product .actions {
	padding-top:0px;
	flex-basis: 42px;
}



.bookings.dimmed {
	opacity:.1;
}


.trip .trip-note-header h3 {
	font-size:14px;
}

.trip .trip-note-header h3 {
	font-size:14px;
}



.trip .transaction-detail .label {
	flex-basis: 150px;
	flex-grow:0;
	font-weight: 100;
	flex-shrink: inherit;
}

.trip .transaction-detail .value {
	flex-grow:1;
	font-weight: 400;
}


.trip .transaction-detail .row div {margin-bottom:3px;}

#payments .row.payment, #calls .row.call {
	margin-left:0px;
	margin-right:0px;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: background-color 0.5s ease;
	font-weight: 100;
}

.trip .row-hover:hover {background-color: #f8faffc2;}
.trip .row-expander {flex-basis:20px;flex-grow:0;}

.trip .row-header {
	border-bottom: 1px solid #e4e4e4;
	text-transform:uppercase;
	display:flex;
	font-size: 11px;
	padding-bottom:10px;
	color: #d2d2d2;
	font-weight: 100;
	padding-top: 5px;
}

.trip .row.payment > div, .trip .row.call > div {display:flex;font-size: 14px;width: 100%;}

#payments .row.payment, #calls .row.call {flex-grow:1;color: #606060;cursor:pointer;}
#payments .payment-amount {
	flex-basis: 110px;
	flex-grow: 0;
	text-align: right;
}

#payments .payment-created, #calls .call-created {
	flex-basis: 125px;
	flex-grow: 0;
}

#payments .payment-ago, #calls .call-ago {
	font-size: .9em;
	color: #bdbdbd;
}


#payments .payment-owner {
	flex-basis: 200px;
	flex-grow: 1;
}

#payments .payment-type, #payments .tip-left {
	flex-basis: 50px;
	flex-grow: 0;
	text-align: left;
}

#payments .payment-type-details {
	flex-basis:100px;
	flex-grow:1;
}

#payments .tip-detail {
	flex-grow: 1;
	text-align: left;
}

#payments .payment-date, #calls .call-date {
	flex-basis: 250px;
	flex-grow: 0;

}

#payments .payment-status {
	flex-basis: 125px;
	flex-grow: 0;
	text-align: center;
}



.resource-assign-modal .resource .fal {
	font-size: 2em;
	color: #b2b2b2;
}

.resource-assign-modal .resource.compact .fal {
	font-size: 1em;
	position:absolute;
	left: 10px;
	top: 8px;
}

.resource-assign-modal .resource.compact .resource-name {
	margin-top:5px;
	text-align:center;
}

.resource-assign-modal .resource.compact .resource-name {
	margin-top:0px;
	text-align:left;
	margin-left:20px;
}

.resource-assign-modal .resource .element-box {
	padding:15px;
}

.resource-assign-modal .resource.compact  .element-box {
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 8px;
}


/* mobile portrait */
@media (min-width: 320px) and (max-width: 768px) {
	#payments {margin-top:20px;}

	#payments button span {font-size: 16px;line-height: 1.5;}

	#payments .element-actions {
		float: inherit;
		position: relative;
		padding-top: 1px;
	}

	#payments .row.payment > div  {font-size:.7em;}
	#payments .payment-created {flex-basis: inherit;margin-left: 20px;}
	#payments .payment-owner {flex-basis: inherit;margin-left: 20px;}
	#payments .payment-details {display:flex;flex-basis: inherit;margin-left: 20px;display: none;}
	#payments .payment-status {flex-basis: inherit;margin-left: 20px;text-align: left;}
	#payments .payment-amount {flex-basis: inherit;margin-left: 20px;}
	#payments .payment-actions {flex-basis:500px;margin-left: 20px;}
	#payments .row.payment span.badge  {font-size:.8em;}

}
	


.addon-catalog-modal .catalog-item .addon-name {display:inline;}
.addon-catalog-modal .catalog-item .addon-price {display:inline;padding-left: 10px;}
.addon-catalog-modal .catalog-item .addon-action {float:right;color: #9b9b9b;padding-right: 10px;}
.addon-catalog-modal .catalog-item .addon-value {cursor:pointer;/* padding: 3px; */border-radius: 3px;/* padding-left: 10px; *//* padding-right: 10px; *//* transition: all 0.4s ease; *//* padding: 3px; */padding-top: 2px;padding-bottom: 2px;}
.addon-catalog-modal .catalog-item .addon-title {color:#000000;padding-left: 10px;padding-right: 10px;padding-top: 5px;}
.addon-catalog-modal .catalog-item {/* padding-top:5px; */padding-bottom:5px;/* margin-top: 30px; */}

.addon-catalog-modal .catalog-item .addon-value.addon-has-not:hover {/* background-color: #037bf808; */font-weight: 600;/* transition: all 0.4s ease; */}
.addon-catalog-modal .catalog-item .addon-value:hover .addon-action {color: #037bf8;}

.addon-catalog-modal .selected-item .action {margin-top:3px;margin-left:5px;opacity:.3;cursor:pointer;}
.addon-catalog-modal .selected-item .action a:hover {opacity:1;}
.addon-catalog-modal .addon-wrapper.addon-has {opacity:.5;transition: all 0.4s ease;}

.addon-catalog-modal .catalog-item:hover {background-color:inherit;}

.catalog-modal .selected-item .qty-selector a.plus {text-align:right;}

.catalog-modal .selected-item .qty-selector .qty {padding-left:3px;padding-right:3px;width: 25px;display: inline-block;text-align: center;font-weight: 400;}

.addon-catalog-modal .catalog-item .multiple.addon-value {padding-left:15px;}

.addon-catalog-modal .addon-has-not:hover .catalog-item {background-color: #037bf808;}


/* mobile portrait */
@media (min-width: 320px) and (max-width: 768px) {
	.catalog-modal .product .product-name {}
	.catalog-modal .product .product-price {}
	.catalog-modal .no-image .catalog-item .text {bottom:10%;}
	.catalog-modal .with-image .catalog-item .text {bottom:2px;}

	.booking-content > .right {
		flex-basis:110px;
	}

	.trip .content-box .side-nav {
		width:inherit;
		position:inherit;
		padding-bottom: 0px;
	}

	.trip-content {
		padding-left:inherit;
		padding-right:inherit;
	}

	.tripOrder .summary {padding-bottom:15px;}
	.trip-status-draft #tripOrders {margin-right:10px;margin-bottom: 30px;}

	
	.element-box.compact .element-actions {
		padding-left:20px;
		padding-right:20px;
	}
	.element-box .element-actions button, .element-wrapper .element-actions .btn {
		display: block;
		width:100%;
		margin-top:10px;
		margin-left:0px;
		margin-right:0px;
		min-height:30px;
	}

	
	.element-box .element-actions button, .element-wrapper .element-actions .btn + .btn {
		margin-left: 0px;
	}
	
	span.badge {font-size: .65rem;}

	.manual-message-modal .action {
		margin-top:10px;
		margin-left: auto;
	}

	.booking-resource-row .resource-time {flex-basis: 160px;flex-grow: 0;flex-wrap: wrap;margin-top: 5px;margin-left: 5px;}
	.booking-resource-row .vdatetime-input {
		max-width: 75px;
	}



}

/* modal window */
@media (min-width: 890px) {

	.modal-dialog.modal-lg .nav.upper.nav-tabs .nav-link {
		margin-left: 5px;
		margin-right: 5px;
	}

	.modal-dialog.modal-lg .nav.upper.nav-tabs .nav-link span {
		font-size: 10px;
	}
	
	.modal-dialog.modal-lg .nav.upper {
		padding-left: 10px;
		padding-right: 30px;
	}


}


/* iPad portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
}

/* iPad landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.nav.upper.centered.nav-tabs .nav-link {
		 margin-left:0px;
		 margin-right:0px;
	}
}
	