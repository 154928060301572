/*
TABLE OF CONTENTS


1. Theme Variables
	1a. User Override Variables
2. Theme Mixins
3. Bootstrap Override Styles
  3a. Override default includes of bootstrap
  3b. Override bootstrap table styles
  3c. Override bootstrap form styles
  3d. Override bootstrap navigation styles
  3e. Override bootstrap button styles
  3f. Override bootstrap breadcrumbs styles
  3g. Override bootstrap typography styles
  3h. Override bootstrap modal styles
  3i. Override bootstrap alerts styles
4. Overrides for various plugins that are included with this template
5. Handy Animations
6. User Profiles
7. Layout styles
8. Menu styles
9. Styles for all element blocks
10. Helpful Misc Styles
11. Icon Fonts
12. Chat
13. App - Email
14. App - Projects
15. App - Full Chat
16. Pricing Plans
17. Pipelines
18. OS Dropdowns
19. App - TODO
20. Fancy Selector
21. App - Rentals
22. App - Support
23. Onboarding
24. Top Bar styles
25. Search
26. Customizer
##. Dark Color Scheme
##. Responsive Styles

*/
/* 1. Theme Variables */
/* 1a. User Override Variables */
/* 2. Theme Mixins */
/* 3. Bootstrap Override Styles */
/* 3a. Override default includes of bootstrap */
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #047bf8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e65252;
  --orange: #fd7e14;
  --yellow: #fbe4a0;
  --green: #24b314;
  --teal: #20c997;
  --cyan: #5bc0de;
  --white: #fff;
  --gray: #636c72;
  --gray-dark: #343a40;
  --primary: #047bf8;
  --secondary: #292b2c;
  --success: #24b314;
  --info: #5bc0de;
  --warning: #fbe4a0;
  --danger: #e65252;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1250px;
  --breakpoint-xxl: 1450px;
  --breakpoint-xxxl: 1550px;
  --breakpoint-xxxxl: 1650px;
  --font-family-sans-serif: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* z-index: 500; */
  /* width: 100%; */
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
	margin: 0;
	font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	color: #3E4B5B;
	text-align: left;
	background-color: #f2f4f8;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #047bf8;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0356ad;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #334152;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  /* padding: 0.25rem; */
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #636c72;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #292b2c;
  border-radius: 4px;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #292b2c;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1250px) {
  .container {
    max-width: 1190px;
  }
}

@media (min-width: 1450px) {
  .container {
    max-width: 1380px;
  }
}

@media (min-width: 1550px) {
  .container {
    max-width: 1580px;
  }
}

@media (min-width: 1650px) {
  .container {
    max-width: 1680px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto, .col-xxxxl-1, .col-xxxxl-2, .col-xxxxl-3, .col-xxxxl-4, .col-xxxxl-5, .col-xxxxl-6, .col-xxxxl-7, .col-xxxxl-8, .col-xxxxl-9, .col-xxxxl-10, .col-xxxxl-11, .col-xxxxl-12, .col-xxxxl,
.col-xxxxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
          flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
          flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
          flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
          flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
          flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
          flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  FONT-VARIANT: JIS78;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }

}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

}

@media (min-width: 1250px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

}

@media (min-width: 1450px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

}

@media (min-width: 1550px) {
  .col-xxxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xxxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

}

@media (min-width: 1650px) {
  .col-xxxxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xxxxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxxxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxxxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxxxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxxxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxxxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxxxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxxxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxxxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(83, 101, 140, 0.33);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(83, 101, 140, 0.33);
}

.table tbody + tbody {
  border-top: 2px solid rgba(83, 101, 140, 0.33);
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid rgba(83, 101, 140, 0.33);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(83, 101, 140, 0.33);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(94, 130, 152, 0.05);
}

.table-hover tbody tr {
  transition: background-color 0.5s;
}

.table-hover tbody tr:hover {
  background-color: rgb(18 95 142 / 4%);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b9dafd;
}

.table-hover .table-primary:hover {
  background-color: #a0cdfc;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a0cdfc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c3c4c4;
}

.table-hover .table-secondary:hover {
  background-color: #b6b7b7;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b6b7b7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c2eabd;
}

.table-hover .table-success:hover {
  background-color: #b0e4aa;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b0e4aa;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d1edf6;
}

.table-hover .table-info:hover {
  background-color: #bce5f2;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #bce5f2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef7e4;
}

.table-hover .table-warning:hover {
  background-color: #fdf0cb;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fdf0cb;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8cfcf;
}

.table-hover .table-danger:hover {
  background-color: #f5b9b9;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b9b9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(18, 95, 142, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(15, 80, 119, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(15, 80, 119, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #292b2c;
  border-color: #3b3e40;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(83, 101, 140, 0.33);
}

.table-dark {
  color: #fff;
  background-color: #292b2c;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3b3e40;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1249.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1449.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1549.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxxl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1649.98px) {
  .table-responsive-xxxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxxxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d3d8e2;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #047bf8;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control::placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.1rem + 4px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.8rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .all-wrapper
.input-group-sm > .input-group-prepend > .form-control-plaintext.fc-button,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .all-wrapper
.input-group-sm > .input-group-append > .form-control-plaintext.fc-button, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .all-wrapper
.input-group-lg > .input-group-prepend > .form-control-plaintext.fc-button,
.input-group-lg > .input-group-append > .form-control-plaintext.btn, .all-wrapper
.input-group-lg > .input-group-append > .form-control-plaintext.fc-button {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn, .all-wrapper
.input-group-sm > .input-group-prepend > .fc-button,
.input-group-sm > .input-group-append > .btn, .all-wrapper
.input-group-sm > .input-group-append > .fc-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 4px;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .all-wrapper
.input-group-sm > .input-group-prepend > select.fc-button:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .all-wrapper
.input-group-sm > .input-group-append > select.fc-button:not([size]):not([multiple]) {
  height: calc(1.7rem + 4px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn, .all-wrapper
.input-group-lg > .input-group-prepend > .fc-button,
.input-group-lg > .input-group-append > .btn, .all-wrapper
.input-group-lg > .input-group-append > .fc-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 6px;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .all-wrapper
.input-group-lg > .input-group-prepend > select.fc-button:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .all-wrapper
.input-group-lg > .input-group-append > select.fc-button:not([size]):not([multiple]) {
  height: calc(2.875rem + 4px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #636c72;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #24b314;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(36, 179, 20, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #24b314;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #24b314;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #24b314;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #24b314;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #69ed5a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #2de119;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #24b314;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e65252;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(230, 82, 82, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e65252;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e65252;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e65252;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e65252;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #f6c1c1;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ec7f7f;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e65252;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .all-wrapper .fc-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 2px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn:hover, .all-wrapper .fc-button:hover, .btn:focus, .all-wrapper .fc-button:focus {
  text-decoration: none;
}

.btn:focus, .all-wrapper .fc-button:focus, .btn.focus, .all-wrapper .focus.fc-button {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn.disabled, .all-wrapper .disabled.fc-button, .btn:disabled, .all-wrapper .fc-button:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled), .all-wrapper .fc-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .all-wrapper .fc-button:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .all-wrapper .fc-button:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled, .all-wrapper a.disabled.fc-button,
fieldset:disabled a.btn,
fieldset:disabled .all-wrapper a.fc-button, .all-wrapper
fieldset:disabled a.fc-button {
  pointer-events: none;
}

.btn-primary, .all-wrapper .fc-button.fc-state-active {
  color: #fff;
  background-color: #047bf8;
  /* border-color: #047bf8; */
  border: 1px solid #047bf8;
}

.btn-primary:hover, .all-wrapper .fc-button.fc-state-active:hover {
  color: #fff;
  background-color: #0368d2;
  border-color: #0362c6;
}

.btn-primary:focus, .all-wrapper .fc-button.fc-state-active:focus, .btn-primary.focus, .all-wrapper .focus.fc-button.fc-state-active {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.btn-primary.disabled, .all-wrapper .disabled.fc-button.fc-state-active, .btn-primary:disabled, .all-wrapper .fc-button.fc-state-active:disabled {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-primary:not(:disabled):not(.disabled):active, .all-wrapper .fc-button.fc-state-active:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .all-wrapper .fc-button.fc-state-active:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle, .all-wrapper
.show > .dropdown-toggle.fc-button.fc-state-active {
  color: #fff;
  background-color: #0362c6;
  border-color: #035cb9;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .all-wrapper .fc-button.fc-state-active:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .all-wrapper .fc-button.fc-state-active:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus, .all-wrapper
.show > .dropdown-toggle.fc-button.fc-state-active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #171818;
  border-color: #101112;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #101112;
  border-color: #0a0b0b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-success:hover {
  color: #fff;
  background-color: #1d9110;
  border-color: #1b850f;
}

.btn-success:focus, .btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1b850f;
  border-color: #187a0e;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.btn-info {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:hover {
  color: #fff;
  background-color: #3bb4d8;
  border-color: #31b0d5;
}

.btn-info:focus, .btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aaacf;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-warning {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-warning:hover {
  color: #292b2c;
  background-color: #f9da7b;
  border-color: #f9d66f;
}

.btn-warning:focus, .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #292b2c;
  background-color: #f9d66f;
  border-color: #f8d363;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e13131;
  border-color: #e02525;
}

.btn-danger:focus, .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e02525;
  border-color: #d91f1f;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.btn-light {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #292b2c;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #292b2c;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #047bf8;
  background-color: transparent;
  background-image: none;
  border-color: #047bf8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #047bf8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.btn-outline-secondary {
  color: #292b2c;
  background-color: transparent;
  background-image: none;
  border-color: #292b2c;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #292b2c;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.btn-outline-success {
  color: #24b314;
  background-color: transparent;
  background-image: none;
  border-color: #24b314;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #24b314;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.btn-outline-info {
  color: #5bc0de;
  background-color: transparent;
  background-image: none;
  border-color: #5bc0de;
}

.btn-outline-info:hover {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #5bc0de;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-outline-warning {
  color: #fbe4a0;
  background-color: transparent;
  background-image: none;
  border-color: #fbe4a0;
}

.btn-outline-warning:hover {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fbe4a0;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.btn-outline-danger {
  color: #e65252;
  background-color: transparent;
  background-image: none;
  border-color: #e65252;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e65252;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #047bf8;
  background-color: transparent;
}

.btn-link:hover {
  color: #0356ad;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #636c72;
}

.btn-lg, .btn-group-lg > .btn, .all-wrapper .btn-group-lg > .fc-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-sm, .btn-group-sm > .btn, .all-wrapper .btn-group-sm > .fc-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #3E4B5B;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding-top: 12px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 400;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1d1e1f;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #047bf8;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #636c72;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #636c72;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn, .all-wrapper .btn-group > .fc-button,
.btn-group-vertical > .btn, .all-wrapper
.btn-group-vertical > .fc-button {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}

.btn-group > .btn:hover, .all-wrapper .btn-group > .fc-button:hover,
.btn-group-vertical > .btn:hover, .all-wrapper
.btn-group-vertical > .fc-button:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .all-wrapper .btn-group > .fc-button:focus, .btn-group > .btn:active, .all-wrapper .btn-group > .fc-button:active, .btn-group > .btn.active, .all-wrapper .btn-group > .active.fc-button,
.btn-group-vertical > .btn:focus, .all-wrapper
.btn-group-vertical > .fc-button:focus,
.btn-group-vertical > .btn:active, .all-wrapper
.btn-group-vertical > .fc-button:active,
.btn-group-vertical > .btn.active, .all-wrapper
.btn-group-vertical > .active.fc-button {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .all-wrapper .fc-button + .btn, .all-wrapper .btn-group .fc-button + .btn, .btn-group .all-wrapper .btn + .fc-button, .all-wrapper .btn-group .btn + .fc-button, .btn-group .all-wrapper .fc-button + .fc-button, .all-wrapper .btn-group .fc-button + .fc-button,
.btn-group .btn + .btn-group,
.btn-group .all-wrapper .fc-button + .btn-group, .all-wrapper
.btn-group .fc-button + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .all-wrapper .btn-group + .fc-button, .all-wrapper
.btn-group .btn-group + .fc-button,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .all-wrapper .fc-button + .btn, .all-wrapper
.btn-group-vertical .fc-button + .btn,
.btn-group-vertical .all-wrapper .btn + .fc-button, .all-wrapper
.btn-group-vertical .btn + .fc-button,
.btn-group-vertical .all-wrapper .fc-button + .fc-button, .all-wrapper
.btn-group-vertical .fc-button + .fc-button,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .all-wrapper .fc-button + .btn-group, .all-wrapper
.btn-group-vertical .fc-button + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .all-wrapper .btn-group + .fc-button, .all-wrapper
.btn-group-vertical .btn-group + .fc-button,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -2px;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child, .all-wrapper .btn-group > .fc-button:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .all-wrapper .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn, .all-wrapper
.btn-group > .btn-group:not(:last-child) > .fc-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .all-wrapper .btn-group > .fc-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn, .all-wrapper
.btn-group > .btn-group:not(:first-child) > .fc-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .all-wrapper .btn-group-sm > .fc-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .all-wrapper .btn-group-lg > .fc-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn-group-vertical .btn, .btn-group-vertical .all-wrapper .fc-button, .all-wrapper .btn-group-vertical .fc-button,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .all-wrapper .btn-group-vertical > .fc-button + .btn, .all-wrapper .btn-group-vertical > .btn + .fc-button, .all-wrapper .btn-group-vertical > .fc-button + .fc-button,
.btn-group-vertical > .btn + .btn-group, .all-wrapper
.btn-group-vertical > .fc-button + .btn-group,
.btn-group-vertical > .btn-group + .btn, .all-wrapper
.btn-group-vertical > .btn-group + .fc-button,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -2px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .all-wrapper .btn-group-vertical > .fc-button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn, .all-wrapper
.btn-group-vertical > .btn-group:not(:last-child) > .fc-button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .all-wrapper .btn-group-vertical > .fc-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn, .all-wrapper
.btn-group-vertical > .btn-group:not(:first-child) > .fc-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .all-wrapper .btn-group-toggle > .fc-button,
.btn-group-toggle > .btn-group > .btn, .all-wrapper
.btn-group-toggle > .btn-group > .fc-button {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .all-wrapper .btn-group-toggle > .fc-button input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"], .all-wrapper
.btn-group-toggle > .fc-button input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"], .all-wrapper
.btn-group-toggle > .btn-group > .fc-button input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"], .all-wrapper
.btn-group-toggle > .btn-group > .fc-button input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn, .input-group-prepend .all-wrapper .fc-button, .all-wrapper .input-group-prepend .fc-button,
.input-group-append .btn,
.input-group-append .all-wrapper .fc-button, .all-wrapper
.input-group-append .fc-button {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn, .input-group-prepend .all-wrapper .fc-button + .btn, .all-wrapper .input-group-prepend .fc-button + .btn, .input-group-prepend .all-wrapper .btn + .fc-button, .all-wrapper .input-group-prepend .btn + .fc-button, .input-group-prepend .all-wrapper .fc-button + .fc-button, .all-wrapper .input-group-prepend .fc-button + .fc-button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .all-wrapper .fc-button + .input-group-text, .all-wrapper
.input-group-prepend .fc-button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .all-wrapper .input-group-text + .fc-button, .all-wrapper
.input-group-prepend .input-group-text + .fc-button,
.input-group-append .btn + .btn,
.input-group-append .all-wrapper .fc-button + .btn, .all-wrapper
.input-group-append .fc-button + .btn,
.input-group-append .all-wrapper .btn + .fc-button, .all-wrapper
.input-group-append .btn + .fc-button,
.input-group-append .all-wrapper .fc-button + .fc-button, .all-wrapper
.input-group-append .fc-button + .fc-button,
.input-group-append .btn + .input-group-text,
.input-group-append .all-wrapper .fc-button + .input-group-text, .all-wrapper
.input-group-append .fc-button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .all-wrapper .input-group-text + .fc-button, .all-wrapper
.input-group-append .input-group-text + .fc-button {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #dde2ec;
  border-radius: 4px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn, .all-wrapper .input-group > .input-group-prepend > .fc-button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn, .all-wrapper
.input-group > .input-group-append:not(:last-child) > .fc-button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .all-wrapper
.input-group > .input-group-append:last-child > .fc-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .all-wrapper .input-group > .input-group-append > .fc-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn, .all-wrapper
.input-group > .input-group-prepend:not(:first-child) > .fc-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), .all-wrapper
.input-group > .input-group-prepend:first-child > .fc-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #047bf8;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, none;
          box-shadow: 0 0 0 1px #fff, none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b1d6fe;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #636c72;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #047bf8;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #047bf8;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #047bf8;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.1rem + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 2px solid #dde2ec;
  border-radius: 4px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: #047bf8;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(4, 123, 248, 0.5);
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(4, 123, 248, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #636c72;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.7rem + 4px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 4px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.1rem + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.1rem + 4px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #047bf8;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #047bf8;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.1rem + 4px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 2px solid #dde2ec;
  border-radius: 4px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.1rem + 4px) - 2px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 2px solid #dde2ec;
  border-radius: 0 4px 4px 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #636c72;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #636c72;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 transparent;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #047bf8;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 2px;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #636c72;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #636c72;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #047bf8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: #0356ad;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-item.active .page-link {
  /* z-index: 1; */
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
}

.badge:empty {
  display: none;
}

.btn .badge, .all-wrapper .fc-button .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #047bf8;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0362c6;
}

.badge-secondary {
  color: #fff;
  background-color: #292b2c;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #101112;
}

.badge-success {
  color: #fff;
  background-color: #24b314;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1b850f;
}

.badge-info {
  color: #292b2c;
  background-color: #5bc0de;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #292b2c;
  text-decoration: none;
  background-color: #31b0d5;
}

.badge-warning {
  color: #292b2c;
  background-color: #fbe4a0;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #292b2c;
  text-decoration: none;
  background-color: #f9d66f;
}

.badge-danger {
  color: #fff;
  background-color: #e65252;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #e02525;
}

.badge-light {
  color: #292b2c;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #292b2c;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 6px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3.85rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #02366d;
  background-color: #54a5fa;
  border-color: #1886f9;
}

.alert-primary hr {
  border-top-color: #0679f1;
}

.alert-primary .alert-link {
  color: #011d3b;
}

.alert-secondary {
  color: #121313;
  background-color: #6d6f70;
  border-color: #3a3c3d;
}

.alert-secondary hr {
  border-top-color: #2e2f30;
}

.alert-secondary .alert-link {
  color: black;
}

.alert-success {
  color: #104f09;
  background-color: #6acb5f14;
  border-color: #36b92724;
}

.alert-success hr {
  border-top-color: #30a423;
}

.alert-success .alert-link {
  color: #072104;
}

.alert-info {
  color: #285462;
  background-color: #8fd4e9;
  border-color: #68c5e1;
}

.alert-info hr {
  border-top-color: #53bddd;
}

.alert-info .alert-link {
  color: #19353e;
}

.alert-warning {
  color: #6e6446;
  background-color: #fcedbe;
  border-color: #fbe6a8;
}

.alert-warning hr {
  border-top-color: #fadf90;
}

.alert-warning .alert-link {
  color: #4f4832;
}

.alert-danger {
  color: #652424;
  background-color: #ee8989;
  border-color: #e86060;
}

.alert-danger hr {
  border-top-color: #e54a4a;
}

.alert-danger .alert-link {
  color: #3f1717;
}

.alert-light {
  color: #6d6e6e;
  background-color: #fafbfc;
  border-color: #f9f9fa;
}

.alert-light hr {
  border-top-color: #ebebee;
}

.alert-light .alert-link {
  color: #545454;
}

.alert-dark {
  color: #171a1c;
  background-color: #75797d;
  border-color: #444a4f;
}

.alert-dark hr {
  border-top-color: #383d41;
}

.alert-dark .alert-link {
  color: black;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.675rem;
  background-color: #e9ecef;
  border-radius: 4px;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #047bf85c;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  background-size: 100%;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

.close, button.btn-close .fal.fa-times {
  float: right;
  font-size: 1.35rem;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.close:not(:disabled):not(.disabled), .btn-close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close, button.btn-close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

button > i.fal {
	/* margin-right:5px; */
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
          transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
}

/* MsgBoxConfirm */
.msgBoxConfirm-custom-modal .modal-dialog {
  margin-top: 80px;
  margin-left: calc(50% - 10px);
  max-width: 450px;
}
.msgBoxConfirm-custom-modal .modal-content {
  width: 100%;
}
.msgBoxConfirm-custom-modal .modal-body {
  white-space: normal;
  word-wrap: break-word;
}
.msgBoxConfirm-custom-modal .btn {
  white-space: normal;
  word-wrap: break-word;
}
@media (max-width: 576px) {
  .msgBoxConfirm-custom-modal .modal-dialog {
    margin-left: 10px;
    margin-right: 10px;
  }
}


.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  /* background-color: #235a94; */
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem;
  border-bottom: 0px solid #e9ecef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 0px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 6px;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 0px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 0px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 6px 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 0px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0px solid #047bf8;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 0px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 6px 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0px;
  border-left-color: #fff;
}

.popover-header {
  padding: 12px 15px;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #fff;
  background-color: #047bf8;
  border-bottom: 0px solid #046fdf;
  border-top-left-radius: calc(6px - 0px);
  border-top-right-radius: calc(6px - 0px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 15px 20px;
  color: #3E4B5B;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #047bf8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0362c6 !important;
}

.bg-secondary {
  background-color: #292b2c !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #101112 !important;
}

.bg-success {
  background-color: #24b314 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1b850f !important;
}

.bg-info {
  background-color: #5bc0de !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #31b0d5 !important;
}

.bg-warning {
  background-color: #fbe4a0 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f9d66f !important;
}

.bg-danger {
  background-color: #e65252 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e02525 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #047bf8 !important;
}

.border-secondary {
  border-color: #292b2c !important;
}

.border-success {
  border-color: #24b314 !important;
}

.border-info {
  border-color: #5bc0de !important;
}

.border-warning {
  border-color: #fbe4a0 !important;
}

.border-danger {
  border-color: #e65252 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1250px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1450px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1550px) {
  .d-xxxl-none {
    display: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1650px) {
  .d-xxxxl-none {
    display: none !important;
  }
  .d-xxxxl-inline {
    display: inline !important;
  }
  .d-xxxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxxl-block {
    display: block !important;
  }
  .d-xxxxl-table {
    display: table !important;
  }
  .d-xxxxl-table-row {
    display: table-row !important;
  }
  .d-xxxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1250px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1450px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1550px) {
  .flex-xxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xxxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xxxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xxxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xxxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xxxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xxxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1650px) {
  .flex-xxxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xxxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xxxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxxxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xxxxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xxxxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxxxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxxxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxxxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxxxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxxxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xxxxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxxxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxxxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xxxxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxxxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxxxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xxxxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xxxxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xxxxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xxxxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xxxxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xxxxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xxxxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xxxxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xxxxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xxxxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xxxxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1250px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1450px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

@media (min-width: 1550px) {
  .float-xxxl-left {
    float: left !important;
  }
  .float-xxxl-right {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
}

@media (min-width: 1650px) {
  .float-xxxxl-left {
    float: left !important;
  }
  .float-xxxxl-right {
    float: right !important;
  }
  .float-xxxxl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}



.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
  /* font-weight: bold; */
}

.text-primary {
  color: #047bf8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0362c6 !important;
}

.text-secondary {
  color: #292b2c !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #101112 !important;
}

.text-success {
  color: #24b314 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1b850f !important;
}

.text-info {
  color: #5bc0de !important;
}

a.text-info:hover, a.text-info:focus {
  color: #31b0d5 !important;
}

.text-warning {
  color: #ffbf00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f9d66f !important;
}

.text-danger {
  color: #e65252 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #e02525 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted, .invoice-body .invoice-desc .desc-value {
  color: #636c72 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  a:not(.btn):not(.all-wrapper .fc-button) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

/* 3b. Override bootstrap table styles */
.table .user-with-avatar {
  white-space: nowrap;
}

.table .user-with-avatar img {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
}

.table .user-with-avatar span {
  display: inline-block;
  vertical-align: middle;
}

.table .user-with-avatar img + span {
  margin-left: 10px;
}

.table .icon-separator {
  margin: 0px 4px;
  opacity: 0.6;
}

.table th {
  font-weight: 500;
}

.table .smaller, .table.smaller {
  font-size: 0.72rem;
}

.table .lighter {
  color: rgba(90, 99, 126, 0.49);
}

.table.table-v-compact td {
  padding: 0.3rem 0.75rem;
}

.table.table-compact td {
  padding: 0.3rem 0.45rem;
}

.table.table-editable td:hover {
  background-color: #fff;
  -webkit-box-shadow: inset 0px 0px 0px 2px #047bf8;
          box-shadow: inset 0px 0px 0px 2px #047bf8;
}

.table.table-lightborder td {
  border-color: rgba(83, 101, 140, 0.08);
}

.table.table-clean tr:first-child td {
  border-top: none;
}

.table.table-clean td {
  padding-left: 0px;
  padding-right: 0px;
  border-top-color: rgba(0, 0, 0, 0.05);
}

.table.table-clean td .value {
  font-size: 0.9rem;
  line-height: 1.2;
}

.table.table-clean td .sub-value {
  font-size: 0.72rem;
  color: rgba(90, 99, 126, 0.49);
}

.table.table-lightfont td {
  font-weight: 300;
}

.table.table-bordered thead th {
  border-top: 1px solid rgba(83, 101, 140, 0.33);
}

.table th,
.table td {
  vertical-align: middle;
}

.table th img, .table td img {
  /* max-width: 100%; */
}

.table thead th {
  border-bottom: 1px solid #999;
}

.table tfoot th {
  border-top: 1px solid #999;
}

.table tfoot th,
.table thead th {
  font-size: 0.63rem;
  text-transform: uppercase;
  border-top: none;
}

.table tbody + tbody {
  border-top: 1px solid rgba(83, 101, 140, 0.33);
}

.table td.nowrap {
  white-space: nowrap;
}

.table .row-actions {
  text-align: center;
}

.table .row-actions a {
  margin-right: 0.8rem;
  color: #3E4B5B;
}

.table .row-actions a.danger {
  color: #9d1818;
}

.table .row-actions a:last-child {
  margin-right: 0px;
}

.table .cell-image-list {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.table .cell-image-list .cell-img {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center center;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0, 0, 0, 0.8);
          box-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0, 0, 0, 0.8);
  vertical-align: middle;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  position: relative;
  cursor: pointer;
}

.table .cell-image-list .cell-img:nth-child(1) {
  z-index: 5;
}

.table .cell-image-list .cell-img:nth-child(2) {
  z-index: 4;
}

.table .cell-image-list .cell-img:nth-child(3) {
  z-index: 3;
}

.table .cell-image-list .cell-img:nth-child(4) {
  z-index: 2;
}

.table .cell-image-list .cell-img:nth-child(5) {
  z-index: 1;
}

.table .cell-image-list .cell-img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.table .cell-image-list .cell-img + .cell-img {
  margin-left: -15px;
}

.table .cell-image-list .cell-img + .cell-img:hover {
  -webkit-transform: translateX(5px) scale(1.1);
          transform: translateX(5px) scale(1.1);
}

.table .cell-image-list .cell-img-more {
  font-size: 0.63rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -70%;
  background-color: #fff;
  padding: 3px 5px;
  border-radius: 4px;
  z-index: 7;
  white-space: nowrap;
}

@media (min-width: 1100px) {
  .table-responsive {
    overflow: visible;
  }
}

.table-lg td {
  padding: 1.2rem 1.5rem;
}

.table.table-v2 thead tr th, .table.table-v2 tfoot tr th {
  text-align: center;
  /* border-top: 1px solid #999; */
  /* border-bottom: 1px solid #999; */
  background-color: rgba(255, 255, 255, 0.05);
}

.table.table-v2 thead tr th:first-child, .table.table-v2 tfoot tr th:first-child {
  /* border-left: 1px solid #999; */
}

.table.table-v2 thead tr th:last-child, .table.table-v2 tfoot tr th:last-child {
  /* border-right: 1px solid #999; */
}

.table.table-v2 tbody tr td {
  border-color: #d1d8e6;
}

.controls-above-table {
  margin-bottom: 1rem;
}

.controls-above-table .btn, .controls-above-table .all-wrapper .fc-button, .all-wrapper .controls-above-table .fc-button {
  margin-right: 0.5rem;
}

.controls-above-table .btn + .btn, .controls-above-table .all-wrapper .fc-button + .btn, .all-wrapper .controls-above-table .fc-button + .btn, .controls-above-table .all-wrapper .btn + .fc-button, .all-wrapper .controls-above-table .btn + .fc-button, .controls-above-table .all-wrapper .fc-button + .fc-button, .all-wrapper .controls-above-table .fc-button + .fc-button {
  margin-left: 0px !important;
}

.controls-above-table .form-control {
  margin-right: 1rem;
}

.controls-above-table .form-control:last-child {
  margin-right: 0px;
}

.controls-below-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.81rem;
}

.controls-below-table .table-records-info {
  color: rgba(0, 0, 0, 0.5);
}

.controls-below-table .table-records-pages ul {
  list-style: none;
}

.controls-below-table .table-records-pages ul li {
  display: inline-block;
  margin: 0px 10px;
}

.controls-below-table .table-records-pages ul li a.current {
  color: #3E4B5B;
}

.all-wrapper table.dataTable {
  border-collapse: collapse !important;
}

.table.table-padded {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.table.table-padded thead tr th {
  border: none;
  font-size: 0.81rem;
  color: rgba(90, 99, 126, 0.49);
  letter-spacing: 1px;
  padding: 0.3rem 1.1rem;
}

.table.table-padded tbody tr {
  border-radius: 4px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.table.table-padded tbody tr:hover {
  -webkit-box-shadow: 0px 2px 5px rgba(69, 101, 173, 0.1);
          box-shadow: 0px 2px 5px rgba(69, 101, 173, 0.1);
  -webkit-transform: translateY(-1px) scale(1.01);
          transform: translateY(-1px) scale(1.01);
}

.table.table-padded tbody td {
  padding: 0.9rem 1.1rem;
  background-color: #fff;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
}

.table.table-padded tbody td.bolder {
  font-weight: 500;
  font-size: 0.99rem;
}

.table.table-padded tbody td img {
  display: inline-block;
  vertical-align: middle;
}

.table.table-padded tbody td img + span {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.table.table-padded tbody td span + span {
  margin-left: 5px;
}

.table.table-padded tbody td .status-pill + span {
  margin-left: 10px;
}

.table.table-padded tbody td:first-child {
  border-radius: 4px 0px 0px 4px;
}

.table.table-padded tbody td:last-child {
  border-radius: 0px 4px 4px 0px;
  border-right: none;
}

.element-box .table:last-child, .invoice-w .table:last-child, .big-error-w .table:last-child {
  margin-bottom: 0;
}

/* 3c. Override bootstrap form styles */
label {
  margin-bottom: 4px;
}

e {
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}

.form-control.rounded {
  border-radius: 30px !important;
}

select.form-control.rounded {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px;
  padding-left: 15px;
  background-position: right 5px top 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.form-text {
  font-size: 0.81rem;
}

.form-text.with-errors {
  color: #e65252;
}

.has-danger .form-control-feedback.text-muted, .has-danger .invoice-body .invoice-desc .form-control-feedback.desc-value, .invoice-body .invoice-desc .has-danger .form-control-feedback.desc-value {
  color: #e65252 !important;
  font-weight: 400;
}

.has-danger .form-control, .has-danger .select2.select2-container .select2-selection--single {
  border-color: #e65252;
  border-width: 1px;
}

.form-control {
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.form-control.form-control-faded {
  background-color: #dde2ec;
}

.form-control.bright {
  border-color: #e2e4ef;
}

.form-control[type="checkbox"] {
  width: auto;
  display: inline-block;
}

.form-group .date-input {
  position: relative;
}

.form-group .date-input:before {
  content: "\e972";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: #047bf8;
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.form-group .date-input .form-control {
  padding-left: 35px;
}

.form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-check-input {
  margin-right: 0.5rem;
}

.form-check.stick-right span {
  margin-right: 5px;
}

.form-buttons-w {
	margin-top: 1.5rem;
	padding-top: 1rem;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	position: relative;
	display: inline-block;
	width: 100%;
}

.form-buttons-w.compact {
  margin-top: 0.5rem;
}

.form-buttons-w .btn + .btn, .form-buttons-w .all-wrapper .fc-button + .btn, .all-wrapper .form-buttons-w .fc-button + .btn, .form-buttons-w .all-wrapper .btn + .fc-button, .all-wrapper .form-buttons-w .btn + .fc-button, .form-buttons-w .all-wrapper .fc-button + .fc-button, .all-wrapper .form-buttons-w .fc-button + .fc-button {
  margin-left: 10px;
}

label.bigger {
  font-size: 1.08rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

label.lighter {
  color: rgba(90, 99, 126, 0.49);
}

label.smaller {
  font-size: 0.8rem;
  line-height: 1.5;
}

fieldset {
  margin-top: 2rem;
}

legend {
  font-size: 0.99rem;
  display: block;
  margin-bottom: 1.5rem;
  position: relative;
  color: #047bf8;
}

legend span {
  padding: 0px 0.5rem 0 0;
  background-color: #fff;
  display: inline-block;
  z-index: 2;
  position: relative;
}

legend:before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1px;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.form-header {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: block;
}

.form-desc {
  color: #999;
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-size: 0.9rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: block;
}

.os-toggler-w {
  display: inline-block;
  margin-right: 10px;
}

.os-toggler-w .os-toggler-i {
  border-radius: 20px;
  background-color: rgb(154 154 154 / 20%);
  height: 20px;
  width: 35px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-toggler-w .os-toggler-i .os-toggler-pill {
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-toggler-w .os-toggler-i {
  background-color: #E1E6F2;
}

.os-toggler-w.on .os-toggler-i .os-toggler-pill {
  -webkit-transform: translate(15px, -50%);
          transform: translate(15px, -50%);
  background-color: #047bf8;
}

/* 3d. Override bootstrap navigation styles */
.nav.smaller {
  font-size: 0.72rem;
}

.nav.smaller.nav-tabs .nav-link {
  padding: 0.7em 1.1em;
}

.nav.smaller.nav-pills .nav-link {
  padding: 0.2em 1.1em;
}

.nav.bigger {
  font-size: 1.25rem;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.nav.bigger.nav-tabs .nav-link.active:after,
.nav.bigger.nav-tabs .nav-item.show .nav-link:after {
  height: 6px;
  bottom: -3px;
  border-radius: 2px;
}

.nav.bigger.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 2rem;
}

.nav.upper {
  font-size: 0.9rem;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.nav.upper.nav-tabs .nav-link.active:after,
.nav.upper.nav-tabs .nav-item.show .nav-link:after {
  height: 3px;
  bottom: -2px;
  border-radius: 2px;
}

.nav.upper.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  margin-right: 2rem;
}

.nav.upper.centered.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav.upper.centered.nav-tabs .nav-item {
  margin-right: 0px;
}

.nav.centered {
  text-align: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.nav-link i {
  display: inline-block;
  color: #b0c4f3;
  font-size: 26px;
  margin-bottom: 5px;
}

.nav-pills .nav-link i {
	color: rgba(0, 0, 0, 0.4);
}

.nav-pills .nav-link.active i {
	color: #FFF;
}

.nav-link span {
  display: block;
  font-size: 0.72rem;
}

.nav-tabs .nav-link.active i {
  color: #047bf8;
}

.nav-tabs .nav-item {
  margin-bottom: 0px;
  margin-right: 1rem;
}

.nav-tabs .nav-link {
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.nav-tabs .nav-link.disabled {
  color: #636c72;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item .nav-link {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
}

.nav-tabs .nav-link:after,
.nav-tabs .nav-item .nav-link:after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #047bf8;
  position: absolute;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-item:hover .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 transparent;
  position: relative;
}

.nav-tabs .nav-link.active:after,
.nav-tabs .nav-item.show .nav-link:after,
.nav-tabs .nav-link:hover:after,
.nav-tabs .nav-item:hover .nav-link:after {
  width: 100%;
}

.nav-pills .nav-link {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.4);
}

.nav-pills .nav-link.active,
.nav-pills .nav-item.show .nav-link {
  color: #fff;
  cursor: default;
  background-color: #047bf8;
}

/* 3e. Override bootstrap button styles */
.toggled-buttons .btn-toggled {
  border: 2px solid #047bf8;
  border: 2px solid transparent;
  border-radius: 6px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 0.72rem;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.3);
  margin: 5px 0px;
}

.toggled-buttons .btn-toggled.on, .toggled-buttons .btn-toggled:hover {
  border-color: #047bf8;
  color: #047bf8;
}

.toggled-buttons .btn-toggled + .btn-toggled {
  margin-left: 10px;
}

.toggled-buttons.solid .btn-toggled {
  background-color: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.81rem;
}

.toggled-buttons.solid .btn-toggled.on, .toggled-buttons.solid .btn-toggled:hover {
  background-color: #047bf8;
  color: #fff;
}

.btn-sm, .btn-group-sm > .btn, .all-wrapper .btn-group-sm > .fc-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.775rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-white, .all-wrapper .fc-button {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover, .all-wrapper .fc-button:hover {
  color: #292b2c;
  background-color: #ececec;
  border-color: #e6e5e5;
}

.btn-white:focus, .all-wrapper .fc-button:focus, .btn-white.focus, .all-wrapper .focus.fc-button {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-white.disabled, .all-wrapper .disabled.fc-button, .btn-white:disabled, .all-wrapper .fc-button:disabled {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .all-wrapper .fc-button:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .all-wrapper .fc-button:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle, .all-wrapper
.show > .dropdown-toggle.fc-button {
  color: #292b2c;
  background-color: #e6e5e5;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .all-wrapper .fc-button:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .all-wrapper .fc-button:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus, .all-wrapper
.show > .dropdown-toggle.fc-button:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-grey {
  color: #292b2c;
  background-color: #dde2ec;
  border-color: #dde2ec;
}

.btn-grey:hover {
  color: #292b2c;
  background-color: #c4cdde;
  border-color: #bcc6da;
}

.btn-grey:focus, .btn-grey.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 226, 236, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(221, 226, 236, 0.5);
}

.btn-grey.disabled, .btn-grey:disabled {
  color: #292b2c;
  background-color: #dde2ec;
  border-color: #dde2ec;
}

.btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #292b2c;
  background-color: #bcc6da;
  border-color: #b4bfd5;
}

.btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 226, 236, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(221, 226, 236, 0.5);
}

.btn-white-gold {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
  color: #CF8D0D;
}

.btn-white-gold:hover {
  color: #292b2c;
  background-color: #ececec;
  border-color: #e6e5e5;
}

.btn-white-gold:focus, .btn-white-gold.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-white-gold.disabled, .btn-white-gold:disabled {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
}

.btn-white-gold:not(:disabled):not(.disabled):active, .btn-white-gold:not(:disabled):not(.disabled).active,
.show > .btn-white-gold.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e5e5;
  border-color: #dfdfdf;
}

.btn-white-gold:not(:disabled):not(.disabled):active:focus, .btn-white-gold:not(:disabled):not(.disabled).active:focus,
.show > .btn-white-gold.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-link.btn-danger {
  color: #e65252;
}

.btn, .all-wrapper .fc-button {
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  outline: none;
  line-height: 1;
}

.btn.btn-secondary:focus, .all-wrapper .btn-secondary.fc-button:focus, .btn.btn-secondary.focus, .all-wrapper .btn-secondary.focus.fc-button {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn.btn-rounded, .all-wrapper .btn-rounded.fc-button {
  border-radius: 40px;
}

.btn.btn-upper, .all-wrapper .btn-upper.fc-button {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn.btn-underlined, .all-wrapper .btn-underlined.fc-button {
  background-color: transparent;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
}

.btn.btn-underlined span, .all-wrapper .btn-underlined.fc-button span {
  border-bottom: 2px solid #047bf8;
}

.btn.btn-underlined i, .all-wrapper .btn-underlined.fc-button i {
  font-size: 0.63rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn.btn-underlined:hover, .all-wrapper .btn-underlined.fc-button:hover {
  color: #0362c6;
}

.btn.btn-underlined:hover span, .all-wrapper .btn-underlined.fc-button:hover span {
  border-color: #0362c6;
}

.btn.btn-underlined:hover i, .all-wrapper .btn-underlined.fc-button:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.btn.btn-underlined.btn-gold, .all-wrapper .btn-underlined.btn-gold.fc-button {
  color: #BE8B1C;
}

.btn.btn-underlined.btn-gold span, .all-wrapper .btn-underlined.btn-gold.fc-button span {
  border-color: #BE8B1C;
}

.btn.btn-underlined.btn-gold:hover, .all-wrapper .btn-underlined.btn-gold.fc-button:hover {
  color: #926a15;
}

.btn.btn-underlined.btn-gold:hover span, .all-wrapper .btn-underlined.btn-gold.fc-button:hover span {
  border-color: #926a15;
}

/* 3f. Override bootstrap breadcrumbs styles */
.breadcrumb {
  list-style: none;
  margin: 0px;
  padding: 10px 30px 10px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: transparent;
  letter-spacing: 0.7px;
}

.breadcrumb li {
  margin-bottom: 0px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.585rem;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.breadcrumb li a {
  color: #3E4B5B;
}

.breadcrumb li span {
  color: rgba(0, 0, 0, 0.4);
}

.breadcrumbs + .content-box {
  padding-top: 0px;
}

/* 3g. Override bootstrap typography styles */
.text-muted, .invoice-body .invoice-desc .desc-value {
  font-weight: 300;
}

/* 3h. Override bootstrap modal styles */
.modal-content {
  -webkit-box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
          box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
}

.modal-footer.buttons-on-left {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.modal-header.faded {
  background-color: rgba(0, 0, 0, 0.05);
}

.modal-header.smaller {
  font-size: 0.99rem;
}

.modal-header span, .modal-header strong, .modal-header .avatar {
  display: inline-block;
  vertical-align: middle;
}

.modal-header span {
  color: #999;
  margin-right: 5px;
}

.modal-header .avatar {
  border-radius: 50%;
  width: 40px;
  height: auto;
}

.modal-header .avatar + span {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  margin-left: 15px;
}

/* 3h. Override bootstrap popover styles */
.popover {
  font-size: 0.99rem;
  -webkit-box-shadow: 0 5px 50px rgba(4, 123, 248, 0.3);
  box-shadow: 0px 5px 50px rgba(4, 123, 248, 0.3);
}

.popover-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9rem;
  color: #fff;
}

/* 3i. Override bootstrap alerts styles */
.alert {
  font-weight: 300;
}

.alert p:last-child {
  margin-bottom: 0px;
}

.alert.alert-warning .alert-heading {
  color: #3E3221;
}

.alert.borderless {
  border: none;
}

.alert.compact {
  padding: 15px 20px;
  font-size: 0.72rem;
}

/* 3i. Override bootstrap badges styles */
.badge {
  font-size: 0.70rem;
}

.badge-default-inverted {
  background-color: #b4bbc3;
  border: 1px solid #101112;
  color: #040404;
  font-weight: 300;
}

.badge-default-inverted[href]:hover, .badge-default-inverted[href]:focus {
  background-color: #98a2ac;
  border: 1px solid black;
  color: #040404;
  font-weight: 300;
}

.badge-primary-inverted {
  background-color: #cce4ff;
  border: 1px solid #0362c6;
  color: #0356ad;
  font-weight: 300;
}

.badge-primary-inverted[href]:hover, .badge-primary-inverted[href]:focus {
  background-color: #99caff;
  border: 1px solid #024994;
  color: #0356ad;
  font-weight: 300;
}

.badge-success-inverted {
  background-color: #c5f0c0;
  border: 1px solid #1b850f;
  color: #166e0c;
  font-weight: 300;
}

.badge-success-inverted[href]:hover, .badge-success-inverted[href]:focus {
  background-color: #9fe697;
  border: 1px solid #12570a;
  color: #166e0c;
  font-weight: 300;
}

.badge-info-inverted {
  background-color: #ecf9fd;
  border: 1px solid #31b0d5;
  color: #28a1c5;
  font-weight: 300;
}

.badge-info-inverted[href]:hover, .badge-info-inverted[href]:focus {
  background-color: #bfeaf7;
  border: 1px solid #2390b0;
  color: #28a1c5;
  font-weight: 300;
}

.badge-warning-inverted {
  background-color: white;
  border: 1px solid #f9d66f;
  color: #f8cf57;
  font-weight: 300;
}

.badge-warning-inverted[href]:hover, .badge-warning-inverted[href]:focus {
  background-color: #fff9e8;
  border: 1px solid #f7c83e;
  color: #f8cf57;
  font-weight: 300;
}

.badge-danger-inverted {
  background-color: #fdebeb;
  border: 1px solid #e02525;
  color: #ce1e1e;
  font-weight: 300;
}

.badge-danger-inverted[href]:hover, .badge-danger-inverted[href]:focus {
  background-color: #fabcbc;
  border: 1px solid #b71b1b;
  color: #ce1e1e;
  font-weight: 300;
}


/* DROPZONE */
.dropzone {
  border: 2px dashed #047bf8;
  border-radius: 6px;
}

/* SELECT 2 */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: #dde2ec;
  border-width: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e2ebff;
  border: 1px solid #4771d2;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #474a50;
  margin-right: 4px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #047bf8;
  color: #fff;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-width: 2px;
  border-color: #047bf8;
}

.select2-dropdown {
  border-width: 2px;
  border-color: #047bf8;
}

none {
  font-weight: 300;
}

.select2 {
  font-weight: 300;
}

/* DATERANGEPICKER */
body .daterangepicker {
  -webkit-box-shadow: 3px 25px 50px rgba(77, 76, 132, 0.34);
          box-shadow: 3px 25px 50px rgba(77, 76, 132, 0.34);
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  border: none;
  padding: 15px;
  min-width: 540px;
}

body .daterangepicker::after {
  display: block;
  clear: both;
  content: "";
}

body .daterangepicker .calendar {
  float: left;
  max-width: 300px;
  margin: 10px;
}

body .daterangepicker .calendar td {
  cursor: pointer;
}

body .daterangepicker .calendar td:hover {
  background-color: #1a88fb;
  color: #fff;
}

body .daterangepicker .calendar td.in-range {
  background-color: #1a88fb;
  color: #fff;
  border-color: #fff;
}

body .daterangepicker .calendar td.active, body .daterangepicker .calendar td.active:hover {
  background-color: #046fdf;
  color: #fff;
  border-color: #fff;
}

body .daterangepicker .ranges {
  clear: left;
  padding: 5px 10px;
}

body .daterangepicker .ranges .btn, body .daterangepicker .ranges .all-wrapper .fc-button, .all-wrapper body .daterangepicker .ranges .fc-button {
  padding: 5px 10px;
  line-height: 1;
  vertical-align: middle;
  text-transform: none;
  font-size: 0.81rem;
}

body .daterangepicker th {
  font-weight: 500;
  text-align: center;
  padding: 5px 0px;
  font-size: 0.72rem;
}

body .daterangepicker th.prev {
  text-align: left;
  cursor: pointer;
}

body .daterangepicker th.next {
  text-align: right;
  cursor: pointer;
}

body .daterangepicker:before {
  border-bottom-color: #3E4B5B;
}

body .daterangepicker .calendar td {
  font-weight: 300;
  font-size: 0.81rem;
  text-align: right;
  font-size: 0.81rem;
  padding: 5px 8px;
  border: 1px solid #eee;
}

body .daterangepicker .calendar th.month {
  color: #047bf8;
}

body .daterangepicker .calendar-table {
  padding-top: 10px;
}

body .daterangepicker thead tr:first-child th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 5px;
}

body .daterangepicker thead tr:first-child + tr th {
  padding-top: 10px;
}

body .daterangepicker .daterangepicker_input {
  position: relative;
}

body .daterangepicker .daterangepicker_input input {
  width: 100%;
  display: block;
  padding-left: 35px;
}

body .daterangepicker .daterangepicker_input i {
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  position: absolute;
}

body .daterangepicker .fa.fa-calendar.glyphicon.glyphicon-calendar:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e926";
}

body .daterangepicker .fa.fa-chevron-left.glyphicon.glyphicon-chevron-left:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e919";
  font-size: 10px;
}

body .daterangepicker .fa.fa-chevron-right.glyphicon.glyphicon-chevron-right:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e910";
  font-size: 10px;
}

/* DATATABLES */
.dataTables_length select {
  display: inline-block;
  width: 50px;
  margin: 0px 5px;
  vertical-align: middle;
}

.dataTables_filter input {
  display: inline-block;
  width: 130px;
  margin: 0px 5px;
  vertical-align: middle;
}

.dataTables_wrapper .row:first-child {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
}

.dataTables_wrapper .row:last-child {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}



.all-wrapper .fc-button {
  padding: 5px 10px;
  height: auto;
  margin: 0px 5px;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.all-wrapper .fc-button.fc-state-active {
  outline: none;
  text-shadow: none;
}

/* Datatables for Bootstrap 4 */
table.dataTable {
  clear: both;
  /* margin-top: 6px !important; */
  /* margin-bottom: 6px !important; */
  max-width: none !important;
  /* border-collapse: separate !important; */
}

table.dataTable td, table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after, table.dataTable.table-condensed .sorting_asc:after, table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}

table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child, table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}

/* 5. Handy Animations  */
@-webkit-keyframes fadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes fadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes jumpUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(70px, 70px);
            transform: scale(0.5) translate(70px, 70px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.1) translate(-10px, -10px);
            transform: scale(1.1) translate(-10px, -10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate(0px);
            transform: scale(1) translate(0px);
  }
}

@keyframes jumpUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(70px, 70px);
            transform: scale(0.5) translate(70px, 70px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.1) translate(-10px, -10px);
            transform: scale(1.1) translate(-10px, -10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate(0px);
            transform: scale(1) translate(0px);
  }
}

.floated-chat-btn {
  -webkit-animation-name: jumpUp;
          animation-name: jumpUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.floated-colors-btn {
  -webkit-animation-name: jumpUp;
          animation-name: jumpUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.floated-customizer-btn {
  -webkit-animation-name: jumpUp;
          animation-name: jumpUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.element-box, .invoice-w, .big-error-w {
  /* -webkit-animation-name: fadeUp; */
  /* animation-name: fadeUp; */
  /* -webkit-animation-duration: 1s; */
  /* animation-duration: 1s; */
}


.aec-full-message-w {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.user-profile {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.content-panel {
  -webkit-animation-name: fadeLeft;
          animation-name: fadeLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

/* 6. User Profile  */
.user-profile .up-head-w {
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
}

.user-profile .up-head-w .decor {
  position: absolute;
  bottom: -1px;
  right: 0px;
  max-width: 100%;
  z-index: 3;
}

.user-profile .up-head-w .decor .decor-path {
  fill: #fff;
}

.user-profile .up-head-w:before {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(rgba(0, 0, 0, 0.2)), color-stop(70%), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2), 70%, rgba(0, 0, 0, 0.5));
}

.user-profile .up-head-w .up-social {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 5;
}

.user-profile .up-head-w .up-social a {
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  font-size: 30px;
  margin-left: 10px;
}

.user-profile .up-head-w .up-social a:hover {
  color: white;
  text-decoration: none;
}

.user-profile .up-main-info {
  padding: 20% 5% 5% 5%;
  position: relative;
  z-index: 4;
}

.user-profile .up-main-info .user-avatar-w {
  margin-bottom: 1rem;
}

.user-profile .up-main-info .user-avatar-w .user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 3px solid #fff;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.user-profile .up-main-info .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.user-profile .up-header {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 3.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

.user-profile .up-sub-header {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.8);
}

.user-profile .up-controls {
  padding: 20px 5%;
}

.user-profile .btn + .btn, .user-profile .all-wrapper .fc-button + .btn, .all-wrapper .user-profile .fc-button + .btn, .user-profile .all-wrapper .btn + .fc-button, .all-wrapper .user-profile .btn + .fc-button, .user-profile .all-wrapper .fc-button + .fc-button, .all-wrapper .user-profile .fc-button + .fc-button {
  margin-left: 20px;
}

.user-profile .up-controls + .up-contents {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.user-profile .up-contents {
  padding: 20px 5%;
  padding-top: 3rem;
}

.user-profile.compact .up-contents {
  padding-top: 0px;
}

.user-profile.compact .up-main-info {
  padding-top: 35%;
}

.user-profile.compact .up-head-w .up-social a {
  font-size: 20px;
}

.user-profile.compact .up-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.user-profile.compact .up-sub-header {
  font-size: 0.81rem;
}

.user-profile + .element-wrapper {
  margin-top: 2rem;
}

.value-pair {
  display: inline-block;
}

.value-pair .label {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.63rem;
  text-transform: uppercase;
  display: inline-block;
}

.value-pair .value {
  display: inline-block;
  font-size: 0.63rem;
  text-transform: uppercase;
}

.value-pair + .value-pair {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* 7. Layout styles */
html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  padding: 50px;
  overflow-x: hidden;
}

body:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: -webkit-gradient(linear, left top, right bottom, from(#D7BBEA), to(#65A8F1));
  background: linear-gradient(to bottom right, #D7BBEA, #65A8F1);
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

body.full-screen {
  padding: 0px;
}

body.full-screen .all-wrapper {
  max-width: initial;
  border-radius: 0px;
}

body.full-screen .all-wrapper > .top-bar {
  border-radius: 0px;
}

body.full-screen .menu-w,
body.full-screen .top-bar {
  border-radius: 0px !important;
}

body.full-screen .menu-position-side .logged-user-menu {
  left: 0px;
  right: -1px;
  border-radius: 0px;
}

b,
strong {
  font-weight: 700;
}

.all-wrapper {
	-webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	max-width: 1600px;
	margin: 0px auto;
	/* position: relative; */
	/* min-height: 100%; */
}

.all-wrapper.with-pattern {
	-webkit-box-shadow: none;
	      box-shadow: none;
	border-radius: 0px;
	background-image: url(../images/template/bg-pattern2.png);
	background-size: contain;
	background-repeat: repeat;
	background-position: 0 0;
}

.all-wrapper.no-padding-content .content-box {
	padding: 0px;
}

.all-wrapper.white-bg-content .content-w {
	background-color: #fff;
	background-image: none;
}

.all-wrapper.solid-bg-all, .full-screen.menu-offset-side {
	background-color: #f2f4f8;
	/* background-color: white; */
}

.all-wrapper.solid-bg-all .content-w {
	background-image: none;
	/* padding-top: 51px; */
	/* position: relative; */
	/* z-index: 500; */
	flex-wrap: wrap;
}

.all-wrapper.solid-bg-all .content-w.less-padding {
	padding-top: 0px;
}

.auth-wrapper .all-wrapper {
	padding: 100px;
}

.section-heading {
	padding: 5% 10%;
	font-size: 1.26rem;
	color: rgba(0, 0, 0, 0.5);
}

.section-heading.centered {
  text-align: center;
}

.section-heading h1 {
  position: relative;
  margin-bottom: 40px;
}

.section-heading h1:after {
  position: absolute;
  bottom: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  content: "";
  background-color: #047bf8;
  height: 5px;
  width: 40px;
  border-radius: 6px;
}

/* Layout settings based on menu position */
/* MENU ON A SIDE */
body.menu-position-side .layout-w {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

body.menu-position-side .content-w {
  border-radius: 0px 6px 6px 0px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

/* MENU ON TOP */
body.menu-position-top .content-w {
  border-radius: 0px 0px 6px 6px;
}

body.menu-position-top .top-bar + .layout-w .menu-w {
  border-radius: 0px;
}

body.menu-side-right .layout-w {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.content-w {
  background-color: #f2f4f8;
  /* background-image: url("/images/template/bg-pattern.png"); */
  background-repeat: no-repeat;
  background-position: 20px 50px;
  vertical-align: top;
  height: 100%;
  /* position: absolute; */
  /* top: 0px; */
  /* bottom: 0px; */
  /* left: 0px; */
  /* right: 0px; */
}

.content-box {
  vertical-align: top;
  padding: 2rem 2.5rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: 50px;
  margin-bottom: 50px;
}

.content-box > .element-wrapper:first-child {
  padding-top: 0px !important;
}

.content-panel {
  vertical-align: top;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 400px;
          flex: 0 0 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 2rem 2.5rem;
  background-color: transparent;
}

.content-panel.compact {
  padding: 2rem 1.5rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 320px;
          flex: 0 0 320px;
}

.content-panel .content-panel-close {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #047bf8;
  z-index: 999;
  cursor: pointer;
}

.content-panel.color-scheme-dark {
  background-color: #2d3756;
}

.content-panel-toggler {
  background-color: #047bf8;
  padding: 4px 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 0.72rem;
  text-transform: uppercase;
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 4;
  cursor: pointer;
}

.content-panel-toggler:hover {
  background-color: #1a88fb;
}

.content-panel-toggler i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

.content-panel-toggler span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1px;
}

/*
 SIDE PANEL IS ACTIVE - ADD STYLES
*/
.with-side-panel .content-i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.with-side-panel .menu-mobile .mm-logo-buttons-w .content-panel-open {
  display: block;
}


/* 8. Menu styles */
/*
  STYLE NAVIGATION

#1. Logged User Box
#2. Shared styles between Top Bar & Main Menu
#3. Desktop Menu
#4. Mobile Menu

*/
/*
#1. Logged User Box
*/
.logged-user-w {
  text-align: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.logged-user-w .avatar-w {
  padding: 5px;
  border: 1px solid #262626;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.logged-user-w .avatar-w img {
  width: 50px;
  height: auto;
  border-radius: 50%;
}

.logged-user-w .logged-user-role {
  display: block;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
}

.logged-user-w .logged-user-toggler-arrow {
  padding: 3px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.logged-user-w.avatar-inline {
  text-align: left;
  position: relative;
}

.logged-user-w.avatar-inline .logged-user-i {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0.7rem;
}

.logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
  padding-left: 20px;
  text-align: left;
}

.logged-user-w.avatar-inline .logged-user-toggler-arrow {
  padding: 3px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.logged-user-w.avatar-inline .logged-user-avatar-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.logged-user-w.avatar-inline .logged-user-avatar-info .logged-user-info-w {
  margin-bottom: 0px;
  padding: 10px 10px 10px 20px;
}

/*
#2. Shared styles between Top Bar & Main Menu
*/
.menu-w .logo-w,
.top-bar .logo-w {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.menu-w .logo-w .filters-toggler,
.top-bar .logo-w .filters-toggler {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.menu-w .logo-w .filters-toggler:hover,
.top-bar .logo-w .filters-toggler:hover {
  color: #fff;
}

.menu-w .logo-w .logo,
.top-bar .logo-w .logo {
  display: inline-block;
  text-decoration: none;
}

.menu-w .logo-w .logo-element,
.top-bar .logo-w .logo-element {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 15px;
  position: relative;
  background-color: #98c9fd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w .logo-w .logo-element:after,
.top-bar .logo-w .logo-element:after {
  content: "";
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 15px;
  right: -20px;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w .logo-w .logo:hover .logo-element,
.top-bar .logo-w .logo:hover .logo-element {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w .logo-w .logo:hover .logo-element:after,
.top-bar .logo-w .logo:hover .logo-element:after {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.menu-w .logo-w .logo:hover .logo-label:after,
.top-bar .logo-w .logo:hover .logo-label:after {
  width: 100%;
  background-color: #fff;
}

.menu-w .logo-w .logo-label,
.top-bar .logo-w .logo-label {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.81rem;
  position: relative;
  margin-left: 10px;
}

.menu-w .logo-w .logo-label:after,
.top-bar .logo-w .logo-label:after {
  height: 2px;
  position: absolute;
  width: 0%;
  left: 0px;
  bottom: -5px;
  background-color: #fff;
  content: "";
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

/*
  #3. Desktop Menu
*/
.menu-w {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
          box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  -webkit-box-flex: 0;
      -ms-flex: 0 0 260px;
          flex: 0 0 260px;
  border-right: 0px solid rgba(0, 0, 0, 0.05);
  /* Styles for sub menu */
}

.menu-w .menu-page-header {
	margin: 0px 20px 20px 20px;
	font-size: 2rem;
	line-height: 110%;
}

.logged-user-w {
  position: relative;
  padding: 1rem;
}

.menu-w .logged-user-w .logged-user-i:hover {
  cursor: pointer;
}

/*
.menu-w .logged-user-w .logged-user-i:hover .logged-user-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
 */

.menu-w .logged-user-w + .main-menu {
  padding-top: 0px;
}

.menu-buttom {
	position: absolute;
	bottom: 25px;
	list-style-type:none;
	padding-left: 15px;
}

.menu-w .logged-user-menu, .menu-mobile .logged-user-menu {
  background: #323c58;
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -1px;
  left: -10px;
  overflow: hidden;
  padding: 1rem;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 6px;
}

.logged-user-menu .avatar-w {
  border-color: #fff;
}

.logged-user-menu .logged-user-avatar-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logged-user-menu .logged-user-info-w .logged-user-name {
  color: #fff;
}

.logged-user-menu .logged-user-info-w .logged-user-role {
  color: rgba(255, 255, 255, 0.6);
}

.logged-user-menu ul {
  list-style: none;
  text-align: left;
  margin: 0px;
  padding: 0px 30px;
  padding-bottom: 20px;
}

.logged-user-menu ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.logged-user-menu ul li a {
  display: block;
  padding: 10px;
  color: #fff;
}

.logged-user-menu ul li a i {
  vertical-align: middle;
  margin-right: 15px;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.logged-user-menu ul li a span {
  vertical-align: middle;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.menu-w .logged-user-menu ul li a:hover {
  text-decoration: none;
}

.menu-w .logged-user-menu ul li a:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w .logged-user-menu ul li a:hover span {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.logged-user-menu ul li:last-child {
  border-bottom: none;
}

.menu-w .logged-user-menu .bg-icon {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -40px;
  right: -20px;
}

.menu-w .logged-user-menu.color-style-light {
  background-color: #fff;
}

.menu-w .logged-user-menu.color-style-light .logged-user-avatar-info {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-name {
  color: #334152;
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-role {
  color: #636c72;
}

.menu-w .logged-user-menu.color-style-light .avatar-w {
  border-color: #111;
}

.menu-w .logged-user-menu.color-style-light ul li {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-w .logged-user-menu.color-style-light ul li a {
  color: #3E4B5B;
}

.menu-w .logged-user-menu.color-style-light ul li a i {
  color: #047bf8;
}

.menu-w .logged-user-menu.color-style-dark {
  background-color: #323c58;
}

.menu-w .logged-user-menu.color-style-bright, .menu-mobile .logged-user-menu.color-style-bright {
  background-color: #1b55e2;
}

.menu-w .logged-user-menu.color-style-bright-alt {
  background-color: #5e00da;
}

.menu-w .side-menu-magic {
  background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
  border-radius: 6px;
  padding: 40px;
  text-align: center;
  margin: 20px;
}

.menu-w .side-menu-magic h1, .menu-w .side-menu-magic h2, .menu-w .side-menu-magic h3, .menu-w .side-menu-magic h4, .menu-w .side-menu-magic h5 {
  color: #fff;
  margin-bottom: 5px;
}

.menu-w .side-menu-magic p {
  color: rgba(255, 255, 255, 0.6);
}

.menu-w .side-menu-magic .btn-w {
  margin-top: 15px;
}

.menu-w .side-menu-magic .btn-white, .menu-w .side-menu-magic .all-wrapper .fc-button, .all-wrapper .menu-w .side-menu-magic .fc-button {
  border: none;
  -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-w .logo-w {
  text-align: center;
  padding: 14px 1rem 14px 1.7rem;
}

.menu-w .logo-w a {
  display: inline-block;
}

.menu-w .logo-w .logo {
  display: inline-block;
}

.menu-w .logo-w img {
  width: 40px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.menu-w .logo-w span {
  vertical-align: middle;
  display: inline-block;
  color: #334152;
  margin-left: 1rem;
}

.menu-w ul.main-menu {
  list-style: none;
  padding: 2rem 1rem;
  margin-bottom: 0px;
}

.menu-w ul.main-menu > li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w ul.main-menu > li:last-child {
  border-bottom: none;
}

.menu-w ul.main-menu > li.has-sub-menu > a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Font Awesome 5 Pro' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f078";
  font-size: 7px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 5px;
}

.menu-w ul.main-menu > li.has-sub-menu.active .sub-menu {
  display: block;
}

.menu-w ul.main-menu > li.sub-header {
  text-transform: uppercase;
  color: #448eef;
  font-size: 0.72rem;
  letter-spacing: 1px;
  padding-top: 20px;
  padding-bottom: 0px;
  border-bottom: none;
}

.menu-w ul.main-menu > li.sub-header:first-child {
  padding-top: 0px;
}

.menu-w ul.main-menu > li.sub-header:first-child span {
  padding-top: 0px;
}

.menu-w ul.main-menu > li > a {
  color: #3E4B5B;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  font-size: 0.99rem;
}

.menu-w ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-w ul.main-menu > li > a:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-w ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  .menu-w ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-w ul.main-menu > li .icon-w {
  color: #0073ff;
  font-size: 27px;
  display: block;
  padding: 1rem 1rem 1rem 0rem;
  width: 70px;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.main-menu > li span {
  padding: 1rem;
  padding-left: 0.8rem;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-w ul.sub-menu {
  padding: 0px;
  padding-left: 55px;
  display: none;
  list-style: none;
}

.menu-w ul.sub-menu:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 1rem;
}

.menu-w ul.sub-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w ul.sub-menu li a {
  padding: 0.4rem 10px 0.4rem 10px;
  display: block;
  position: relative;
  font-size: 0.81rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.sub-menu li a:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #047bf8;
  position: absolute;
  left: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  display: block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.menu-w ul.sub-menu li a:hover {
  text-decoration: none;
  cursor: pointer;
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
}

.menu-w ul.sub-menu li a:hover:before {
  -webkit-transform: translate(-5px, -50%);
          transform: translate(-5px, -50%);
  border-radius: 6px;
  background-color: #047bf8;
  border-color: #047bf8;
}

.menu-w.color-scheme-light .messages-notifications,
.menu-w.color-scheme-light .top-icon {
  color: #047bf8;
}

.menu-w.color-scheme-light .logo-w {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w.color-scheme-light .logo-w .logo-label {
  color: #3E4B5B;
}

.menu-w.color-scheme-light .logo-w .logo-element {
  background-color: #0a7cf8;
}

.menu-w.color-scheme-light .logo-w .logo-element:after {
  background-color: #bfd6f9;
}

.menu-w.color-scheme-light .logo-w .logo:hover .logo-label {
  color: #047bf8;
}

.menu-w.color-scheme-light .logo-w .logo:hover .logo-label:after {
  background-color: #047bf8;
}

.menu-w.color-scheme-dark {
  background-color: #293145;
  background-image: none;
  color: rgba(255, 255, 255, 0.9);
  border-right-color: rgba(255, 255, 255, 0.05);
  -webkit-box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
          box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.menu-w.color-scheme-dark .messages-notifications,
.menu-w.color-scheme-dark .top-icon {
  color: #fff;
}

.menu-w.color-scheme-dark .logged-user-menu {
  -webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
}

.menu-w.color-scheme-dark .side-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
  -webkit-box-shadow: 0px 5px 10px rgba(19, 19, 19, 0.17);
          box-shadow: 0px 5px 10px rgba(19, 19, 19, 0.17);
}

.menu-w.color-scheme-dark ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark ul.sub-menu li a {
  color: #fff;
}

.menu-w.color-scheme-dark.color-style-bright ul.main-menu .icon-w {
  color: #95acff;
}

.menu-w.color-scheme-dark ul.main-menu .icon-w {
  color: #babed0;
}

.menu-w.color-scheme-dark ul.main-menu > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark ul.main-menu > li > a {
  color: #fff;
}

.menu-w.color-scheme-dark ul.main-menu > li > a:hover > .icon-w {
  color: #fff;
}

.menu-w.color-scheme-dark ul.main-menu > li > a:before {
  color: #fff;
}

.menu-w.color-scheme-dark .sub-menu-w {
  -webkit-box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-w.color-scheme-dark .logo-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark .logo-w span {
  color: #fff;
}

.menu-w.color-scheme-dark .logged-user-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark .logged-user-w .avatar-w {
  border-color: #fff;
}

.menu-w.color-scheme-dark .logged-user-w .logged-user-role {
  color: rgba(255, 255, 255, 0.4);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w .sub-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu:first-child {
  border-top-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a {
  color: #ccd9e8;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:before {
  border-color: #fff;
  opacity: 0.7;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:hover {
  color: #fff;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:hover:before {
  background-color: #fff;
  opacity: 1;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu + ul.sub-menu {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-style-transparent {
  background: none;
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-right-width: 1px;
}

.menu-w.color-style-bright {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#0e369a));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #0e369a 100%);
  background-repeat: repeat-x;
}

.menu-w.sub-menu-style-inside .sub-menu-header {
  display: none;
}

.menu-w.sub-menu-style-inside .sub-menu-icon {
  display: none;
}

.menu-w.sub-menu-style-inside ul.sub-menu {
  padding-left: 45px;
}

.menu-w.sub-menu-style-inside ul.sub-menu:last-child {
  padding-bottom: 1rem;
}

.menu-w.sub-menu-style-inside ul.sub-menu:last-child li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a:before, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a:before {
  color: #fff;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a span, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a span {
  color: #fff;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a .icon-w, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a .icon-w {
  color: #fff;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu {
  position: relative;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu > a:before {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active > a:before {
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.menu-w.sub-menu-style-flyout .sub-menu-w {
  position: absolute;
  background-color: #fff;
  padding: 10px 20px 20px;
  border-radius: 6px;
  right: 0px;
  top: 50%;
  -webkit-transform: translate(110%, -50%);
          transform: translate(110%, -50%);
  z-index: 10000;
  -webkit-box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.menu-w.sub-menu-style-flyout .sub-menu-header {
  font-size: 1.35rem;
  font-weight: 500;
  color: #047bf8;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.menu-w.sub-menu-style-flyout .sub-menu-icon {
  display: none;
}

.menu-w.sub-menu-style-flyout .sub-menu-i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-w.sub-menu-style-flyout ul.sub-menu {
  display: block;
  padding-left: 0px;
  border: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 180px;
          flex: 0 0 180px;
  padding: 0px 5px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li {
  min-width: 200px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a {
  color: #3E4B5B;
  white-space: nowrap;
  font-size: 0.9rem;
  padding: 8px 12px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:before {
  opacity: 0;
  -webkit-transform: translate(5px, -50%);
          transform: translate(5px, -50%);
  background-color: #047bf8;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:hover {
  color: #047bf8;
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:hover:before {
  opacity: 1;
  -webkit-transform: translate(12px, -50%);
          transform: translate(12px, -50%);
}

.menu-w.sub-menu-style-flyout ul.sub-menu li .badge {
  font-size: 0.72rem;
  padding: 2px 5px;
  margin-left: 5px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu + ul.sub-menu {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w {
  background-color: #323c58;
  -webkit-box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w .sub-menu-header, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w .sub-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a {
  color: #ccd9e8;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a:before, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a:before {
  background-color: #fff;
  border-color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a:hover, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a:hover {
  color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu + ul.sub-menu, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu + ul.sub-menu {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w {
  background-color: #1b55e2;
}

.menu-w.sub-menu-style-over ul.main-menu > li {
  position: relative;
}

.menu-w.sub-menu-style-over ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: none;
          transform: none;
}

.menu-w.sub-menu-style-over ul.main-menu > li.active {
  border-right-color: #1b55e2;
  border-bottom-color: #1b55e2;
}

.menu-w.sub-menu-style-over ul.main-menu > li.active > a {
  background-color: #1b55e2;
  color: #fff;
}

.menu-w.sub-menu-style-over ul.main-menu > li.active > a:before {
  color: #fff;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.menu-w.sub-menu-style-over ul.main-menu > li.active > a .icon-w {
  color: #fff;
}

.menu-w.sub-menu-style-over ul.main-menu > li.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0px) scale(1);
          transform: translateX(0px) scale(1);
}

.menu-w.sub-menu-style-over .sub-menu-w {
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(40px) scale(0.95);
          transform: translateX(40px) scale(0.95);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  background-color: #1b55e2;
  color: #fff;
  left: 100%;
  top: -100px;
  overflow: hidden;
  text-align: left;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-header {
  font-size: 2.4rem;
  color: rgba(255, 255, 255, 0.2);
  padding: 5px 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-icon {
  position: absolute;
  font-size: 120px;
  color: rgba(255, 255, 255, 0.1);
  bottom: -50px;
  right: -10px;
  display: block;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i .sub-menu + .sub-menu {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w.sub-menu-style-over .sub-menu-w ul.sub-menu:first-child {
  border-top: none;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul {
  list-style: none;
  padding: 0px;
  min-width: 250px;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li:last-child, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a {
  color: #fff;
  display: block;
  padding: 12px 50px;
  font-size: 0.99rem;
  position: relative;
  white-space: nowrap;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a:before, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #FBB463;
  border-color: #FBB463;
  position: absolute;
  left: 28px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
          transform: translate(-10px, -50%);
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a:hover:before, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a:hover:before {
  opacity: 1;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-header {
  color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-i .sub-menu + .sub-menu {
  border-left-color: rgba(0, 0, 0, 0.04);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-icon {
  color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li.active {
  border-right-color: #fff;
  border-bottom-color: #fff;
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li.active > a {
  background-color: #fff;
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li.active > a .icon-w {
  color: #047bf8;
}

.menu-w.sub-menu-style-over.sub-menu-color-light.color-scheme-light ul.main-menu > li.active > a:before {
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark .sub-menu-w {
  background-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li.active {
  border-right-color: #323c58;
  border-bottom-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li.active > a {
  background-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-bright ul.main-menu > li.active > a {
  background-color: #1b55e2;
}

.menu-w.menu-layout-mini {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px;
}

.menu-w.menu-layout-mini .logged-user-toggler-arrow {
  display: none;
}

.menu-w.menu-layout-mini .logo-w {
  padding: 17px 15px;
  text-align: center;
}

.menu-w.menu-layout-mini .logo-w .logo-label {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li span {
  display: none;
}

.menu-w.menu-layout-mini .side-menu-magic {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li .icon-w {
  padding: 18px 5px;
  font-size: 30px;
  width: auto;
}

.menu-w.menu-layout-mini ul.main-menu {
  padding: 20px 0px;
}

.menu-w.menu-layout-mini ul.main-menu > li > a {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.menu-w.menu-layout-mini .main-menu > li.has-sub-menu > a:before {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li.sub-header {
  display: none;
}

.menu-w.menu-layout-mini .logged-user-w.avatar-inline .logged-user-i > .logged-user-info-w {
  display: none;
}

.menu-w.menu-layout-mini .logged-user-w.avatar-inline .logged-user-i {
  padding-left: 0px;
}

.menu-w.menu-layout-mini .logged-user-w {
  text-align: center;
}

.menu-w.menu-layout-mini .logged-user-w {
  padding: 15px;
}

.menu-w.menu-layout-mini .logged-user-menu {
  width: 280px;
}

.menu-w.menu-layout-mini .logged-user-w .logged-user-i > .avatar-w img {
  max-width: 100%;
  height: auto;
}

.menu-w.menu-layout-compact {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 260px;
          flex: 0 0 260px;
}

.logged-user-w .avatar-w {
  padding: 4px;
}

.menu-w.menu-layout-compact .logged-user-w .avatar-w img {
  width: 35px;
}

.menu-w.menu-layout-compact .logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
  padding-left: 15px;
  padding-right: 15px;
}

.menu-w.menu-layout-compact ul.main-menu {
  /* padding: 1rem 1.5rem; */
  margin-bottom: 0px;
}

.menu-w.menu-layout-compact ul.main-menu > li {
  border-bottom: none;
}

.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a:before {
  content: "\f078";
  font-size: 7px;
  right: 5px;
}

.menu-w.menu-layout-compact ul.main-menu > li.sub-header {
  text-transform: uppercase;
  font-size: 0.72rem;
  letter-spacing: 1px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.menu-w.menu-layout-compact ul.main-menu > li > a {
  font-size: 0.9rem;
}

.menu-w.menu-layout-compact ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-w.menu-layout-compact ul.main-menu > li .icon-w {
  font-size: 18px;
  padding: 7px 10px 7px 0px;
  width: 40px;
}

.menu-w.menu-layout-compact ul.main-menu > li span {
  padding: 7px;
  padding-left: 5px;
}

.menu-w.menu-layout-compact ul.main-menu > li .icon-w + span {
  padding-left: 0px;
  /* display: none; */
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu {
  padding-left: 30px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu:first-child {
  padding-top: 10px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li {
  border-bottom: none;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a {
  padding: 4px 0px 4px 10px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a .badge {
  font-size: 0.72rem;
  padding: 2px 3px;
  border-radius: 2px;
  margin-left: 3px;
}

.menu-position-side.menu-side-right .top-bar {
  border-radius: 6px 0px 0px 0px;
}

.menu-position-side.menu-side-right .content-w {
  border-radius: 6px 0px 0px 6px;
}

.menu-position-side.menu-w {
  border-radius: 6px 0px 0px 6px;
}

.menu-position-side.menu-w.menu-side-left .logo-w:first-child {
  border-radius: 6px 0px 0px 0px;
}

.menu-side-left {
	width: 220px;
}

.menu-position-side.menu-w.menu-side-right {
  border-radius: 0px 6px 6px 0px;
}

.menu-position-side.menu-w.menu-side-right .logo-w:first-child {
  border-radius: 0px 6px 0px 0px;
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .sub-menu-w, .menu-position-side.menu-w.menu-side-right.sub-menu-style-over .sub-menu-w {
  left: auto;
  right: 100%;
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .sub-menu-w {
  -webkit-transform: translate(-10%, -50%);
          transform: translate(-10%, -50%);
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.menu-position-side.menu-w.menu-side-right.color-style-transparent {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-side.menu-w.menu-side-right.color-style-transparent.color-scheme-dark {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w .menu-actions {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w .menu-actions .messages-notifications, .menu-position-side.menu-w .menu-actions .top-icon {
  margin: 5px 0px;
}

.menu-position-side.menu-w .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
}

.menu-position-side.menu-w .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.menu-position-side.menu-w .messages-notifications i {
  vertical-align: middle;
}

.menu-position-side.menu-w .top-icon {
  margin: 0px 1rem;
  font-size: 26px;
}

.menu-position-side.menu-w .top-icon i {
  vertical-align: middle;
}

.menu-position-side.menu-w .element-search {
  position: relative;
  margin: 0px 1rem;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.menu-position-side.menu-w .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.menu-position-side.menu-w .element-search input {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  background-color: rgba(91, 109, 136, 0.1);
}

.menu-position-side.menu-w .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w.menu-layout-full .menu-actions {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search {
  padding: 15px 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search,
.menu-position-side.menu-w.menu-layout-full .menu-actions {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-full .element-search + ul.main-menu,
.menu-position-side.menu-w.menu-layout-full .menu-actions + ul.main-menu {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search + ul.main-menu > .sub-header:first-child,
.menu-position-side.menu-w.menu-layout-full .menu-actions + ul.main-menu > .sub-header:first-child {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-compact .menu-actions {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search {
  padding: 15px 0px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search,
.menu-position-side.menu-w.menu-layout-compact .menu-actions {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-compact .element-search + ul.main-menu,
.menu-position-side.menu-w.menu-layout-compact .menu-actions + ul.main-menu {
  padding-top: 20px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search + ul.main-menu > .sub-header:first-child,
.menu-position-side.menu-w.menu-layout-compact .menu-actions + ul.main-menu > .sub-header:first-child {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-mini {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.menu-position-side.menu-w.menu-layout-mini .logo-w {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.menu-position-side.menu-w.menu-layout-mini .element-search {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 10px 0px;
  margin: 0px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.menu-position-side.menu-w.menu-layout-mini .element-search:before {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  font-size: 22px;
  color: #fff;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input {
  opacity: 0;
  background-color: transparent;
  width: 100%;
  color: transparent;
  cursor: pointer;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-webkit-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input:-ms-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-ms-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions {
  margin: 0px 10px;
  border-radius: 50px;
  border: none;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions + .element-search {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .menu-actions {
  background-color: rgba(0, 0, 0, 0.03);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .menu-actions + .element-search {
  border-top-color: rgba(0, 0, 0, 0.05);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .element-search:before {
  color: #047bf8;
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-dark .menu-actions {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu-position-side.menu-w.color-scheme-dark .logo-w {
  background-color: rgba(0, 0, 0, 0.15);
}

.menu-position-side.menu-w.color-scheme-dark .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-light.menu-layout-full .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-full .menu-actions, .menu-position-side.menu-w.color-scheme-light.menu-layout-mini .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-mini .menu-actions, .menu-position-side.menu-w.color-scheme-light.menu-layout-compact .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-compact .menu-actions {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
  border-right: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-position-top.menu-w .logged-user-toggler-arrow {
  display: none;
}

.menu-position-top.menu-w.menu-has-selected-link {
  /* margin-bottom: 50px; */
}

.menu-position-top.menu-w ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.menu-position-top.menu-w .menu-actions {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu-position-top.menu-w .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.menu-position-top.menu-w .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
  /* padding: 0.25em 0.4em; */
}

.menu-position-top.menu-w .messages-notifications i {
  vertical-align: middle;
}

.menu-position-top.menu-w .top-icon {
  margin: 0px 1rem;
  font-size: 26px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.menu-position-top.menu-w .top-icon i {
  vertical-align: middle;
}

.menu-position-top.menu-w ul.main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-right: auto;
}

.menu-position-top.menu-w ul.main-menu > li {
  border-bottom: none;
}

.logged-user-w {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  border-bottom: none;
  padding: 5px 15px;
}

.menu-position-top.menu-w .logged-user-w .avatar-w {
  padding: 0px;
  border: none;
}

.menu-position-top.menu-w .logged-user-w .avatar-w img {
  width: 35px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu {
  left: auto;
  right: 0;
  top: 50px;
  width: auto;
  min-width: 230px;
  border-top-left-radius: 0;
  border-top-right-radius: 0px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu ul {
  padding: 0px 10px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu ul a {
  white-space: nowrap;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu .logged-user-avatar-info {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu .logged-user-avatar-info .logged-user-info-w {
  padding: 5px 5px 5px 20px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i:hover .logged-user-menu {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.menu-position-top.menu-w .logo-w {
  border-bottom: none;
}

.menu-position-top.menu-w .element-search {
  position: relative;
  margin: 0px 1rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-right: 10px;
}

.menu-position-top .element-search:before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\f002";
  color: rgba(0, 0, 0, 0.4);
}

.menu-position-top .element-search input {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 15px 8px 48px;
  display: block;
  width: 220px;
  outline: none;
  border: none;
  box-shadow: none;
}

.menu-position-top.menu-w .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .top-icon,
.menu-position-top.menu-w.color-scheme-dark .messages-notifications {
  color: rgba(255, 255, 255, 0.8);
}

.menu-position-top.menu-w.color-style-bright {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#1c4cc3));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c4cc3 100%);
  background-repeat: repeat-x;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu-position-top.menu-w.color-style-transparent {
  border-bottom-width: 1px;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: white;
  z-index: 25;
}

.menu-position-top .element-search input {
  background-color: rgba(121, 138, 185, 0.07);
}

.menu-position-top.menu-w.menu-layout-mini .logo-w {
  padding: 10px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu {
  padding: 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li > a {
  padding: 15px 20px;
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li > a .icon-w {
  padding: 0px;
  font-size: 28px;
}

.menu-position-top.menu-w.menu-layout-full.sub-menu-style-flyout .sub-menu-w {
  padding-top: 20px;
}

.menu-position-top.menu-w.menu-layout-full.sub-menu-style-flyout .sub-menu-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full > .logged-user-w > .logged-user-i > .logged-user-info-w {
  display: none !important;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active > a:before {
  color: #047bf8;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active span, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active span, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active span {
  color: #047bf8;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active > a:before {
  color: #3E4B5B;
}

.menu-position-top.menu-w.menu-layout-full .side-menu-magic {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full .logo-w {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  margin-right: 20px;
  padding: 10px 20px;
}

.menu-position-top.menu-w.menu-layout-full .logo-w .logo {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu {
  padding: 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu .sub-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a {
  padding: 15px 20px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a .icon-w {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a span {
  font-size: 0.81rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 2px;
  padding: 0px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a:hover:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a:hover span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.active > a:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.active > a span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.has-sub-menu > a {
  padding-right: 30px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.has-sub-menu > a:before {
  right: 13px;
  font-size: 7px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.logged-user-w {
  border: none !important;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w .logged-user-info-i > .logged-user-info-w {
  display: none !important;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w .avatar-w {
  vertical-align: middle;
}

.menu-position-top.menu-w.menu-layout-compact .logo-w {
  padding: 10px;
  padding-right: 20px;
}

.menu-position-top.menu-w.menu-layout-compact .logo-w .logo {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu {
  padding: 0px 10px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu .sub-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a {
  padding-right: 25px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a:before {
  right: 15px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li .sub-menu-w .sub-menu-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a {
  padding: 8px 12px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: none;
          transform: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover span {
  -webkit-transform: none;
          transform: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.active a:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li .icon-w {
  width: auto;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li span {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-compact .side-menu-magic {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact.sub-menu-style-flyout ul.main-menu > li .sub-menu-w, .menu-position-top.menu-w.menu-layout-compact.sub-menu-style-over ul.main-menu > li .sub-menu-w {
  padding-top: 20px;
}

.menu-position-top.menu-w.menu-layout-compact.color-scheme-dark ul.main-menu > li, .menu-position-top.menu-w.menu-layout-compact.color-scheme-bright ul.main-menu > li {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.menu-position-top.menu-w ul.main-menu > li.selected {
  position: static;
  background-color: #fff;
}

.menu-position-top.menu-w ul.main-menu > li.selected .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w {
  -webkit-transform: none !important;
          transform: none !important;
  border-radius: 0px !important;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  padding: 0px 10px !important;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-header,
.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-icon {
  display: none;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow-x: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px;
  padding: 0px;
  min-width: auto;
  border: none;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li {
  border-bottom: none;
  min-width: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  white-space: nowrap;
  padding: 10px 15px;
  font-size: 0.9rem;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a:before {
  display: none;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected {
  border-right-color: rgba(0, 0, 0, 0.05) !important;
  background-color: transparent !important;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a {
  background-color: #bfd6fa21;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a:before {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a .icon-w {
  color: #047bf8 !important;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a span {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected .sub-menu-w {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light.color-scheme-light .sub-menu-w {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected {
  border-right-color: #323c58;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a {
  background-color: #323c58;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a .icon-w {
  color: #fff !important;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a span {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a:before {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected .sub-menu-w {
  background-color: #323c58;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected {
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a {
  background-color: #1b55e2;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a .icon-w {
  color: #fff !important;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a span {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a:before {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected .sub-menu-w {
  background-color: #1b55e2;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-over .sub-menu-w {
  left: 0px;
  top: 100%;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li.active {
  border-right-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li.active {
  border-right-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-bright ul.main-menu > li.active {
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  padding: 0px 10px;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow-x: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px;
  padding: 0px;
  border: none;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li {
  border-bottom: none;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a {
  white-space: nowrap;
  padding: 15px 15px;
  font-size: 0.9rem;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a:before {
  display: none;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active {
  background-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active {
  background-color: #fff;
  border-right-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active span {
  color: #3E4B5B;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active {
  background-color: #323c58;
  border-right-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w {
  background-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active {
  background-color: #1b55e2;
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w {
  background-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  -webkit-transform: translate(50%, 0);
          transform: translate(50%, 0);
}

.menu-position-top.menu-w.sub-menu-style-flyout .sub-menu-w {
  right: 50%;
  top: 100%;
  -webkit-transform: translate(50%, 20%);
          transform: translate(50%, 20%);
}

.menu-position-top.menu-w.menu-with-image {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(21, 32, 70, 0.1)), color-stop(75%, rgba(16, 20, 41, 0.2)), to(rgba(14, 21, 53, 0.65))) !important;
  background-image: linear-gradient(to bottom, rgba(21, 32, 70, 0.1) 0%, rgba(16, 20, 41, 0.2) 75%, rgba(14, 21, 53, 0.65) 100%) !important;
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 5px;
  border-bottom: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.menu-position-top.menu-w.menu-with-image > .element-search {
  margin-left: auto;
}

.menu-position-top.menu-w.menu-with-image .logo-w {
  padding-left: 20px;
}

.menu-position-top.menu-w.menu-with-image .logged-user-w {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.menu-position-top.menu-w.menu-with-image .logged-user-w > .logged-user-i > .avatar-w {
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 4px;
}

.menu-position-top.menu-w.menu-with-image ul.main-menu {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
  margin-top: 20px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
}

.menu-position-top.menu-w.menu-with-image ul.main-menu .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.menu-with-image .menu-page-header {
  display: block;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding: 30px 20px;
  margin-bottom: 0px;
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
  margin-top: 30px;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.menu-position-top.menu-w.menu-with-image h1.menu-page-header {
  font-size: 3.25rem;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), color-stop(75%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0.85))) !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 75%, rgba(255, 255, 255, 0.85) 100%) !important;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light .logo-w .logo-label {
  color: #fff;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light ul.main-menu .icon-w {
  color: #047bf8;
}

.menu-mobile {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
          box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  position: relative;
  display: none;
  /* Styles for sub menu */
}

.menu-mobile .mm-logo-buttons-w {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem;
}

.menu-mobile .mm-logo-buttons-w .mm-logo {
  vertical-align: middle;
  text-align: left;
  text-decoration: none;
}

.menu-mobile .mm-logo-buttons-w .mm-logo img {
  width: 40px;
  display: inline-block;
}

.menu-mobile .mm-logo-buttons-w .mm-logo span {
  display: inline-block;
  color: #3E4B5B;
  margin-left: 1rem;
}

.menu-mobile .mm-logo-buttons-w .mm-logo:hover {
  text-decoration: none;
}

.menu-mobile .mm-logo-buttons-w .mm-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size:22px;
}

.menu-mobile .mm-logo-buttons-w .content-panel-open {
  display: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  color: #047bf8;
}

.menu-mobile .mm-logo-buttons-w .mobile-menu-trigger {
  vertical-align: middle;
  text-align: right;
  font-size: 18px;
  cursor: pointer;
  color: #047bf8;
}

.menu-mobile .menu-and-user {
  display: none;
  padding-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-mobile .logged-user-w {
  text-align: left;
  padding: 0px;
  border-bottom:none;
}

.menu-mobile .logged-user-w .avatar-w {
  vertical-align: middle;
  padding:0px;
}

.menu-mobile .logged-user-w .avatar-w img {
  width: 30px;
  height: 30px;
  padding:2px;
  border:1px solid #3cacff;
}

.menu-mobile .logged-user-w .logged-user-info-w {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.menu-mobile .mobile-menu-magic {
  background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
  border-radius: 6px;
  padding: 40px;
  text-align: center;
  margin: 10px 20px;
}

.menu-mobile .mobile-menu-magic:last-child {
  margin-bottom: 0px;
}

.menu-mobile .mobile-menu-magic h1, .menu-mobile .mobile-menu-magic h2, .menu-mobile .mobile-menu-magic h3, .menu-mobile .mobile-menu-magic h4, .menu-mobile .mobile-menu-magic h5 {
  color: #fff;
  margin-bottom: 5px;
}

.menu-mobile .mobile-menu-magic p {
  color: rgba(255, 255, 255, 0.6);
}

.menu-mobile .mobile-menu-magic .btn-w {
  margin-top: 15px;
}

.menu-mobile .mobile-menu-magic .btn-white, .menu-mobile .mobile-menu-magic .all-wrapper .fc-button, .all-wrapper .menu-mobile .mobile-menu-magic .fc-button {
  border: none;
  -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-mobile ul.main-menu {
  list-style: none;
  padding: 10px 1rem;
  margin-bottom: 0px;
}

.menu-mobile ul.main-menu > li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-mobile ul.main-menu > li:last-child {
  border-bottom: none;
}

.menu-mobile ul.main-menu > li.has-sub-menu > a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91c";
  font-size: 7px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.menu-mobile ul.main-menu > li.has-sub-menu.active .sub-menu {
  display: block;
}

.menu-mobile ul.main-menu > li > a {
  color: #3E4B5B;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.menu-mobile ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-mobile ul.main-menu > li > a:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-mobile ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  .menu-mobile ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-mobile ul.main-menu > li .icon-w {
  color: #0073ff;
  font-size: 27px;
  display: block;
  padding: 1rem;
  width: 80px;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-mobile ul.main-menu > li span {
  padding: 1rem;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-mobile ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-mobile ul.sub-menu {
  padding: 1rem 0px;
  padding-left: 55px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: none;
}

.menu-mobile ul.sub-menu li {
  padding: 0.4rem 10px 0.4rem 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-mobile ul.sub-menu li:last-child {
  border-bottom: none;
}

.menu-mobile ul.sub-menu li a {
  font-size: 0.81rem;
}

.menu-mobile.color-scheme-dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3D4D75), to(#31395B));
  background-image: linear-gradient(to bottom, #3D4D75 0%, #31395B 100%);
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#1c2e7b));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 100%);
  background-repeat: repeat-x;
  color: rgba(255, 255, 255, 0.9);
}

.menu-mobile.color-scheme-dark .side-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
}

.menu-mobile.color-scheme-dark ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark ul.sub-menu li a {
  color: #fff;
}

.menu-mobile.color-scheme-dark ul.main-menu .icon-w {
  color: #babed0;
}

.menu-mobile.color-scheme-dark ul.main-menu > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark ul.main-menu > li > a {
  color: #fff;
}

.menu-mobile.color-scheme-dark ul.main-menu > li > a:before {
  color: #fff;
}

.menu-mobile.color-scheme-dark .sub-menu-w {
  -webkit-box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w span {
  color: #fff;
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w .content-panel-open {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w .content-panel-open,
.menu-mobile.color-scheme-dark .mm-logo-buttons-w .mobile-menu-trigger {
  color: #fff;
}

.menu-mobile.color-scheme-dark .logged-user-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark .logged-user-w .avatar-w {
  border-color: #fff;
}

.menu-mobile.color-scheme-dark .logged-user-w .logged-user-role {
  color: rgba(255, 255, 255, 0.4);
}

.menu-mobile.color-scheme-dark .mobile-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
}

.menu-mobile .mm-logo-buttons-w .content-panel-open {
  display: block;
}


/* 9. Styles for all element blocks */
/*

  # -- STYLES NAVIGATION:

  #1. Element Wrapper Styles
  #2. Tablo Element
  #3. Charts & Legends
  #4. Profile Tiles
  #5. Users list element
  #6. Tabs Controls
  #7. Activity Boxes
  #8. Note box
  #9. Steps
  #10. Invoice
  #11. Error Page
  #12. Authorization styles
  #13. Timed Activities Element
  #14. Progress Bar Element
  #15. Balances
  #16. Call to action box
  #17. Post Box
  #18. Todo List Widget


*/
/*
 #1. Element Wrapper Styles
*/
.element-wrapper {
  padding-bottom: 3rem;
}

.element-wrapper .btn + .btn, .element-wrapper .all-wrapper .fc-button + .btn, .all-wrapper .element-wrapper .fc-button + .btn, .element-wrapper .all-wrapper .btn + .fc-button, .all-wrapper .element-wrapper .btn + .fc-button, .element-wrapper .all-wrapper .fc-button + .fc-button, .all-wrapper .element-wrapper .fc-button + .fc-button {
  margin-left: 1rem;
}

.element-wrapper.compact {
  padding-bottom: 2rem;
}

.element-wrapper.folded {
  padding-bottom: 2rem;
}

.element-wrapper.folded .element-header {
  margin-bottom: 0px;
}

.element-wrapper .element-info {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.element-wrapper .element-info .element-info-with-icon {
  margin-bottom: 0px;
}

.element-wrapper .element-info-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem;
}

.element-wrapper .element-info-with-icon .element-info-icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px;
  color: #047bf8;
  font-size: 30px;
}

.element-wrapper .element-info-with-icon.smaller .element-info-icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px;
  font-size: 20px;
}

.element-wrapper .element-info-with-icon.smaller .element-info-text .element-inner-header {
  margin-bottom: 0px;
}

.element-wrapper .element-actions {
  /* float: right; */
  position: relative;
  z-index: 2;
  margin-top: -0.2rem;
}

.element-wrapper .element-actions select.form-control-sm, .element-wrapper .element-actions .input-group-sm > select.form-control, .element-wrapper .element-actions
.input-group-sm > .input-group-prepend > select.input-group-text, .element-wrapper .element-actions
.input-group-sm > .input-group-append > select.input-group-text, .element-wrapper .element-actions
.input-group-sm > .input-group-prepend > select.btn, .element-wrapper .element-actions .all-wrapper
.input-group-sm > .input-group-prepend > select.fc-button, .all-wrapper .element-wrapper .element-actions
.input-group-sm > .input-group-prepend > select.fc-button, .element-wrapper .element-actions
.input-group-sm > .input-group-append > select.btn, .element-wrapper .element-actions .all-wrapper
.input-group-sm > .input-group-append > select.fc-button, .all-wrapper .element-wrapper .element-actions
.input-group-sm > .input-group-append > select.fc-button,
.element-wrapper .element-actions input.form-control-sm,
.element-wrapper .element-actions .input-group-sm > input.form-control,
.element-wrapper .element-actions
.input-group-sm > .input-group-prepend > input.input-group-text,
.element-wrapper .element-actions
.input-group-sm > .input-group-append > input.input-group-text,
.element-wrapper .element-actions
.input-group-sm > .input-group-prepend > input.btn,
.element-wrapper .element-actions .all-wrapper
.input-group-sm > .input-group-prepend > input.fc-button, .all-wrapper
.element-wrapper .element-actions
.input-group-sm > .input-group-prepend > input.fc-button,
.element-wrapper .element-actions
.input-group-sm > .input-group-append > input.btn,
.element-wrapper .element-actions .all-wrapper
.input-group-sm > .input-group-append > input.fc-button, .all-wrapper
.element-wrapper .element-actions
.input-group-sm > .input-group-append > input.fc-button {
  height: 1.75rem;
}

.element-wrapper .element-actions .form-control + .form-control {
  margin-left: 10px;
}

.element-wrapper .element-actions .btn + .btn, .element-wrapper .element-actions .all-wrapper .fc-button + .btn, .all-wrapper .element-wrapper .element-actions .fc-button + .btn, .element-wrapper .element-actions .all-wrapper .btn + .fc-button, .all-wrapper .element-wrapper .element-actions .btn + .fc-button, .element-wrapper .element-actions .all-wrapper .fc-button + .fc-button, .all-wrapper .element-wrapper .element-actions .fc-button + .fc-button {
  margin-left: 10px;
}

.element-wrapper .element-actions label {
  margin-right: 7px;
  color: rgba(90, 99, 126, 0.49);
}

.element-wrapper .element-actions.actions-only {
  margin-top: 0px;
}

.element-wrapper .element-actions .element-action {
  text-decoration: none;
  color: rgba(90, 99, 126, 0.49);
}

.element-wrapper .element-actions .element-action:hover {
  color: #3E4B5B;
}

.element-wrapper .element-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;
  /* z-index: 1; */
}

.element-wrapper .element-header:after {
  content: "";
  background-color: #047bf8;
  width: 25px;
  height: 4px;
  border-radius: 0px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0px;
}

.element-wrapper .element-inner-header {
  margin-bottom: 0.5rem;
  margin-top: 0px;
  display: block;
}

.element-wrapper .element-inner-desc {
  color: #999;
  font-weight: 300;
  font-size: 0.81rem;
  display: block;
}

.element-wrapper .element-search {
  position: relative;
}

.element-wrapper .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 48%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 20px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.2);
}

.element-wrapper .element-search input {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #f1f1f1;
  border-radius: 30px;
  padding: 10px 15px 10px 50px;
  display: block;
  width: 100%;
  outline: none;
}

.element-wrapper .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.box-style, .user-profile, .element-box, .invoice-w, .big-error-w, .activity-boxes-w .activity-box, .post-box, .projects-list .project-box {
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
}

.element-box, .invoice-w, .big-error-w {
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
}

.element-box.less-padding, .less-padding.invoice-w, .less-padding.big-error-w {
  padding: 1rem;
}

.element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
  margin-left: -2rem;
  margin-right: -2rem;
}

.element-box .os-tabs-controls .nav, .invoice-w .os-tabs-controls .nav, .big-error-w .os-tabs-controls .nav {
  padding-left: 2rem;
  padding-right: 2rem;
}

.element-box .centered-header, .invoice-w .centered-header, .big-error-w .centered-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 1rem;
}

.element-box .element-box-header, .invoice-w .element-box-header, .big-error-w .element-box-header {
  color: #046fdf;
  margin-bottom: 1rem;
}

.element-box-content + .form-header {
  margin-top: 2rem;
}

.element-box + .element-box, .invoice-w + .element-box, .big-error-w + .element-box, .element-box + .invoice-w, .invoice-w + .invoice-w, .big-error-w + .invoice-w, .element-box + .big-error-w, .invoice-w + .big-error-w, .big-error-w + .big-error-w {
  margin-top: 2rem;
}

.element-box-tp .input-search-w,
.element-box .input-search-w, .invoice-w .input-search-w, .big-error-w .input-search-w {
  margin-bottom: 1rem;
}

/*
 #2. Tablo Element
*/
.el-tablo {
  display: block;
}

.el-tablo:not(.centered) {
  padding-right: 5px;
}

.el-tablo .label {
  display: block;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
}

.el-tablo .value {
  font-size: 2.43rem;
  font-weight: 500;
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}

.el-tablo .trending {
  padding: 3px 10px;
  border-radius: 30px;
  display: inline-block;
  font-size: 0.81rem;
  vertical-align: middle;
  margin-left: 10px;
}

.el-tablo .trending span {
  display: inline-block;
  vertical-align: middle;
}

.el-tablo .trending-up {
  color: #fff;
  background-color: #24b314;
}

.el-tablo .trending-down {
  color: #fff;
  background-color: #e65252;
}

.el-tablo .trending-up-basic {
  color: #24b314;
  padding: 0px;
}

.el-tablo .trending-down-basic {
  color: #e65252;
  padding: 0px;
}

.el-tablo.trend-in-corner {
  position: relative;
}

.el-tablo.trend-in-corner .trending {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  line-height: 1.2;
  font-size: 0.63rem;
}

.el-tablo.trend-in-corner .trending i {
  font-size: 12px;
}

.el-tablo.centered {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.el-tablo.centered.padded {
  padding-left: 10px;
  padding-right: 10px;
}

.el-tablo.padded {
  padding: 2rem;
}

.el-tablo.bigger .value {
  font-size: 3.33rem;
}

.el-tablo.bigger .label {
  font-size: 0.9rem;
}

.el-tablo.smaller .value {
  font-size: 1.71rem;
}

.el-tablo.smaller .label {
  font-size: 0.63rem;
  letter-spacing: 2px;
}

.el-tablo.smaller.trend-in-corner .trending {
  top: 3px;
  right: 3px;
}

.el-tablo.highlight .value {
  color: #047bf8;
}

.el-tablo.bold-label .label {
  text-transform: none;
  font-size: 0.99rem;
  letter-spacing: 0px;
}

a.el-tablo {
  text-decoration: none;
  display: block;
  color: #3E4B5B;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

a.el-tablo .value {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

a.el-tablo .label {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

a.el-tablo:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
          transform: translateY(-5px) scale(1.02);
  -webkit-box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
          box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
}

a.el-tablo:hover .value {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: #047bf8;
}

a.el-tablo:hover .label {
  color: #3395fc;
}

a.el-tablo:hover.centered .value {
  -webkit-transform: scale(1.1) translateY(-3px);
          transform: scale(1.1) translateY(-3px);
}

a.el-tablo:hover .label {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.el-buttons-list .btn, .el-buttons-list .all-wrapper .fc-button, .all-wrapper .el-buttons-list .fc-button {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.el-buttons-list .btn + .btn, .el-buttons-list .all-wrapper .fc-button + .btn, .all-wrapper .el-buttons-list .fc-button + .btn, .el-buttons-list .all-wrapper .btn + .fc-button, .all-wrapper .el-buttons-list .btn + .fc-button, .el-buttons-list .all-wrapper .fc-button + .fc-button, .all-wrapper .el-buttons-list .fc-button + .fc-button {
  margin-left: 0px;
}

.el-buttons-list.full-width .btn, .el-buttons-list.full-width .all-wrapper .fc-button, .all-wrapper .el-buttons-list.full-width .fc-button {
  display: block;
}

.el-tablo + .el-chart-w {
  margin-top: 1rem;
}

/*
 #3. Charts & Legend
*/
.el-chart-w {
  position: relative;
  text-align: center;
}

.el-chart-w .inside-donut-chart-label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.63rem;
  text-transform: uppercase;
  z-index: 1;
}

.el-chart-w .inside-donut-chart-label strong {
  display: block;
  font-size: 1.98rem;
  line-height: 1.1;
}

.el-chart-w .inside-donut-chart-label span {
  display: block;
  color: rgba(0, 0, 0, 0.4);
}

.el-chart-w canvas {
  position: relative;
  z-index: 2;
  max-width: 100%;
}

.el-chart-w + .el-legend {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  margin-top: 2rem;
}

.el-legend .legend-value-w {
  margin-bottom: 0.5rem;
}

.el-legend.condensed {
  margin-top: 15px;
}

.el-legend.condensed .legend-value-w {
  white-space: nowrap;
}

.el-legend.condensed .legend-value-w .legend-pin {
  vertical-align: top;
  margin-top: 3px;
  margin-right: 2px;
}

.el-legend .legend-pin {
  width: 10px;
  height: 10px;
  border-radius: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
}

.el-legend .legend-pin.legend-pin-squared {
  border-radius: 0px;
  width: 7px;
  height: 7px;
}

.el-legend .legend-value {
  display: inline-block;
  vertical-align: middle;
}

.el-legend .legend-value span {
  display: block;
  line-height: 1;
}

.el-legend .legend-value .legend-sub-value {
  color: rgba(90, 99, 126, 0.49);
  font-size: 0.63rem;
}

/*
 #4. Profile Tile
*/
.profile-tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-tile .profile-tile-box {
  width: 110px;
  text-align: center;
  border-radius: 6px;
  padding: 1.2rem 0.5rem 0.5rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
          box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  text-decoration: none;
  color: #3E4B5B;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: block;
}

.profile-tile .profile-tile-box:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
          transform: translateY(-5px) scale(1.02);
  -webkit-box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
          box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
}

.profile-tile .profile-tile-box.faded {
  background-color: #E9ECF3;
  border: 1px dashed #ACBEDA;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.profile-tile .pt-avatar-w {
  display: inline-block;
  border-radius: 50px;
  overflow: hidden;
}

.profile-tile .pt-avatar-w img {
  width: 60px;
  height: auto;
}

.profile-tile .pt-user-name {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.63rem;
  text-transform: uppercase;
}

.profile-tile .pt-new-icon {
  background-color: #047bf8;
  color: #fff;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  line-height: 1;
  height: 35px;
  width: 35px;
  margin-bottom: 10px;
}

.profile-tile .pt-new-icon i {
  line-height: 35px;
  display: inline-block;
  vertical-align: middle;
}

.profile-tile .profile-tile-meta {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 30px;
}

.profile-tile .profile-tile-meta ul {
  list-style: none;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin: 0px;
  padding: 0px;
}

.profile-tile .profile-tile-meta ul li {
  margin-bottom: 3px;
}

.profile-tile .profile-tile-meta ul strong {
  color: #3E4B5B;
  margin-left: 5px;
}

.profile-tile .profile-tile-meta ul strong a {
  color: #3E4B5B;
  text-decoration: none;
  border-bottom: 1px solid #3E4B5B;
}

.profile-tile .pt-btn {
  margin-top: 10px;
}

.inline-profile-tiles .profile-tile {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 130px;
          flex: 0 1 130px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: none;
  padding: 0px;
}

.inline-profile-tiles .profile-tile .pt-avatar-w img {
  width: 40px;
  height: auto;
}

.inline-profile-tiles .profile-tile .profile-tile-box {
  display: block;
  width: 100%;
  padding: 15px;
  padding-bottom: 10px;
}

.inline-profile-tiles .profile-tile .pt-user-name {
  border-top: none;
  padding-top: 0px;
  text-transform: none;
  font-size: 0.9rem;
  line-height: 1.2;
  margin-top: 5px;
}

.status-pill {
  width: 12px;
  height: 12px;
  border-radius: 30px;
  background-color: #eee;
  display: inline-block;
  vertical-align: middle;
}

.status-pill.yellow {
  background-color: #f8bc34;
}

.status-pill.red {
  background-color: #c21a1a;
}

.status-pill.green {
  background-color: #71c21a;
}

.status-pill.smaller {
  width: 8px;
  height: 8px;
}

.status-pill + span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

/*
 #5. Users list element
*/
.users-list-w .user-w {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.users-list-w .user-w .user-avatar-w {
  width: 50px;
}

.users-list-w .user-w .user-avatar-w .user-avatar {
  border-radius: 40px;
  overflow: hidden;
}

.users-list-w .user-w .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.users-list-w .user-w.with-status .user-avatar-w {
  position: relative;
}

.users-list-w .user-w.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 0px 3px #fff;
          box-shadow: 0px 0px 0px 3px #fff;
}

.users-list-w .user-w.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.users-list-w .user-w.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.users-list-w .user-w .user-name {
  padding-left: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.users-list-w .user-w .user-title {
  margin-bottom: 4px;
}

.users-list-w .user-w .user-role {
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}

.users-list-w .user-w .user-action {
  width: 50px;
  color: #047bf8;
  font-size: 18px;
}

/*
 #6. Tabs Controls
*/
.os-tabs-controls {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.os-tabs-controls .nav-pills {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.os-tabs-controls .nav-tabs {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.os-tabs-controls.os-tabs-complex .nav-tabs {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.os-tabs-controls.os-tabs-complex .nav-item {
  white-space: nowrap;
  margin-right: 0px;
}

.os-tabs-controls.os-tabs-complex .nav-item.nav-actions {
  margin-left: auto;
}

.os-tabs-controls.os-tabs-complex .nav-item .btn, .os-tabs-controls.os-tabs-complex .nav-item .all-wrapper .fc-button, .all-wrapper .os-tabs-controls.os-tabs-complex .nav-item .fc-button {
  margin-left: 10px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link {
  color: #000000;
  opacity: 0.6;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  padding: 8px 20px;
  margin-right: 0px;
  font-weight: 300;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link span {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link span.badge {
  font-size: 0.72rem;
  padding: 3px 5px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link span.badge span {
  font-size: 0.72rem;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link.active {
  opacity: 1;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link.active .tab-label {
  font-size: 1.26rem;
  line-height: 1.25;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .tab-label {
  display: inline-block;
  margin-right: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-size: 1.08rem;
  vertical-align: middle;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .tab-value {
  display: inline-block;
  color: #047bf8;
  margin-right: 10px;
  text-transform: none;
  letter-spacing: normal;
  vertical-align: middle;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .badge {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.72rem;
  letter-spacing: normal;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .badge span {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.72rem;
}


/*
 #11. Error Page
*/
.big-error-w {
  width: 450px;
  text-align: center;
  padding: 50px;
  margin: 0px auto;
  /* margin-top: 100px; */
}

.big-error-w h1 {
  font-size: 4.75rem;
  margin-bottom: 0px;
}

.big-error-w h5 {
  color: #047bf8;
  margin-bottom: 3rem;
}

.big-error-w h4 {
  margin-bottom: 0px;
}

.big-error-w form {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

/*
 #12. Authorization styles
*/
.auth-box-w {
  max-width: 450px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeUp;
  animation-name: fadeUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.auth-box-w.wider {
  max-width: 600px;
}

.auth-box-w .logged-user-w {
  padding-top: 0px;
  padding-bottom: 10px;
}

.auth-box-w .logo-w {
  text-align: center;
  padding: 10%;
  /* padding-bottom: 0; */
  padding-left: 55px;
}

.auth-box-w .auth-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  padding-left: 80px;
}

.auth-box-w .auth-header:after {
  content: "";
  background-color: #047bf8;
  width: 32px;
  height: 7px;
  border-radius: 2px;
  display: block;
  position: absolute;
  bottom: -4px;
  left: 80px;
}

.auth-box-w form {
  padding: 20px 80px;
  padding-bottom: 60px;
}

.auth-box-w form .form-check-inline {
  margin-left: 1rem;
}

.auth-box-w form .form-group {
  position: relative;
}

.auth-box-w form .form-group .pre-icon {
  position: absolute;
  bottom: 5px;
  font-size: 24px;
  color: #047bf8;
  left: -38px;
}

.auth-box-w .buttons-w {
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.auth-box-w.centered {
  text-align: center;
}

.auth-box-w.centered .auth-header {
  padding-left: 0px;
}

.auth-box-w.centered .auth-header:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
}



/* 10. Helpful little styles */
.relative {
  position: relative;
}

.padded-v {
  padding: 1rem 10px;
}

.padded-v-big {
  padding: 2rem 10px;
}

.padded {
  padding: 1rem 2rem;
}

.b-l {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.b-r {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.b-t {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.b-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.m-t {
  margin-top: 1rem;
}

.m-b {
  margin-bottom: 1rem;
}


.with-avatar {
  margin-right: 5px;
}

.with-avatar img {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.with-avatar span {
  display: inline-block;
  vertical-align: middle;
}

a.with-avatar span {
  border-bottom: 1px solid #047bf8;
}

.add-agent-btn i {
  margin-right: 15px;
  padding-left: 5px;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
}

.add-agent-btn span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.centered-load-more-link {
  display: block;
  padding: 10px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.centered-load-more-link i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-right: 5px;
}

.centered-load-more-link span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.centered-load-more-link:hover span {
  border-bottom-color: #0362c6;
}

.centered-load-more-link.smaller {
  font-size: 0.8rem;
}

.text-faded {
  color: rgba(90, 99, 126, 0.49);
}

.text-bright {
  color: #1D1F28;
}


/* 13. App - Email */
/* Email App styles
#1. EMAIL APP WRAPPER
#2. LEFT MENU
#3. MESSAGES LIST
#4. CONTENT BOX
#5. MESSAGE BOX
#6. REPLY
#7. FOLDED STYLES
#8. DARK SCHEME
*/
/* #1. EMAIL APP WRAPPER */
.app-email-w a:focus, .app-email-w a:hover {
  text-decoration: none;
}

.app-email-i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  background-color: #fff;
  border-radius: 6px;
}

/* #2. LEFT MENU */
.ae-side-menu {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 170px;
          flex: 0 0 170px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ebeef3;
}

.ae-side-menu .aem-head {
  padding: 10px 20px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 10px;
}

.ae-side-menu .ae-main-menu {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.ae-side-menu .ae-main-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.ae-side-menu .ae-main-menu li a {
  display: block;
  padding: 20px;
}

.ae-side-menu .ae-main-menu li a i {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #047bf8;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.ae-side-menu .ae-main-menu li a span {
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #3E4B5B;
  font-weight: 500;
  text-transform: uppercase;
}

.ae-side-menu .ae-main-menu li:after {
  content: "";
  position: absolute;
  right: 0px;
  top: -1px;
  bottom: -1px;
  width: 5px;
  opacity: 0;
  background-color: #047bf8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ae-side-menu .ae-main-menu li:hover a i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.ae-side-menu .ae-main-menu li:hover:after, .ae-side-menu .ae-main-menu li.active:after {
  opacity: 1;
}

.ae-side-menu .ae-labels {
  margin-top: 20px;
}

.ae-side-menu .ae-labels .ae-labels-header {
  padding: 20px;
}

.ae-side-menu .ae-labels .ae-labels-header i {
  color: #047bf8;
  font-size: 20px;
  vertical-align: middle;
  display: inline-block;
}

.ae-side-menu .ae-labels .ae-labels-header span {
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
}

.ae-side-menu .ae-labels .ae-label {
  display: block;
  padding: 10px;
  padding-left: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  color: #3E4B5B;
  white-space: nowrap;
}

.ae-side-menu .ae-labels .ae-label .label-pin {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #047bf8;
  vertical-align: middle;
}

.ae-side-menu .ae-labels .ae-label .label-value {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.ae-side-menu .ae-labels .ae-label:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ae-side-menu .ae-labels .ae-label.ae-label-red .label-pin {
  background-color: #e65252;
}

.ae-side-menu .ae-labels .ae-label.ae-label-green .label-pin {
  background-color: #24b314;
}

.ae-side-menu .ae-labels .ae-label.ae-label-yellow .label-pin {
  background-color: #fbe4a0;
}

/* #3. MESSAGES LIST */
.ae-list-w {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 370px;
          flex: 0 0 370px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.ae-list-w .ael-head {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ae-list-w .ael-head a {
  display: inline-block;
  vertical-align: middle;
}

.ae-list-w .ael-head a i {
  color: #047bf8;
  font-size: 20px;
}

.ae-list-w .ael-head a:hover {
  text-decoration: none;
}

.ae-list-w .ael-head a + a {
  margin-left: 15px;
}

.ae-list-w .ae-list {
  height: 1000px;
  overflow: hidden;
  position: relative;
}

.ae-list-w .ae-list .ae-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  cursor: pointer;
}

.ae-list-w .ae-list .ae-item.with-status .user-avatar-w {
  position: relative;
}

.ae-list-w .ae-list .ae-item.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 0px 3px #fff;
          box-shadow: 0px 0px 0px 3px #fff;
}

.ae-list-w .ae-list .ae-item.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.ae-list-w .ae-list .ae-item.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.ae-list-w .ae-list .ae-item.with-status.status-blue .user-avatar-w:before {
  background-color: #047bf8;
}

.ae-list-w .ae-list .ae-item.with-status.status-yellow .user-avatar-w:before {
  background-color: #fbe4a0;
}

.ae-list-w .ae-list .ae-item.active {
  background-color: #047bf8;
  color: #fff;
}

.ae-list-w .ae-list .ae-item.active .aei-content .aei-title {
  color: #fff;
}

.ae-list-w .ae-list .ae-item.active .aei-content .aei-sub-title {
  color: #fff;
}

.ae-list-w .ae-list .ae-item.active .aei-content .aei-text {
  color: rgba(255, 255, 255, 0.5);
}

.ae-list-w .ae-list .ae-item.active .aei-timestamp {
  color: rgba(255, 255, 255, 0.5);
}

.ae-list-w .ae-list .ae-item.active .user-avatar-w {
  -webkit-box-shadow: 0px 0px 0px 3px #fff;
          box-shadow: 0px 0px 0px 3px #fff;
  border-radius: 50%;
}

.ae-list-w .ae-list .ae-item:hover {
  background-color: #f9f9f9;
}

.ae-list-w .ae-list .ae-item:hover.active {
  background-color: #047bf8;
}

.ae-list-w .ae-list .aei-image {
  margin-right: 20px;
}

.ae-list-w .ae-list .aei-image .user-avatar-w {
  width: 50px;
}

.ae-list-w .ae-list .aei-image .user-avatar-w img {
  border-radius: 50px;
  display: block;
  width: 50px;
  height: auto;
}

.ae-list-w .ae-list .aei-content {
  position: relative;
}

.ae-list-w .ae-list .aei-content .aei-timestamp {
  position: absolute;
  top: -10px;
  right: 0px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.63rem;
}

.ae-list-w .ae-list .aei-content .aei-title {
  margin-bottom: 4px;
}

.ae-list-w .ae-list .aei-content .aei-sub-title {
  color: #a7aeb5;
  text-transform: uppercase;
  font-size: 0.72rem;
  font-weight: 500;
}

.ae-list-w .ae-list .aei-content .aei-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.81rem;
}

.ae-list-w .ae-load-more {
  display: block;
  padding: 10px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.ae-list-w .ae-load-more i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-right: 5px;
}

.ae-list-w .ae-load-more span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.ae-list-w .ae-load-more:hover span {
  border-bottom-color: #0362c6;
}

/* #4. CONTENT BOX */
.ae-content-w {
  background-color: #f6f7f8;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.ae-content-w .aec-head {
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff;
}

.ae-content-w .aec-head .separate {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 15px;
  margin-right: 5px;
}

.ae-content-w .aec-head a {
  display: inline-block;
  vertical-align: middle;
}

.ae-content-w .aec-head a i {
  color: #047bf8;
  font-size: 20px;
}

.ae-content-w .aec-head a.highlight i {
  color: #CB9E48;
}

.ae-content-w .aec-head a:hover {
  text-decoration: none;
}

.ae-content-w .aec-head a + a {
  margin-left: 15px;
}

.ae-content-w .aec-head .actions-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ae-content-w .aec-head .actions-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ae-content-w .aec-head .user-avatar {
  margin-left: 40px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.ae-content-w .aec-head .user-avatar img {
  display: block;
  width: 30px;
  height: auto;
  border-radius: 40px;
}

.ae-content {
  padding: 40px;
}

/* #5. MESSAGE BOX */
.aec-full-message-w {
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
}

.aec-full-message-w .more-messages {
  position: absolute;
  left: 50%;
  top: -55px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: inline-block;
  border-bottom: 1px solid transparent;
  padding-bottom: 1px;
}

.aec-full-message-w .more-messages:hover {
  cursor: pointer;
  color: #047bf8;
  border-bottom: 1px solid #047bf8;
}

.aec-full-message-w.show-pack {
  margin-top: 40px;
}

.aec-full-message-w.show-pack:before {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  top: -30px;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.aec-full-message-w.show-pack:after {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  top: -15px;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.aec-full-message-w .aec-full-message {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.aec-full-message-w .message-head {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.aec-full-message-w .message-head .user-w {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.aec-full-message-w .message-head .user-w .user-avatar-w {
  width: 50px;
}

.aec-full-message-w .message-head .user-w .user-avatar-w .user-avatar {
  border-radius: 40px;
  overflow: hidden;
}

.aec-full-message-w .message-head .user-w .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.aec-full-message-w .message-head .user-w.with-status .user-avatar-w {
  position: relative;
}

.aec-full-message-w .message-head .user-w.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 0px 3px #fff;
          box-shadow: 0px 0px 0px 3px #fff;
}

.aec-full-message-w .message-head .user-w.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.aec-full-message-w .message-head .user-w.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.aec-full-message-w .message-head .user-w .user-name {
  padding-left: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.aec-full-message-w .message-head .user-w .user-title {
  margin-bottom: 2px;
  color: #047bf8;
}

.aec-full-message-w .message-head .user-w .user-role {
  font-weight: 500;
  font-size: 0.81rem;
}

.aec-full-message-w .message-head .user-w .user-role span {
  display: inline-block;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.4);
}

.aec-full-message-w .message-head .user-w .user-action {
  width: 50px;
  color: #047bf8;
  font-size: 18px;
}

.aec-full-message-w .message-head .message-info {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.72rem;
  text-align: right;
}

.aec-full-message-w .message-content {
  padding: 6% 10% 8% 10%;
}

.aec-full-message-w .message-attachments {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  margin-top: 20px;
}

.aec-full-message-w .message-attachments .attachments-heading {
  text-transform: uppercase;
  font-size: 0.72rem;
  color: rgba(0, 0, 0, 0.4);
}

.aec-full-message-w .message-attachments .attachments-docs {
  margin-top: 15px;
}

.aec-full-message-w .message-attachments .attachments-docs a {
  display: inline-block;
}

.aec-full-message-w .message-attachments .attachments-docs a i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.aec-full-message-w .message-attachments .attachments-docs a span {
  display: inline-block;
  vertical-align: middle;
}

.aec-full-message-w .message-attachments .attachments-docs a:hover {
  text-decoration: none;
}

.aec-full-message-w .message-attachments .attachments-docs a + a {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.older-pack {
  display: none;
}

/* #6. REPLY */
.aec-reply {
  padding: 40px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.aec-reply .reply-header {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
  margin-bottom: 0px;
}

.aec-reply .reply-header i {
  font-size: 130px;
  color: rgba(0, 86, 255, 0.05);
  position: absolute;
  top: -70px;
  left: -70px;
}

.aec-reply .reply-header h5 {
  margin: 0px;
  font-size: 1.08rem;
}

.aec-reply .reply-header h5 span {
  color: #047bf8;
  border-bottom: 1px dotted #047bf8;
  display: inline-block;
}

.aec-reply .buttons-w {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.aec-reply .buttons-w .btn, .aec-reply .buttons-w .all-wrapper .fc-button, .all-wrapper .aec-reply .buttons-w .fc-button {
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 0.7rem;
}

.aec-reply .cke_chrome {
  border: none;
}

.aec-reply .cke_bottom {
  border: none;
}

/* #7. FOLDED STYLES */
.app-email-w.compact-side-menu .ae-side-menu {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 60px;
          flex: 0 1 60px;
  text-align: center;
}

.app-email-w.compact-side-menu .ae-side-menu .aem-head {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-main-menu li a span {
  display: none;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-labels .ae-label {
  padding-left: 10px;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-labels .ae-label span.label-value {
  display: none;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-labels-header span {
  display: none;
}


.control-header {
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.control-header select.form-control {
  font-weight: 400;
  border-color: rgba(0, 0, 0, 0.5);
}

.control-header label {
  text-transform: uppercase;
  font-size: 0.72rem;
  letter-spacing: 1px;
}

.control-header .form-group {
  margin-bottom: 0px;
}

/* 18. Dropdowns */
.os-dropdown-trigger {
  position: relative;
  cursor: pointer;
}

.os-dropdown-trigger.over .os-dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(100%, 100%, 0);
          transform: translate3d(100%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-center .os-dropdown {
  right: 50%;
  -webkit-transform: translate3d(50%, 110%, 0);
          transform: translate3d(50%, 110%, 0);
}

.os-dropdown-trigger.os-dropdown-position-center.over .os-dropdown {
  -webkit-transform: translate3d(50%, 100%, 0);
          transform: translate3d(50%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left .os-dropdown {
  right: 0px;
  -webkit-transform: translate3d(0%, 110%, 0);
          transform: translate3d(0%, 110%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left.over .os-dropdown {
  -webkit-transform: translate3d(0%, 100%, 0);
          transform: translate3d(0%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right .os-dropdown {
  right: 100%;
  -webkit-transform: translate3d(100%, 110%, 0);
          transform: translate3d(100%, 110%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right.over .os-dropdown {
  -webkit-transform: translate3d(100%, 100%, 0);
          transform: translate3d(100%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right-center .os-dropdown {
  right: 0%;
  bottom: 50%;
  -webkit-transform: translate3d(110%, 50%, 0);
          transform: translate3d(110%, 50%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right-center.over .os-dropdown {
  -webkit-transform: translate3d(100%, 50%, 0);
          transform: translate3d(100%, 50%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left-center .os-dropdown {
  right: 100%;
  bottom: 50%;
  -webkit-transform: translate3d(-10%, 50%, 0);
          transform: translate3d(-10%, 50%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left-center.over .os-dropdown {
  -webkit-transform: translate3d(0%, 50%, 0);
          transform: translate3d(0%, 50%, 0);
}

.os-dropdown {
  background-color: #047bf8;
  color: #fff;
  padding: 20px 15px;
  position: absolute;
  z-index: 999;
  bottom: 5px;
  right: 0px;
  visibility: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translate3d(100%, 110%, 0);
          transform: translate3d(100%, 110%, 0);
  opacity: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0px 10px 25px 0px rgba(26, 32, 39, 0.1);
          box-shadow: 0px 10px 25px 0px rgba(26, 32, 39, 0.1);
  overflow: hidden;
  font-size: 0.9rem;
  text-align: left;
}

.os-dropdown > .icon-w {
  position: absolute;
  top: -30px;
  right: -30px;
  color: rgba(0, 0, 0, 0.08);
  font-size: 100px;
}

.os-dropdown ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.os-dropdown ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.os-dropdown ul li:last-child {
  border-bottom: none;
}

.os-dropdown ul li a {
  display: block;
  white-space: nowrap;
  padding: 10px 10px 10px 10px;
  line-height: 1;
  color: #fff;
  font-size: 0.9rem;
}

.os-dropdown ul li a:hover {
  text-decoration: none;
}

.os-dropdown ul li a i {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  font-size: 22px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-dropdown ul li a span {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-dropdown ul li a i + span {
  padding-right: 10px;
}

.os-dropdown ul li a:hover i {
  color: #fff;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.os-dropdown ul li a:hover span {
  -webkit-transform: translateX(3px);
          transform: translateX(3px);
}

.os-dropdown.message-list {
  padding: 15px 10px;
}

.os-dropdown.message-list li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.os-dropdown.message-list li:last-child {
  border-bottom: none;
}

.os-dropdown.message-list li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 15px;
}

.os-dropdown.message-list li > a:hover {
  background-color: rgba(124, 137, 234, 0.07);
}

.os-dropdown.message-list li > a:hover .user-avatar-w img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.os-dropdown.message-list li > a:hover .message-content {
  -webkit-transform: translateX(3px);
          transform: translateX(3px);
}

.os-dropdown.message-list li > a .user-avatar-w {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40px;
          flex: 0 0 40px;
  margin-right: 15px;
}

.os-dropdown.message-list li > a .user-avatar-w img {
  width: 40px;
  border-radius: 30px;
  height: auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: block;
}

.os-dropdown.message-list li > a .message-content {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-dropdown.message-list li > a .message-content .message-from {
  color: #fff;
  margin-bottom: 5px;
}

.os-dropdown.message-list li > a .message-content .message-title {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.54rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 2px;
  margin-bottom: 0px;
}

.os-dropdown.light {
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 75px 0px rgba(12, 76, 140, 0.21), 0px 3px 7px 0px rgba(12, 76, 140, 0.14);
          box-shadow: 0px 5px 75px 0px rgba(12, 76, 140, 0.21), 0px 3px 7px 0px rgba(12, 76, 140, 0.14);
}

.os-dropdown.light.message-list > .icon-w {
  color: rgba(4, 123, 248, 0.1);
}

.os-dropdown.light.message-list li {
  border-bottom: 1px solid rgba(113, 133, 171, 0.09);
}

.os-dropdown.light.message-list li:last-child {
  border-bottom: none;
}

.os-dropdown.light.message-list li > a .message-content .message-from {
  color: #334152;
}

.os-dropdown.light.message-list li > a .message-content .message-title {
  color: #047bf8;
}




.all-wrapper > .top-bar {
  border-radius: 6px 6px 0px 0px;
}

.content-w > .top-bar {
  padding-left: 0px;
}

.top-bar {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  padding: 0px 20px 0px 0px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  /* border-radius: 0px 6px 0px 0px; */
}

.top-bar > ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  color: #3E4B5B;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 0.99rem;
}

.top-bar > ul > li {
  display: inline-block;
}

.top-bar > ul li a {
  display: inline-block;
  padding: 20px 25px;
  color: rgb(0 0 0 / 47%);
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar > ul > li > a:after {
  content: "";
  background-color: #047bf8;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0px;
  height: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar > ul li.active > a, .top-bar > ul li:hover > a {
  color: #3E4B5B;
  text-decoration: none;
}

.top-bar > ul li.active a:after, .top-bar > ul > li:hover a:after {
  width: 100%;
}

.top-bar .fancy-selector-w {
  margin-right: auto;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.top-bar .logo-w.menu-size + .fancy-selector-w {
  margin-left: 0px;
}

.top-bar .logo-w {
  text-align: left;
}

.top-bar .logo-w a {
  display: inline-block;
}

.top-bar .logo-w img {
  height: 25px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
}

.top-bar .logo-w span {
  display: inline-block;
  vertical-align: middle;
}

.top-bar .logo-w img + span {
  margin-left: 20px;
  color: #3E4B5B;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.81rem;
  font-weight: 500;
}

.top-bar .logo-w.menu-size {
  width: 260px;
}

.top-bar .top-menu-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
  padding: 7px 0px;
}

.top-bar .top-menu-controls .element-search {
  position: relative;
  margin: 0px 1rem;
}

.top-bar .top-menu-controls .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.top-bar .top-menu-controls .element-search input {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 220px;
  outline: none;
  border: none;
  box-shadow: none;
  background: #eee;
}

.top-bar .top-menu-controls .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
  color: #047bf8;
}

.top-bar .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.top-bar .messages-notifications i {
  vertical-align: middle;
}

.top-bar .top-icon {
  margin: 0px 1rem;
  color: #047bf8;
  font-size: 26px;
}

.top-bar .top-icon i {
  vertical-align: middle;
}

.top-bar .logged-user-w {
  position: relative;
  padding: 0px 1rem;
  border-bottom: none;
}

.top-bar .logged-user-w .avatar-w {
  padding: 0px;
  border: none;
  vertical-align: middle;
}

.top-bar .logged-user-w .avatar-w img {
  width: 40px;
}

.top-bar .logged-user-w .logged-user-i {
  display: inline-block;
}

.top-bar .logged-user-w .logged-user-i:hover {
  cursor: pointer;
}

.top-bar .logged-user-w .logged-user-i:hover .logged-user-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.top-bar .logged-user-w .logged-user-menu {
  background: #1b55e2;
  -webkit-box-shadow: 0 10px 30px 0 rgba(27, 30, 35, 0.27);
          box-shadow: 0 10px 30px 0 rgba(27, 30, 35, 0.27);
  position: absolute;
  top: -10px;
  right: -20px;
  overflow: hidden;
  padding: 1rem;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: left;
  border-radius: 6px;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-avatar-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0px 30px 10px 13px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.top-bar .logged-user-w .logged-user-menu .avatar-w {
  border-color: #fff;
  vertical-align: middle;
  margin-right: 20px;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-info-w {
  vertical-align: middle;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-info-w .logged-user-name {
  color: #fff;
  white-space: nowrap;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-info-w .logged-user-role {
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
}

.top-bar .logged-user-w .logged-user-menu ul {
  list-style: none;
  text-align: left;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0px;
}

.top-bar .logged-user-w .logged-user-menu ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.top-bar .logged-user-w .logged-user-menu ul li a {
  display: block;
  padding: 10px 20px;
  color: #fff;
}

.top-bar .logged-user-w .logged-user-menu ul li a i {
  vertical-align: middle;
  margin-right: 15px;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.top-bar .logged-user-w .logged-user-menu ul li a span {
  vertical-align: middle;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.top-bar .logged-user-w .logged-user-menu ul li a:hover {
  text-decoration: none;
}

.top-bar .logged-user-w .logged-user-menu ul li a:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.top-bar .logged-user-w .logged-user-menu ul li a:hover span {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.top-bar .logged-user-w .logged-user-menu ul li:last-child {
  border-bottom: none;
}

.top-bar .logged-user-w .logged-user-menu .bg-icon {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -40px;
  right: -20px;
}

.top-bar.color-scheme-transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.top-bar.color-scheme-light .top-menu-controls .element-search input, .top-bar.color-scheme-transparent .top-menu-controls .element-search input {
  background-color: rgba(121, 138, 185, 0.07);
}

.top-bar.color-scheme-light .logo-w .logo-label, .top-bar.color-scheme-transparent .logo-w .logo-label {
  color: #3E4B5B;
}

.top-bar.color-scheme-light .logo-w .logo-element, .top-bar.color-scheme-transparent .logo-w .logo-element {
  background-color: #0a7cf8;
}

.top-bar.color-scheme-light .logo-w .logo-element:after, .top-bar.color-scheme-transparent .logo-w .logo-element:after {
  background-color: #bfd6f9;
}

.top-bar.color-scheme-light .logo-w .logo:hover .logo-label, .top-bar.color-scheme-transparent .logo-w .logo:hover .logo-label {
  color: #047bf8;
}

.top-bar.color-scheme-light .logo-w .logo:hover .logo-label:after, .top-bar.color-scheme-transparent .logo-w .logo:hover .logo-label:after {
  background-color: #047bf8;
}

.top-bar.color-scheme-dark, .top-bar.color-scheme-bright {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input, .top-bar.color-scheme-bright .top-menu-controls .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::-webkit-input-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input:-ms-input-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::-ms-input-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search:before, .top-bar.color-scheme-bright .top-menu-controls .element-search:before {
  color: rgba(255, 255, 255, 0.7);
}

.top-bar.color-scheme-dark .messages-notifications,
.top-bar.color-scheme-dark .top-icon, .top-bar.color-scheme-bright .messages-notifications,
.top-bar.color-scheme-bright .top-icon {
  color: #fff;
}

.top-bar.color-scheme-dark > ul li a, .top-bar.color-scheme-bright > ul li a {
  color: rgba(255, 255, 255, 0.4);
}

.top-bar.color-scheme-dark > ul li a:after, .top-bar.color-scheme-bright > ul li a:after {
  background-color: #FBB463;
  border-color: #FBB463;
}

.top-bar.color-scheme-dark > ul li.active a, .top-bar.color-scheme-dark > ul li:hover a, .top-bar.color-scheme-bright > ul li.active a, .top-bar.color-scheme-bright > ul li:hover a {
  color: #fff;
}

.top-bar.color-scheme-dark .logo-w img + span, .top-bar.color-scheme-bright .logo-w img + span {
  color: #fff;
}

.top-bar.color-scheme-dark .fancy-selector-w .fancy-selector-current .fs-selector-trigger, .top-bar.color-scheme-bright .fancy-selector-w .fancy-selector-current .fs-selector-trigger {
  background-color: #3572ed;
}

.top-bar.color-scheme-dark {
  background-color: #323c58;
}

.top-bar.color-scheme-bright {
  background-color: #1b55e2;
}




.box-style-dark, .color-scheme-dark .ae-content-w .aec-full-message-w .aec-full-message, .color-scheme-dark .ae-content-w .aec-reply, body.color-scheme-dark .box-style, body.color-scheme-dark .user-profile, body.color-scheme-dark .element-box, body.color-scheme-dark .invoice-w, body.color-scheme-dark .big-error-w, body.color-scheme-dark .activity-boxes-w .activity-box, .activity-boxes-w body.color-scheme-dark .activity-box, body.color-scheme-dark .post-box, body.color-scheme-dark .projects-list .project-box, .projects-list body.color-scheme-dark .project-box,
body.color-scheme-dark .profile-tile .profile-tile-box, .content-panel.color-scheme-dark .box-style, .content-panel.color-scheme-dark .user-profile, .content-panel.color-scheme-dark .element-box, .content-panel.color-scheme-dark .invoice-w, .content-panel.color-scheme-dark .big-error-w, .content-panel.color-scheme-dark .activity-boxes-w .activity-box, .activity-boxes-w .content-panel.color-scheme-dark .activity-box, .content-panel.color-scheme-dark .post-box, .content-panel.color-scheme-dark .projects-list .project-box, .projects-list .content-panel.color-scheme-dark .project-box,
.content-panel.color-scheme-dark .profile-tile .profile-tile-box {
  background-color: #323c58;
  -webkit-box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
          box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#323c58), to(#303950));
  background-image: linear-gradient(to bottom, #323c58 0%, #303950 100%);
  background-repeat: repeat-x;
}



/* ##. Responsive Styles */
/*

Contents:

#1.  Screen size <= 1650px
#2.  Screen size <= 1550px
#3.  Screen size <= 1400px
#4.  Screen size <= 1350px
#5.  Screen size between 1100px - 1350px
#6.  Screen size <= 1250px
#7.  Screen size <= 1150px
#8.  Tablets and phones  (< 1024px)
#9.  Tablets (768px - 1024px)
#10. Phones & Vertical Tablets (<= 768px)
#11. Phones (< 767px)
#12. Screen Height >= 700px


*/
/* #1.  Screen size <= 1650px */
@media (max-width: 1650px) {
  body,
  body.auth-wrapper .all-wrapper {
    padding: 40px;
  }
  .content-panel {
    padding: 2rem;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 350px;
            flex: 0 0 350px;
  }
  .content-panel.compact {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
            flex: 0 0 300px;
    padding: 2rem 1rem;
  }
  .content-box {
    /* padding-top: 10px; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
  }
  .all-wrapper {
    max-width: 100%;
  }
}

/* #2.  Screen size <= 1550px */
@media (max-width: 1550px) {
  body,
  body.auth-wrapper .all-wrapper {
    padding: 20px;
  }
  .menu-side-w ul.main-menu {
    padding: 1.5rem 1rem;
  }
  .menu-side-w ul.main-menu > li .icon-w {
    padding-left: 1rem;
    width: 75px;
  }
  .menu-side-w ul.sub-menu {
    padding-left: 37px;
  }
  .menu-side-w {
    width: 260px;
  }
  .menu-side-w .logged-user-menu ul {
    padding-left: 30px;
    padding-right: 30px;
  }

}

/* #3.  Screen size <= 1400px */
@media (max-width: 1400px) {
  .menu-side-w ul.main-menu > li .icon-w {
    padding-left: 10px;
    width: 65px;
  }
  .menu-side-w {
    width: 230px;
  }
  .menu-side-w .side-menu-magic {
    padding: 30px 20px;
  }
  .menu-side-w .logged-user-menu ul {
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu-side-w ul.sub-menu {
    padding-left: 30px;
  }
  .menu-top-image-w ul.main-menu > li .icon-w {
    padding-left: 20px;
  }
  .menu-top-image-w ul.main-menu > li span {
    padding-right: 20px;
  }

}

/* #4.  Screen size <= 1350px */
@media (max-width: 1350px) {
  .top-bar .fs-extra-info {
    display: none;
  }
  .menu-side-w .logo-w {
    padding: 1.5rem 1rem;
  }
  .menu-top-w .menu-top-i .logo-w {
    padding: 0rem 1rem;
    width: 50px;
  }
  .menu-top-w .menu-top-i ul.main-menu {
    padding-left: 0px;
  }

}

/* #5.  Screen size between 1100px - 1350px */
@media (min-width: 1100px) and (max-width: 1350px) {
  .content-panel {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
            flex: 0 0 300px;
    padding: 1.5rem;
  }
  .padded {
    padding: 0.5rem 1rem;
  }
  .content-box {
    /* padding: 1.5rem; */
    /* margin-bottom: 50px; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
  }

}

/* #6.  Screen size <= 1250px */
@media (max-width: 1250px) {
  .element-box, .invoice-w, .big-error-w, .invoice-w, .big-error-w {
    /* padding: 1rem 1.5rem; */
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  body,
  body.auth-wrapper .all-wrapper {
    padding: 0px;
  }
  .content-w,
  .menu-side-w,
  .all-wrapper {
    border-radius: 0px !important;
    /* overflow: hidden; */
  }
  .menu-side-w .logged-user-menu {
    left: 0px;
    right: 0px;
  }
  .support-index .support-ticket-content-w .support-ticket-info {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 250px;
    -webkit-box-shadow: 0px 20px 60px rgba(4, 123, 248, 0.3);
            box-shadow: 0px 20px 60px rgba(4, 123, 248, 0.3);
    background-color: #fff;
    background-image: none;
    display: none;
  }
}

/* #7.  Screen size <= 1150px */
@media (max-width: 1150px) {
  .ae-content {
    padding: 0px;
  }
  .aec-full-message-w {
    margin-bottom: 0px;
  }
  .ae-content-w {
    background-image: none;
    background-color: #fff;
  }
  .aec-full-message-w .more-messages {
    top: -32px;
    background-color: #fff;
    padding: 3px 5px;
    border-radius: 4px;
    color: #047bf8;
  }
  .ae-list-w {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
            flex: 0 0 300px;
  }
  .ae-list-w .ae-list .aei-image {
    display: none;
  }
  .layout-w {
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    position: relative;
    z-index: 0;
  }
  .content-box:after {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(4, 36, 113, 0.6);
    content: "";
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .content-panel {
    max-width: 400px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(8%, #EFF2F9), color-stop(90%, #fff));
    background-image: linear-gradient(-90deg, #EFF2F9 8%, #fff 90%);
    z-index: 4;
    border: none;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    visibility: hidden;
    z-index: 11;
  }
  .content-panel.compact {
    max-width: 320px;
  }
  .content-panel .content-panel-close {
    display: block;
  }
  .content-panel.color-scheme-dark {
    background-color: #293145;
    background-image: -webkit-gradient(linear, right top, left top, from(#293145), to(#293145));
    background-image: linear-gradient(-90deg, #293145, #293145);
  }
  body.color-scheme-dark .content-panel {
    background-color: #293145;
    background-image: -webkit-gradient(linear, right top, left top, from(#293145), to(#293145));
    background-image: linear-gradient(-90deg, #293145, #293145);
  }
  .content-panel-toggler {
    display: block;
  }
  .content-i, .with-side-panel .content-i {
    display: block;
  }
  .content-i .content-box, .with-side-panel .content-i .content-box {
    display: block;
  }
  .content-panel-active .content-panel {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
    visibility: visible;
    -webkit-box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.4);
  }
  .content-panel-active .layout-w {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
  .content-panel-active .content-box:after {
    opacity: 1;
    visibility: visible;
  }
}

/* #8.  Tablets and phones  (< 1024px) */
@media (max-width: 1024px) {
  .invoice-w {
    padding: 50px;
  }
  .ae-list-w {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 230px;
            flex: 0 0 230px;
  }
  .ae-list-w .ae-list .ae-item {
    padding: 15px;
  }
  .table th,
  .table td {
    padding: 0.7rem 0.5rem;
  }
  .content-w {
    border-radius: 0px !important;
  }
}

/* #9.  Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .top-bar .logo-w {
    display: none;
  }
  .top-bar .fs-extra-info {
    display: none;
  }
  .element-wrapper {
    padding-bottom: 2rem;
  }
  .top-bar {
    padding: 0px;
  }
  .top-bar > ul {
    white-space: nowrap;
  }
  .top-bar > ul li a {
    padding: 15px 10px;
    margin: 0px 10px;
  }
  .top-bar.with-overflow {
    overflow: visible;
  }
  .nav.upper.nav-tabs .nav-link {
    margin-right: 0.7rem;
    padding-bottom: 10px;
    font-size: 0.72rem;
  }
  .display-type {
    content: "tablet";
  }
  .content-box {
    padding: 1.5rem;
  }
  .padded {
    padding: 0.3rem 0.8rem;
  }
  .element-box, .invoice-w, .big-error-w, .invoice-w, .big-error-w {
    padding: 1rem 1rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .layout-w {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .user-profile .up-header {
    font-size: 2.5rem;
  }
  .user-profile .up-sub-header {
    font-size: 1rem;
  }
}

/* #10. Phones & Vertical Tablets (<= 768px) */
@media (max-width: 768px) {
  .element-balances.mobile-full-width > .balance {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0px;
    margin-bottom: 30px;
    text-align: center;
  }
  .os-tabs-w .os-tabs-controls {
    margin-bottom: 1rem;
  }
  .os-tabs-w .os-tabs-complex .nav-item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .os-tabs-w .os-tabs-complex .nav-item .nav-link {
    border-right: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 15px;
  }
  .os-tabs-w .os-tabs-complex .nav-item:first-child .nav-link {
    border-top: none;
  }
}

/* #11. Phones (< 767px) */
@media (max-width: 767px) {
  .layout-w {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .floated-customizer-btn {
    display: none;
  }
  .hidden-mobile {
    display: none !important;
  }
  .support-index .st-foot {
    padding: 10px 10px;
  }
  .support-index .support-ticket + .support-ticket {
    margin-top: 15px;
  }
  .top-bar .logo-w {
    display: none;
  }
  .top-bar.with-overflow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .top-bar.with-overflow .top-menu-controls {
    display: none;
  }
  .top-bar .fs-extra-info {
    display: none;
  }
  .element-balances {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .element-balances .balance {
    border: none !important;
  }
  .cell-with-media {
    text-align: center;
  }
  .cell-with-media img {
    margin-bottom: 5px;
  }
  .cell-with-media img + span {
    margin-left: 0px !important;
  }
  .element-wrapper .element-actions {
    float: none;
    margin-bottom: 20px;
  }
  .element-wrapper .element-actions.actions-only {
    float: right;
    margin-bottom: 0;
  }
  .el-tablo.smaller .value {
    font-size: 1.26rem;
    margin-top: 3px;
  }
  .display-type {
    content: "phone";
  }
  .ae-list-w {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 230px;
            flex: 1 1 230px;
  }
  .ae-content-w {
    display: none;
  }
  .top-bar {
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .top-bar .fancy-selector-w {
    width: 100%;
  }
  .top-bar .fancy-selector-w .fancy-selector-current {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .top-bar .fancy-selector-w .fancy-selector-current .fs-selector-trigger {
    margin-left: auto;
  }
  .top-bar > ul {
    white-space: nowrap;
  }
  .top-bar > ul li a {
    padding: 15px 10px;
    margin: 0px 10px;
  }
  .top-bar.with-overflow {
    overflow: visible;
  }
  .nav.upper.nav-tabs .nav-link {
    margin-right: 0.7rem;
    padding-bottom: 10px;
    font-size: 0.72rem;
  }
  .aec-full-message-w .message-content {
    padding: 15px;
  }
  .aec-full-message-w .message-attachments {
    text-align: center;
  }
  .aec-full-message-w .message-head .user-w .user-role span {
    margin-left: 0;
  }
  .aec-reply {
    padding: 15px;
  }
  .aec-reply .reply-header {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }
  .aec-reply .buttons-w {
    display: block;
    text-align: center;
  }
  .aec-reply .buttons-w .btn, .aec-reply .buttons-w .all-wrapper .fc-button, .all-wrapper .aec-reply .buttons-w .fc-button {
    margin-bottom: 10px;
  }
  .floated-chat-btn i + span {
    display: none;
  }
  .floated-chat-btn {
    padding: 10px 12px;
  }
  .floated-colors-btn {
    padding: 10px 12px;
  }
  .floated-colors-btn.second-floated-btn {
    right: auto;
    left: 10px;
  }
  .floated-colors-btn span + span {
    display: none;
  }
  .padded {
    padding: 1rem;
  }
  .user-profile .up-head-w .up-social {
    top: 5px;
    right: 10px;
  }
  .user-profile .up-head-w .up-social a {
    font-size: 24px;
  }
  .user-profile .up-main-info {
    padding-bottom: 10%;
  }
  .user-profile .up-header {
    font-size: 1.75rem;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .user-profile .up-sub-header {
    font-size: 1rem;
  }
  .user-profile .up-controls {
    text-align: center;
  }
  .user-profile .up-controls .value-pair {
    margin-bottom: 1rem;
  }
  .user-profile .up-controls .text-right {
    text-align: center !important;
  }
  .content-panel-toggler {
    display: none;
  }
  .element-info .element-search {
    margin-top: 1rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .element-box .os-tabs-controls .nav + .nav, .invoice-w .os-tabs-controls .nav + .nav, .big-error-w .os-tabs-controls .nav + .nav, .invoice-w .os-tabs-controls .nav + .nav, .big-error-w .os-tabs-controls .nav + .nav {
    margin-top: 1rem;
  }
  .element-box.el-tablo, .el-tablo.invoice-w, .el-tablo.big-error-w {
    text-align: center;
  }
  .controls-above-table .form-control {
    width: 110px;
    display: inline-block;
  }
  .controls-above-table .btn, .controls-above-table .all-wrapper .fc-button, .all-wrapper .controls-above-table .fc-button {
    margin-bottom: 0.5rem;
  }
  .layout-w {
    display: block;
  }
  .content-w, .menu-side .content-w {
    display: block;
    margin-bottom: 50px;
    margin-top: 55px;
  }
  .content-i {
    display: block;
  }
  .content-i .content-box {
    display: block;
    padding: 15px;
  }
  .content-i .content-panel {
    padding: 30px 15px;
    border-left: none;
    display: block;
    width: auto;
  }
  .big-error-w {
    padding: 1rem;
  }
  .element-wrapper {
    padding-bottom: 1.5rem;
  }
  .element-wrapper.compact {
    padding-bottom: 1rem;
  }
  .element-box, .invoice-w, .big-error-w {
    padding: 1rem;
  }
  canvas {
    max-width: 100%;
  }
  table {
    max-width: 100%;
  }
  .invoice-w {
    padding: 30px;
  }
  .breadcrumb {
    margin-bottom: 1rem;
  }
}

/* #12. Screen Height >= 700px */
@media (min-height: 700px) and (min-width: 768px) {
  .modal-dialog.modal-centered {
    top: 50%;
    -webkit-transform: translate(0, -80%);
            transform: translate(0, -80%);
    margin: 0px auto;
  }
  .modal.show .modal-dialog.modal-centered {
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    margin: 0px auto;
  }
}
