.calendar .top-bar {
	border-top: 1px solid #eeeff0;
	z-index: 5;
	padding-left: 10px;
	display: flex;
	position: fixed;
	left: 0px;
	right: 0px;
	top: 51px;
}
.event-calendar .top-bar > ul {
	font-size:.8rem;
}

.calendar .content-box {
	padding:0px;
}

.event-calendar .day .experience {width:100%;}

.event-calendar .day-range .day {
	width: 100%;
}

.event-calendar .filters .form-check label {max-width: 250px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}

.event-calendar .day {
	width: 14.23%;
	border: 1px solid #f7f7f7;
	min-height: 90px;
	padding: 5px;
	background-color: white;
	position: relative;
	padding-top: 20px;
	transition: all 0.3s ease;
	border-right-color: #e6e6e6;
}
.event-calendar .week-day-view .day {padding-top: 10px;display: flex;padding: 0px;min-height: 55px;margin: 0px;}


.week-day-view .times .day {min-height:inherit;font-size: 12px;}

.event-calendar .top-bar ul {transition: all .4s;}

.event-manifest .bottom .main-content {transition: all .4s;padding-left: 0px;}

.event-calendar .bottom.dropDownOpen .main-content {margin-left: 290px;}

.event-calendar .current-filter-header {
	text-align: left;
}

.experience-day-detail .timeslot .badge {
	font-size: 9px;
	/* position: absolute; */
	margin-top: 4px;
	margin-left: 5px;
	padding-top: 3px;
}

.event-calendar .timeslot .avail-bar div {
	width:100%;
	height: 10px;
	/* border:1px solid red; */
	background-color: #0505051f;
	left: 0px;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 100%;
}

.event-calendar .sticky {
  position: fixed;
  top: 91px;
  width: 100%;
  z-index: 4;
  background-color: #f1f4f8;
  left: 0px;
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.5s ease;
}

.event-calendar .dropDownOpen .sticky {
	left: 290px;
}


.event-calendar .sticky + .content {
  padding-top: 102px;
}

.event-calendar .week .day-num {
	font-size: 24px;
	font-weight: 300;
}

.event-calendar .week .day-num.today {
	background-color: #067bf8;
	border-radius: 50%;
	display: inline-block;
	color: white;
	padding-left: 7px;
	padding-right: 7px;
	font-size: 20px;
	padding-bottom: 3px;
	padding-top: 3px;
}


.event-calendar .period-row h4 {display: inline-block;}

.event-calendar .saved {margin-top:10px;}
.event-calendar .saved .pretty {display:block;margin-bottom: 3px;}

.event-calendar .bottom {display:flex;min-height: 500px;}

.event-calendar .filter-body {
	font-size: 12px;
}

.event-calendar .main-content {width: 100%;padding-top: 20px;padding-left: 20px;padding-right: 10px;}

.event-calendar .form-check {padding-left: 10px;}

.event-calendar .month-view .row.header .day:first-child {
	border-top-left-radius: 6px;
}

.event-calendar .month-view .row.header .day:last-child {
	border-top-right-radius: 6px;
}

.event-calendar .month-view .day:nth-child(n+2) {
    margin-left: -1px;
}

.event-calendar .month-view .day.blank {background-color: #f9f9f9;}

.event-calendar .experience-item {
	cursor:pointer;
	position: relative;
	padding: 3px;
}

.calendar .top-bar > ul li a {
	padding-top: 12px;
	padding-bottom: 10px;
}

.calendar .top-bar > ul {
	font-size:.8rem;
}

.week-day-view .period-row {margin-bottom:15px;/* margin-top: 10px; */}

.event-calendar .main-content .week-day-view {position:inherit;}

.event-calendar .time-marker {
	font-size:10px;
}

.calendar .dropDownOpen .time-marker {
	display:none;
}

.event-calendar .resource-item-wrapper.hoverTrip .resource-item {
	box-shadow: 0 0 0 1px rgba(42,47,69,.24), 0 2px 5px 0 rgba(42,47,69,.24), 0 3px 9px 0 rgba(42,47,69,0.24), 0 1px 1.5px 0 rgba(0,0,0,.24), 0 1px 2px 0 rgba(0,0,0,.24);
}

.resource .resource-item {overflow:hidden;}

.event-calendar .time-marker .time {
    text-align: center;
    background-color: #ff8282;
    border-radius: 3px;
    color: white;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    white-space: nowrap;
    right: 0px;
    padding-left: 3px;
    padding-right: 3px;
}

.event-calendar .time-marker {
	position: absolute;
	top: 163px;
	bottom: 0px;
	border-right: 1px solid #ff8282;
	z-index: 1;
}

.event-calendar .resource-item-wrapper.starts-outside-of-view {
	padding-left:0px;
}


.event-calendar .resource-item-wrapper .indent {flex-basis: 20px;flex-grow: 0;padding-left: 1em;border: 1px solid #e3e3e3;border-width: 0 0 1px 1px;margin-left: 15px;height: 20px;}

.event-calendar .resource .resource-item-wrapper.starts-outside-of-view .resource-item.label {
	border-left-style:dashed;
	border-top-left-radius:0px;
	border-bottom-left-radius:0px;	
}

.event-calendar .resource .resource-item-wrapper.ends-outside-of-view .resource-item.label {
	border-right-style:dashed;
	border-top-right-radius:0px;
	border-bottom-right-radius:0px;	
}


.event-calendar .day .resource .resource-item-wrapper .indent {
	display:none;
}

.event-calendar .day .resource .resource-item-wrapper.box-child .indent {
	display:inherit;
}



.event-calendar .droppable.drag-target {background-color: #ddeafb;}


.event-calendar .resource-item-group {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width:0px;
  cursor: pointer;
}


.event-calendar .resource-item-group:hover .resource-expander, .event-calendar .resource-item:hover .resource-expander {
	background-color: rgb(198 198 198);
}

.event-calendar .resource-item-group:hover .resource-expander a, .event-calendar .resource-item:hover .resource-expander a {
	color: black;
	opacity:1;
}

.event-calendar .resource-item .box-child {background-color: #ffffffe3;z-index:2;}



.event-calendar .day .resource .resource-item-wrapper.row-0 {
  top: 5px;
}

.event-calendar .day .resource .resource-item-wrapper.row-1 {
  top: 56px;
}

.event-calendar .day .resource .resource-item-wrapper.row-2 {
  top:calc((2 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-3 {
  top:calc((3 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-4 {
  top:calc((4 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-5 {
  top:calc((5 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-6 {
  top:calc((6 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-7 {
  top:calc((7 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-8 {
  top:calc((8 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-9 {
  top:calc((9 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-10 {
  top:calc((10 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-11 {
  top:calc((11 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-12 {
  top:calc((12 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-13 {
  top:calc((13 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-14 {
  top:calc((14 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-15 {
  top:calc((15 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-16 {
  top:calc((16 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-17 {
  top:calc((17 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-18 {
  top:calc((18 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-19 {
  top:calc((19 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-20 {
  top:calc((20 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-21 {
  top:calc((21 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-22 {
  top:calc((22 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-23 {
  top:calc((23 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-24 {
  top:calc((24 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-25 {
  top:calc((25 * 50px) + 6px);
}

.event-calendar .day .resource .resource-item-wrapper.row-26 {
  top:calc((26 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-27 {
  top:calc((27 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-28 {
  top:calc((28 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-29 {
  top:calc((29 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-30 {
  top:calc((30 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-31 {
  top:calc((31 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-32 {
  top:calc((32 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-33 {
  top:calc((33 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-34 {
  top:calc((34 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-35 {
  top:calc((35 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-36 {
  top:calc((36 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-37 {
  top:calc((37 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-38 {
  top:calc((38 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-39 {
  top:calc((39 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-40 {
  top:calc((40 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-41 {
  top:calc((41 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-42 {
  top:calc((42 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-43 {
  top:calc((43 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-44 {
  top:calc((44 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-45 {
  top:calc((45 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-46 {
  top:calc((46 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-47 {
  top:calc((47 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-48 {
  top:calc((48 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-49 {
  top:calc((49 * 50px) + 6px);
}
.event-calendar .day .resource .resource-item-wrapper.row-50 {
  top:calc((50 * 50px) + 6px);
}




.unassigned .day .week-hour-bar {
	background-color: #fcfcfc;
}

.day .week-hour-bar {
	border-right: 1px dotted #ececec;
	/* min-width: 200px; */
}

.day .week-hour-bar.business-closed {
	background-color: #fbfbfb;
}


.day .week-hour-bar:hover {
	background-color: #edf4fd;
	cursor:pointer;
}


.event-calendar .day .resource-item-wrapper.starts-before-office-opens a, .event-calendar .day .resource-item-wrapper.starts-outside-of-view a {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}


.event-calendar .month-view .experience-item a {
	padding:5px;
}

.event-calendar .month-view .experience-item .experience-counts {
	display: inline-block;
}

.event-calendar .month-view .experience-item .experience-gross {
	display: inline-block;
	text-align: right;
}


.event-calendar .month-view .experience-item .experience-label.hasPax {
	font-weight: 600;
}

.event-calendar .timeslot .col-new {
	width:50px;
}

.event-calendar .experience-item .experience-label {
	overflow:hidden;
	font-weight: bold;
	margin-left: 15px;
	color: black;
	white-space: normal;
}

.event-calendar .experience-item .experience-note {
	margin-left: 15px;
	font-weight:bold;
	white-space: normal;
	word-break: break-word;
}

.experience-day-detail .timeslot .timeslot-note {
	max-width: 320px;
	line-height: 1;
	padding-bottom: 10px;
}

.event-calendar .experience-resources .experience-resource {
	margin-left: 5px;
	white-space: normal;
	text-align: left;
	/* display: inline-block; */
}

.event-calendar .experience-resources {
	margin-left: 10px;
	white-space:normal;
}

.event-calendar .experience-workgroups .experience-workgroup {
	margin-left: 5px;
	white-space: normal;
	text-align: left;
	background-color: rgb(148, 242, 242);
}

.event-calendar .experience-workgroups {
	margin-left: 10px;
	white-space:normal;
}


.event-calendar .day.past .experience-item .experience-label, .event-calendar .day.past .experience-item .timeslot-info {
	margin-left: 0px;
}


.experience-day-detail-popup .popover-body, .resource-group-popup .popover-body {
	padding:0px;
	border: 8px;
	overflow: hidden;
	border-radius: 8px;
}

.event-calendar-filters .toggled-buttons button {
	background-color:white;
}

.event-calendar-filters .toggled-buttons button:focus {
	outline:none;
}

.experience-day-detail, .resource-popup-detail {
	overflow-y: scroll;
	background-color: white;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	max-height: 90vh;
}

.resource-popup-detail .trip-row {
	font-size:12px;
	cursor:pointer;
}

.resource-popup-detail .highlight-row {
	background-color: #b9cee426;
}

.resource-popup-detail .trip-row:hover {
	background-color: #0090ff0a;
}


.day .month-label {
	position:absolute;
	top:0px;
	left:10px;
	font-weight:600;
}

.day .experience-item .timeslot-info {
	margin-left:15px;
	white-space: normal;
}

.event-calendar .month-view .day .experience-item .timeslot-info {
	font-size:12px;
}


.experience-day-detail .title, .resource-popup-detail .title {
	/* margin-right: 30px; */
	flex: 1;
	/* width: 100%; */
	position: absolute;
	top: 15px;
	width: 100%;
	padding-left: 15px;
}

.experience-day-detail .header .booked-box.box, .resource-popup-detail .header .booked-box.box {
	border-right:none;
}

.calendar .year-label {font-weight:100;}

.resource-popup-detail .trip-row {
	border-top: 1px solid #067bf81f;
	padding-left:20px;
	padding-right:20px;
	padding-bottom:10px;
	padding-top: 10px;
	/* width: 100%; */
}

.resource-popup-detail .trip-row:first-child {
	border-top: none;
}

.resource-popup-detail .trip-row:last-of-type {
	border-bottom: none;
	margin-bottom: 10px;
}


.resource-popup-detail .trip-row .code {
	flex-grow:0;
	flex-basis: 80px;
	white-space: nowrap;
	flex-shrink: 0;
}

.resource-popup-detail .trip-row .names {
	flex-grow:1;
	white-space: nowrap;
	/* flex-shrink: 0; */
	flex-basis: 300px;
}

.resource-popup-detail .trip-row .pax-cnt {
	flex-grow:0;
	flex-basis: 40px;
	text-align:center;
	flex-shrink: 0;
}


.resource-popup-detail .trip-row .actions {
	flex-basis: 100px;
	flex-grow:0;
}


.month-view .day.today {
	background-color: #0098ff14;
	outline: 1px solid #0098ff42;
	outline-offset: -2px;
}

.event-calendar .month-view .day.today .day-number {
	color: #060606;
	font-weight: 600;
}

.event-calendar .day .experience-item a, .event-calendar .day .resource-item  {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.event-calendar .day:hover {
	background-color: #f9fbff;
}


.event-calendar .day.past .experience-item a:hover, .event-calendar .day.past .resource-item:hover {
	opacity: .8;
}

.event-calendar .day .experience-item a:hover, .event-calendar .day .resource-item:hover {
	/* box-shadow: 0 0 0 1px rgba(42,47,69,.04), 0 2px 5px 0 rgba(42,47,69,.04), 0 3px 9px 0 rgba(42,47,69,0.04), 0 1px 1.5px 0 rgba(0,0,0,.04), 0 1px 2px 0 rgba(0,0,0,.04); */
}

.experience-day-detail .timeslot {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.experience-day-detail .timeslot:hover {
	cursor:pointer;
}

.modal-dialog header span {
	display:block;
	font-weight: 100;
	font-size: 14px;
}

.experience-day-detail .actions i.fa-chevron-right {font-size:.8em;opacity: .7;}
.experience-day-detail .actions i.fa-chevron-down {font-size:.8em;opacity: .7;}

.experience-day-detail .status-detail {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 10px;
	margin-bottom: 20px;
	width: 100%;
	text-align: center;
}

.experience-day-detail .manifest-detail {
	padding-left: 20px;
	padding-right: 20px;
	    font-size: .8em;
}

.experience-day-detail .body {
	background-color: white;
	overflow-y: scroll;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	transition: all 0.5s ease;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.experience-day-detail .body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.experience-day-detail .body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.experience-day-detail .actions button {
	outline-width:0px;
}

span.timeslot-status {
	width:8px;
	height:8px;
	display:inline-block;
	border-radius:10px;
	margin-right: 4px;
	position: absolute;
	top: 7px;
	/* flex-grow: 1; */
	/* flex-basis: 200px; */
}

span.timeslot-status-1 {
	background-color: #02bb02;
}

span.timeslot-status-2 {
	background-color: #028c02;
}

span.timeslot-status-3 {
	background-color: #b5b5b5;
}

span.timeslot-status-4 {
	background-color:red;
}

span.timeslot-status-5 {
	background-color: #feb2b2;
	color: #0c0c0c;
	font-weight:300;
	border:1px solid red;
}

.experience-day-detail .actions .timeslot-status-1 button {
	background-color: #02bb0214;
}

.experience-day-detail .actions .timeslot-status-2 button {
	background-color: #0065000d;
}

.experience-day-detail .actions .timeslot-status-3 button {
	background-color: #b5b5b52b;
}

.experience-day-detail .actions .timeslot-status-4  button {
	background-color: #ff00000a;
	color: #bf1f1f;
}

.experience-day-detail .actions .timeslot-status-5  button {
	background-color: #ff00000a;
	color: #bf1f1f;
}


.experience-day-detail .actions a {
	color: #909090;
	position: relative;
}

.experience-day-detail .actions .dropdown-menu i {
	width: 25px;
}

.experience-day-detail .actions .dropdown-menu span.small {
	/* display:block; */
	/* margin-left: 20px; */
	/* width: 100%; */
}

.experience-day-detail .dropdown-item.active, .dropdown-item:active {
	background-color: #0098ff;
	color:white;
	position: relative;
}

.experience-day-detail .dropdown-menu .dropdown-item.active:hover {
	background-color:#0098ff;
}

.experience-day-detail .dropdown-menu {
	box-shadow: 0 5px 50px rgba(4, 123, 248, 0.3);
	border: 1px solid #0098ff17;
	border-radius:0px;
}

.experience-day-detail .actions .btn:hover {
	background-color: #f5f8fb;
	border-color: #d5eaf9;
}

.experience-day-detail .timeslot .availabilities {
	padding: 3px;
	background-color: #f3f3f3;
	border-radius: 12px;
	padding-left: 10px;
	padding-right: 10px;
}

.experience-day-detail .actions .btn {
	padding: 5px;
	background-color: #f9f9f9;
	border-radius: 12px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 13px;
	margin-right: 10px;
	border-width: 1px;
	color: #909090;
	border: 1px solid whitesmoke;
}

.experience-day-detail .actions .btn.holds {
	background-color: #fff7f7;
	color: #909090;
	border: 1px solid #ffdfdf;
}


.event-calendar input.single-daterange {
	width:0px;
	padding: 0px;
	display: inline-block;
	border: none;
}


.event-calendar .top-bar > ul li a {
    /* padding-top: 15px; */
    /* padding-bottom: 15px; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    /* cursor: pointer; */
}


.header .manifest.btn, .header .notify.btn {
	padding: 3px;
	background-color: #f9f9f9;
	border-radius: 12px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 13px;
	margin-right: 10px;
	border-width: 1px;
	color: #909090;
	border: 1px solid whitesmoke;
}

.experience-day-detail .manifest.btn, .experience-day-detail .notify.btn {background-color:white;}

.experience-day-detail .timeslot .status {
	flex-basis: 140px;
	text-align: center;
}

.experience-day-detail .timeslot .left {flex-grow: 1;margin-bottom: 10px;}

.experience-day-detail .timeslot .info {
	/* display:flex; */
	width: 100%;
	margin-top: 8px;
	padding-left: 25px;
}

.experience-timeslot-note {
	display: inline;
}

.experience-day-detail .experience-timeslot-note .small {
	/* font-size:10px; */
	/* margin-top: 5px; */
	/* margin-bottom: 5px; */
}

.experience-timeslot-note .edit-note {
	margin-top:15px;
}

.experience-timeslot-note .edit-note input {
	display: inline-block;
	max-width: 300px;
}

.experience-day-detail .experience-timeslot-note a {
	/* background-color: #fbfbfb; */
	display: inline-block;
	/* padding: 3px; */
	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;
	color: inherit;
	padding-top: 5px;
	padding-bottom: 5px;
}

.avail-detail {
	padding-left: 10px;
	font-size: .9em;
	padding-right: 10px;
}

.avail-detail table th {
	padding:4px;
	text-align:center;
}

.calendar-date .form-control {
    border: none;
}

.calendar-date-chooser-popup {
    border-radius: 8px;
    min-width: 400px;
    width: inherit;
    max-width: 310px;
    min-width: inherit;
    z-index: 5;
}

.calendar-date .b-calendar-nav button {
    flex-basis: 20%;
}

.calendar-date footer {
    /* display: none; */
}

.calendar-date-inner {
    margin-bottom: 5px;
    margin-top: 5px;
}

.break {
	/* height:0; */
	flex-basis:100%;
}


.experience-day-detail .avail-detail {
	flex-basis: 100%;
}

.experience-day-detail .manifest-detail {
	flex-basis: 100%;
}


.experience-day-detail .timeslot .actions {
	display:flex;
	font-size: .7em;
	width: 100%;
	margin-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	color: #ababab;
	padding-left: 15px;
	padding-right: 20px;
	padding-bottom: 5px;
	/* position: relative; */
}



.experience-day-detail .timeslot {
	border-bottom: 1px solid #f1f1f1;
	display: flex;
	flex-wrap: wrap;
	background-color: white;
	/* position: relative; */
}

.experience-day-detail .timeslot:last-child {
	border-bottom:none;
}

.experience-day-detail .timeslot .avail .label {
	font-size: 7px;
	text-transform: uppercase;
	color: gainsboro;
	margin-left: 0;
}


.experience-day-detail .timeslot .time {
	flex-grow:1;
	display: inline;
	position: relative;
}

.experience-day-detail .timeslot .action {
	padding-right: 25px;
	padding-top: 20px;
}

.experience-day-detail .box .label, .resource-popup-detail .box .label {
	font-size:.5em;
	text-transform:uppercase;
	color: gainsboro;
	margin-left: 0;
	/* margin-bottom: 5px; */
	margin-top: -3px;
	margin-bottom: 3;
}

.experience-day-detail-popup, .resource-group-popup {
	border-radius:8px;
	width: inherit;
	max-width: inherit;
	min-width: inherit;
	z-index: 20;
	/* overflow: hidden; */
	min-width: 450px;
}

.experience-day-detail .mobile.expand {display:none;}

.experience-day-detail .header, .resource-group-popup .header {
	display:flex;
	padding-left: 20px;
	border-bottom: 1px solid #f3f3f3;
	background-color: #fbfbfb;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 2;
	position: relative;
	padding-bottom: 0px;
	justify-content: right;
}

.experience-day-detail .header .date, .resource-popup-detail .header .date {
	color: #545454;
	font-size: 1.4em;
	white-space: nowrap;
	font-weight: 500;
	margin-bottom: 0px;
}

.experience-day-detail .header .name, .resource-popup-detail .header .name {
	max-width:220px;
	overflow:hidden;

}

.experience-day-detail .header .box, .resource-popup-detail .header .box {flex-basis:100px;}
.experience-day-detail .header .box, .resource-popup-detail .header .box {flex-basis: 80px;border: 1px solid #f1f1f1;padding: 10px;text-align: center;border-bottom: 0px;background-color: #ffffff;padding-bottom: 0px;margin-top: 65px;padding-top: 5px;padding-bottom: 5px;}

.experience-day-detail .header h4, .resource-popup-detail .header h4 {
	font-size: 14px;
	white-space: nowrap;
	font-weight: 100;
	margin-bottom: 0px;
	max-width: 400px;
	overflow: hidden;
	text-overflow: ellipsis;
	/* margin-top: 10px; */
}

.event-calendar .month-view .day .day-number {
	position:absolute;
	right: 8px;
	color: #9e9e9e;
	top: 2px;
}

.event-calendar .month-view .header .day {
	text-align: right;
	min-height:inherit;
	background-color: white;
	border-bottom: none;
}

.event-calendar .header .day {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 100;
	color: #717171;
	padding-top: 5px;
	padding: 5px;
	display: block;
	background-color: inherit;
	border-bottom: 1px solid #eaeaea;
}


.event-calendar-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  top: 93px;
  position: fixed;
  z-index: 4;
  height: 100%;
  width: 300px;
  border-right: 1px solid #067bf82e;
  bottom: 0px;
  overflow-y: scroll;
  padding-bottom: 130px;
}

.event-calendar-filters .filters {
  -ms-flex: 0 0 300px;
  /* width: 100%; */
  position: relative;
  /* height: 100%; */
  /* overflow: scroll; */
}

.event-calendar-filters .filters .filters-header {
  display: flex;
  padding: 0px 20px;
  -ms-flex-pack: justify;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -ms-flex-align: center;
  position: relative;
}

.event-calendar .month-view .day.past {opacity: .8;}
.event-calendar .month-view .day.past:hover {opacity: 1;}

.event-calendar-filters .filter-buttons {
	position: absolute;
	bottom: 100px;
	background-color: #ffffff;
	width: 100%;
	left: 0px;
	padding-bottom: 10px;
	padding-top: 10px;
	border-top: 1px solid #f0f3f7;
	}

.resource-label .custom-field-summary {
	font-size:10px;
	display: table;
	margin-bottom: 3px;
}

.event-calendar .day .experience .label, .event-calendar .day .resource .label {
	background-color: #9a9a9a0d;
	width:100%;
	display:block;
	margin-bottom:3px;
	font-size: 14px;
	border-radius:3px;
	white-space: nowrap;
	padding: 2px;
	border-width: 2px;
	border-style: solid;
	border-color: #e6e6e6;
	text-align: left;
	font-weight: 400;
	padding-left: 4px;
	padding-right: 4px;
	position: relative;
	text-overflow: ellipsis;
	min-height: 44px;
	overflow: hidden;
	flex-grow: 1;
	color: black;
}

.resource-label .time {
	font-size:12px;
}

.event-calendar-filters .filters .filters-header h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .7rem;
  margin-bottom: 0px;
}

.event-calendar-filters .filters .filters-header .reset-filters {
  color: #0098ff69;
  position: absolute;
  right: 10px;
  top: 25px;
}

.event-calendar-filters .filters .filters-header .reset-filters i {
  display: inline-block;
  vertical-align: middle;
  /* margin-right: 10px; */
  font-size: 7px;
  margin-bottom: 2px;
}

.event-calendar-filters .filters .filters-header .reset-filters span {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 8px;
}

.event-calendar-filters .filters .filter-w {
  position: relative;
  /* overflow-x: scroll; */
}

.event-calendar-filters .filters .filter-w .filter-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  padding: 4px 5px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.4);
  font-size: 6px;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  z-index: 3;
}

.event-calendar-filters .filters .filter-w .filter-toggle i {
  display: inline-block;
  vertical-align: middle;
}

.event-calendar-filters .filters .filter-w .filter-toggle:hover {
  background-color: #111;
  color: #fff;
}

.event-calendar-filters .filters .filter-w iframe {
  max-width: 100%;
}

.event-calendar-filters .filters .filter-w.no-padding .filter-body {
  padding-left: 0px;
  padding-right: 0px;
}

.event-calendar-filters .filters .filter-w.collapsed .filter-body {
  display: none;
}

.event-calendar-filters .filters .filter-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.7rem;
  position: relative;
  padding: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0px;
}

.event-calendar-filters .filters .filter-header:before {
  content: "";
  background-color: #047bf8;
  width: 7px;
  height: 7px;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

.event-calendar-filters .filters .filter-body {
  padding: 0px 20px 20px 20px;
  padding-right: 5px;
}

.event-calendar-filters .filters .filter-w + .filter-w {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.event-calendar-filters .filters .select2 {
  display: block;
  width: 100%;
}

.week-day-view .row {
	flex-wrap:nowrap;
	overflow: hidden;
	-webkit-transition: min-height 0.5s ease;
	transition: min-height 0.5s ease;
}

.week-day-view .row .exp {
	flex-grow:0;
	flex-basis: 120px;
	min-width: 150px;
	color: #333333;
	font-weight: 100;
	margin-bottom: 10px;
	position: relative;
}

.week-day-view .row.exp:last-child {
	border-bottom:1px solid rgba(0, 0, 0, 0.1);
}

.week-day-view .row .day .day {
	display: block;
	margin: auto;
}

.week-day-view .row .day .dow {
	display: block;
	text-align: center;
	margin: auto;
}

.event-calendar-filters .filters .select2.select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border-color: transparent;
}

.event-calendar-filters .filters .select2.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border: 2px solid #047bf8;
  border-radius: 16px;
  font-size: 0.63rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: transparent;
  font-weight: 500;
  padding: 3px 8px;
  color: #047bf8;
  margin-right: 7px;
}

.event-calendar-filters .filters .select2.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #047bf8;
}

.event-calendar-filters .rentals-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.event-calendar-filters .rentals-list .list-controls {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.event-calendar-filters .rentals-list .list-controls .list-info {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.72rem;
}

.event-calendar-filters .rentals-list .list-controls .list-order {
  margin-left: auto;
}

.event-calendar-filters .rentals-list .list-controls .list-order label {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.72rem;
  margin-bottom: 0px;
}

.event-calendar-filters .property-items.as-list .property-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.event-calendar-filters .property-items.as-list .property-item .item-media-w {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
}

.event-calendar-filters .property-items.as-list .property-item .item-media-w .item-media {
  height: 100%;
}

.event-calendar-filters .property-items.as-list .property-item .item-info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.event-calendar-filters .property-items.as-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.event-calendar-filters .property-items.as-grid .property-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
}

.event-calendar-filters .property-items.as-grid .property-item:nth-child(odd) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.event-calendar-filters .property-items.as-grid .property-item .item-media-w .item-media {
  padding-bottom: 65%;
}

.event-calendar-filters .property-items.as-grid .property-item .item-info {
  padding: 30px 30px;
}

.event-calendar-filters .property-item {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.event-calendar-filters .property-item .item-media-w {
  display: block;
  position: relative;
  overflow: hidden;
}

.event-calendar-filters .property-item .item-media-w .item-media {
  background-size: cover;
  background-position: center center;
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.event-calendar-filters .property-item .item-media-w:after {
  content: "";
  background-color: transparent;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 3;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.event-calendar-filters .property-item .item-media-w:hover .item-media {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.event-calendar-filters .property-item .item-media-w:hover:after {
  background-color: rgba(0, 0, 0, 0.1);
}

.event-calendar-filters .property-item .item-info {
  padding: 60px 50px;
}

.event-calendar-filters .property-item .item-info .item-title a {
  color: #334152;
  text-decoration: none;
}

.event-calendar-filters .property-item .item-info .item-title a:hover {
  color: #1f2833;
}

.event-calendar-filters .property-item .item-price-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}

.event-calendar-filters .property-item .item-reviews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.event-calendar-filters .property-item .item-reviews .reviews-count {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.event-calendar-filters .property-item .item-price strong {
  color: #047bf8;
  font-size: 1.8rem;
}

.event-calendar-filters .property-item .item-price span {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #999;
  margin-left: 5px;
}

.event-calendar-filters .property-item .item-features {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #777;
  margin: 10px 0px;
}

.event-calendar-filters .property-item .item-features .feature {
  margin-right: 17px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.event-calendar-filters .property-item .item-features .feature + .feature:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.event-calendar-filters .property-item .item-buttons {
  padding-top: 20px;
}

.event-calendar-filters .property-item .item-buttons .btn, .event-calendar-filters .property-item .item-buttons .all-wrapper .fc-button, .all-wrapper .event-calendar-filters .property-item .item-buttons .fc-button {
  border-width: 2px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 14px;
  letter-spacing: 2px;
}

.event-calendar-filters .property-item .item-buttons .btn span, .event-calendar-filters .property-item .item-buttons .all-wrapper .fc-button span, .all-wrapper .event-calendar-filters .property-item .item-buttons .fc-button span, .event-calendar-filters .property-item .item-buttons .btn i, .event-calendar-filters .property-item .item-buttons .all-wrapper .fc-button i, .all-wrapper .event-calendar-filters .property-item .item-buttons .fc-button i {
  display: inline-block;
  vertical-align: middle;
}

.event-calendar-filters .property-item .item-buttons .btn span + i, .event-calendar-filters .property-item .item-buttons .all-wrapper .fc-button span + i, .all-wrapper .event-calendar-filters .property-item .item-buttons .fc-button span + i {
  margin-left: 10px;
  font-size: 10px;
  margin-right: 0px;
}

.event-calendar-filters .property-item .item-buttons .btn + .btn, .event-calendar-filters .property-item .item-buttons .all-wrapper .fc-button + .btn, .all-wrapper .event-calendar-filters .property-item .item-buttons .fc-button + .btn, .event-calendar-filters .property-item .item-buttons .all-wrapper .btn + .fc-button, .all-wrapper .event-calendar-filters .property-item .item-buttons .btn + .fc-button, .event-calendar-filters .property-item .item-buttons .all-wrapper .fc-button + .fc-button, .all-wrapper .event-calendar-filters .property-item .item-buttons .fc-button + .fc-button {
  margin-left: 10px;
}

.event-calendar-filters .pagination-w {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 20px;
}

.event-calendar-filters .pagination-w .pagination-info {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.event-calendar-filters .pagination-w .pagination-links .pagination {
  margin-bottom: 0;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #047bf8;
}

.event-calendar-filters .pagination-w .pagination-links .pagination .page-link {
  border: none;
}

.event-calendar-filters .pagination-w .pagination-links .pagination .disabled .page-link {
  color: rgba(0, 0, 0, 0.3);
}

.event-calendar-filters .filter-content {width:100%;overflow: scroll;}

.event-calendar-filters.hide-filters .filters {
  display: none;
}

.event-calendar-filters.hide-filters .property-items.as-grid .property-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3%;
          flex: 0 0 33.3%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.event-calendar-filters.hide-filters .property-items.as-grid .property-item:nth-child(3n) {
  border-right: none;
}


.event-calendar .timeline-head .col {
	text-align:center;
}

.event-calendar.desktop {
	padding-top: 45px;
	z-index: 50;
}


.event-calendar .hour-row {
	border-top: 1px solid #d6d6d6;
}


.event-calendar .data-cell {
	border-top: 1px dotted #e2e2e2;
	border-right: 1px dotted #e2e2e2;
}

.event-calendar .time-cell {
	border-right: 1px dotted #e2e2e2;
}

.event-calendar .timeline-view {
	margin-top: 67px;
}


.event-calendar .data-cell .trip-wrapper {
	position: absolute;
	z-index: 3;
}

.event-calendar .data-cell .trip {
	border: 2px solid white;
	background-color:white;
	/* min-height:200px; */
	font-size: 10px;
	padding: 10px;
	position:relative;
	cursor:pointer;
	animation-duration: .5s;
	width: 100%;
	position: relative;
	transition-duration: .5s;
	border-radius: 2px;
	white-space: nowrap;
	overflow: hidden;
	font-weight: 100;
}

.event-calendar .data-cell .trip:hover {
	box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.32);
}

.event-calendar .data-cell .time-label {
	opacity:0;
	transition-duration: .3s;
	font-size:12px;
	text-align:center;
	cursor:pointer;
	color: #8a8a8a;
	font-weight: 100;
}

.event-calendar .data-cell:hover .time-label {
	opacity:1;
}


.event-calendar .guide-col .trip-wrapper {
	max-width:80px;
	width:80px;
}

.event-calendar .trip-wrapper .trip .name {
	font-weight:500;
}

.event-calendar .trip-wrapper .trip .time {
	font-weight:500;
	color: #0098ff;
}

.event-calendar .trip-wrapper .trip .experience {
	margin-bottom:5px;
	border-bottom: 1px solid #efefef;
}

.event-calendar .resource-col .trip-wrapper {
	max-width:130px;
	width:130px;
}


.event-calendar .time-row .data-cell, .event-calendar .time-row .data-cell {
	height:20px;
}

.event-calendar .timeline-view .row {
	flex-wrap: nowrap;
}

.event-calendar .trip-wrapper.hoverTrip .trip {
	border-color: #c1dff3;
	border-width: 2px;
	background-color: #daeeff;
}

.event-calendar .timeline-view .time-col.time-cell {
	background-color: white;
	text-align: center;
	height: 20px;
	padding-top: 3px;
}

.event-calendar .timeline-head .col {
	text-align:center;
	padding: 10px;
	font-size: 12px;
	font-weight: 100;
	background-color: white;
}

.event-calendar .timeline-head .guide-header-cell .name {
	margin-top:5px;
}

.event-calendar .timeline-head .resource-header-cell .label {
	position:absolute;
	bottom: 20px;
	left: 0px;
	right: 0px;
}


.event-calendar .timeline-head {
	border-bottom: 1px solid #089bff3d;
	background-color: white;
	position: fixed;
	left: 0px;
	right: 0px;
	top: 107px;
	z-index: 4;
	transition: all .4s;
}

.event-calendar .dropDownOpen .timeline-head {
	left:225px;
}

.event-calendar .row.marker {
	border-bottom: 1px solid red;
	background-color: white;
	height: 2px;
	/* flex-wrap: inherit; */
	overflow-x: inherit;
}

.event-calendar .row.marker span {
    margin-left: 50px;
    background-color: #ff0000b3;
    color: white;
    font-size: 9px;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -5px;
    z-index: 2;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 13px;
}

.event-calendar .timeline-head + .time-row {
	border-top:none;
}

.event-calendar .timeline-view .hour-row .data-cell {
	border-top:none;
}

.event-calendar .timeline-view .time-cell {
	font-size: 15px;
	font-weight: 100;
}

.event-calendar .timeline-view .time-col {
	flex-basis: 100px;
	flex-grow: 0;
}

.event-calendar .timeline-view .guide-col {
	flex-grow:0;
	flex-basis:100px;
	min-height: 20px;
}

.event-calendar .timeline-view .resource-col {
	flex-grow:0;
	flex-basis:150px;
}

.event-calendar .timeline-view .guide-header-cell img {
	height: 30px;
	width: 30px;
	border-radius:50%;
	padding: 2px;
	border: 1px solid #047bf8;
}


.calendar-view-chooser ul {
	list-style: none;
	padding-inline-start: 0px;
	font-size: .85rem;
	width: 100%;
	padding-bottom: 0px;
	margin-bottom: 0px;
	overflow: scroll;
	max-height: 500px;
}

.calendar-view-chooser-popup .calendar-view-chooser {min-width:300px;max-width: 300px;border-bottom-left-radius: 8px;overflow: hidden;border-bottom-right-radius: 8px;}

.calendar-view-chooser h6 {
	padding-left: 20px;
	padding-top:20px;
	padding-right:10px;
	padding-bottom: 10px;
}

.calendar-view-chooser h6 {
    color: #a7aeb5;
    text-transform: uppercase;
    font-size: 0.72rem;
    font-weight: 500;
    margin-bottom: 3px;
}

.calendar-view-chooser .no-views {
	padding:40px;
	font-size: .8rem;
	white-space: initial;
}


.calendar-view-chooser li:hover {
    background-color: #f9f9f9;
}

.calendar-view-chooser li:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.calendar-view-chooser li.active {
	background-color: #0c7bf2;
	color: white;
}

.calendar-view-chooser li.active .text a {
	color:white;
	font-weight:600;
}

.calendar-view-chooser li {
	display:flex;
	padding-left: 8px;
	padding-right: 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	cursor:pointer;
}

.calendar-view-chooser li .text a {
	padding:10px;
	width:100%;
	display:block;
}

#viewChooserContainer .calendar-view-chooser li .actions a {
	margin-right:5px;
	padding:0px;
}

.calendar-view-chooser li .active-icon {
	flex-basis: 20px;
	text-align: right;
	margin-right: 3px;
	flex-shrink: 0;
	padding-top: 10px;
}

.calendar-view-chooser li .text {
	flex-grow: 1;
	white-space: nowrap;
}

.calendar-view-chooser li .actions {
	flex-basis:60px;
	text-align: right;
	padding-right: 15px;
	/* display: inline-flex; */
	margin-top: 10px;
}


.calendar-view-chooser-popup {
    width: inherit;
    max-width: inherit;
    z-index: 5;
}

.calendar-view-chooser-popup .popover-body {
	padding:0px;
	/* max-height: 500px; */
}


.event-calendar .month-year {
	width:200px;
	margin: auto;
	text-align: center;
}

.resource-expander {
	position:absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	padding-top: 8px;
	padding-right: 8px;
	padding-left: 8px;
	background-color: rgb(198 198 198 / 0%);
	transition: all 0.3s ease;
}

.resource-expander:hover {
	background-color: rgb(198 198 198);
	border-left: 1px solid #9b9b9b;
}

.resource-expander:hover a {
	color: black;
	opacity: 1;
}

.resource-expander  a {
	color: #96969638;
	opacity: 0;
	transition: all 0.3s ease;
}


.day .timeslots  {
	display:flex;
	flex-wrap: wrap;
}

.day .timeslots .calendar-box {
	flex-basis:150px;
	flex-grow:1;
	flex-shrink: 1;
}

.subgroup {
	padding-left: 20px;
}

.blackout-box {
	background-color: #f4f4f4 !important;
	border-color: #e4e4e4 !important;
	background-image: repeating-linear-gradient(45deg, #eee 10px,#eee 12px,transparent 12px,transparent 20px);
}
.blackout-box:hover {
	background-color: #d0a6a64a !important;
	/* border-color: #fea0a0 !important; */
}

.blackout-box.business-closed {
	background-image: repeating-linear-gradient(45deg, #ccc 10px,#ccc 12px,transparent 12px,transparent 20px);
}

.selected-box {
	background-color: rgba(100, 149, 237, 0.3) !important;
	border: 1px dotted #6495ed !important;
}

.blackout-box.selected-box {
	background-color: #B282AE !important;
	border: 1px dotted #6495ed !important;
}


.filter-body .toggled-buttons.three-buttons button {
	margin-left: 5px;
	font-size: .6rem;
	padding-left: 6px;
	padding-right: 6px;
}

.filter-body .toggled-buttons.three-buttons .btn-toggled + .btn-toggled {
	margin-left: 2px;
}


/* iPhone */
@media (max-width: 768px) and (orientation : portrait){

	.event-calendar .top-nav .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle

	.event-calendar .week .experience {display:none;}
	.event-calendar .day {min-height:50px;}
	.event-calendar .month-view .day .day-number {
		top: 14px;
		width: 100%;
		text-align: center;
		left: 0px;
		right: 0px;
		color: #6b6b6b;
	}
	.event-calendar .day .month-label {
		font-weight:100;
		position:absolute;
		font-size:10px;
		text-align: center;
		left: 0px;
		right: 0px;
		width: 100%;
	}
	
	.experience-day-detail .timeslot .info {
		padding-left: 12px;
		margin-top: 10px;
	}


	.event-calendar .day.past .month-label {
		color: #c3c3c3;
	}

	.event-calendar .day .month-label {
		color: #6b6b6b;
		text-transform: uppercase;
		margin-top: 3px;
		font-size: 8px;
	}

	.event-calendar .month-view .day.cursor {
		background-color: #c7daff;
		border: 1px solid #3cacff;
		margin-right: 1px;
		font-weight: 600;
		}

	.event-calendar .month-view .day.past .day-number {
		color: #dadada;
	}


	.event-calendar .month-view .day.today-or-future.has-availability {
		border: 1px solid #0080001f;
		margin-right:0px;
		background-color: #00800026;
	}

	.event-calendar .month-view .day.cursor .day-number {
		color: black;
	}

	.event-calendar .experience-day-detail {width:inherit;overflow: hidden;border-radius: 8px;}
	.experience-day-detail .header .date {display:none;}
	.experience-day-detail .header .box {margin-top: 0px;margin-right: 5px;border: none;padding: 0px;background-color: inherit;flex-basis: auto;text-align: left;padding-left: 7px;background-color: #d1e2d6;padding-right: 7px;border-radius: 3px;padding-top: 3px;font-size: 12px;font-weight: 600;height: 25px;margin-top: 5px;}
	.experience-day-detail .body {margin-top: 20px;}
	.calendar-experiences .experience {margin-bottom: 5px;padding: 0px;/* overflow: hidden; *//* margin-right: -10px; *//* margin-left: -10px; */}
	.experience-day-detail .timeslot .action {padding-right: 10px;}
	.experience-day-detail .header {padding-top: 5px;/* padding-bottom: 5px; *//* display: none; */max-height: 3px;/* overflow: hidden; */}

	.experience-day-detail.not-selected .body {
		max-height: 0px;
	}

	.experience-day-detail .header .available-box.box {
		display:none;
	}

	.experience-day-detail .box .label {display:none;}
	.experience-day-detail .title {flex-grow:0;margin-right: 5px;/* overflow: hidden; *//* overflow-x: hidden; *//* flex-basis: 270px; *//* overflow-x: hidden; *//* width: inherit; *//* position: relative; */top: 13px;}
	.experience-day-detail .header .booked-box.box.empty {display:none;}
	.experience-day-detail .header h4 {line-height: 2;display: block;width: 100%;}
	.event-calendar .experience-day-detail.selected {
		border: 1px solid #0098ff2e;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		height: inherit;
		max-height: inherit;
	}

	.event-calendar .experience-day-detail.selected h4 {font-weight:600;}
	.event-calendar .experience-day-detail h4 {font-weight:600;max-width:215px;overflow:hidden;}

	.experience-day-detail .mobile.expand {display: block;position: absolute;right: 33px;top: 0px;font-size: 10px;color: #b5b5b5;}
	.experience-day-detail.selected .body {/* max-height: none; */}
	.calendar-experiences h4.date {font-size:14px;font-weight:100;}
	.experience-day-detail {min-height:inherit;height: 45px;}

	.event-calendar-filters {
		width:90%;
		left:0px;
	}
	
	.calendar .content-box {padding-top: 40px;}
	
	.experience-day-detail .avail-detail {
		margin-bottom:30px;
	}

	.experience-message-modal .action {
	    margin-top: 10px;
	    margin-left: auto;
	}
	
	.calendar .top-bar {flex-direction:inherit;}
	.calendar .customize-view span {display:none;}
	.calendar .choose-view span {display:none;}
	.calendar .top-bar > ul li a {
		padding-left:5px;
		margin-right:5px;
		margin-left:5px;
		padding-right:5px;
	}


}


/* tweaks for tiny phones on lanscaoe - iPhone SE */
@media (max-width: 950px) and (orientation : landscape) {
	.modal-content .modal-body {
		
	}
}


/* tweaks for tiny phones on lanscaoe - iPhone SE */
@media (max-width: 895px) and (orientation : landscape) {
	.trip-builder-timeslots .column.prior {
		display:none;
	}

	.trip-builder-timeslots .column.after {
		display:none;
	}

	.trip-builder-timeslots .column.current {
		flex-basis:100%;
		max-width: 100%;
	}

}

/* mobile portrait */
@media (max-width: 767px) and (orientation : portrait) {
	.month-view .period-row {text-align:center;margin-bottom: 15px;}

	
}

/* iPad portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
}

/* iPad landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.experience-day-detail {height: 500px;}
}
